


















































import { computed, defineComponent, inject } from '@vue/composition-api'
import utils from '../../mixins/utils'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import router from '@/router'
import ExpandedLeftMenu from '@/shared/components/left-panel/ExpandedLeftMenu.vue'
import CollapsedMenuSection from '@/shared/components/left-panel/CollapsedMenuSection.vue'
import { Route } from 'vue-router'
import { menu} from '@/router/menu'
import type { MenuItemSpec, MenuSectionSpec, MenuItemState, MenuSectionState } from '@/dto/leftMenuDTO'

export default defineComponent({
    name: 'LeftMenu',
    components: {
        ExpandedLeftMenu,
        CollapsedMenuSection,
    },
    props: {
        externalOverlayVisible: {
            type: Boolean,
            default: false,
        }
    },
    setup(_, { root, emit}) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { role } = useState(['role'])
        const { menuOpen } = useState(['menuOpen'])
        const { closeSection }: 
            {closeSection: (sectionIdentifier?: string) => void;
        } = inject('LEFT_MENU_OPEN_SECTION')

        const openMenuItem = (item: MenuItemSpec) => {
            item?.location && router.push(item.location)
            closeSection()
        }

        // Items are active if their route is currently opened
        const itemState = (itemSpec: MenuItemSpec, currentRoute: Route): MenuItemState => ({
            header: t(itemSpec.headerKey, language.value),
            active: currentRoute.name === itemSpec.location?.name,
            command: () => {openMenuItem(itemSpec)}
        })

        // Sections are active if any of their items are active, or if they contain an active subsection
        const sectionState = (sectionData: MenuSectionSpec, currentRoute: Route): MenuSectionState => {
            const items = sectionData.items?.filter(
                    // If visibleToRoles is defined, only show the menu item if the curernt role is in the list
                    (item: MenuItemSpec) => item?.visibleToRoles?.includes(role.value) ?? true
                ).map(
                    item => itemState(item, currentRoute)
                ) ?? []
            const subSections = sectionData.subSections?.map(
                subsection => sectionState(subsection, currentRoute)
            ) ?? []
            const active = (
                items.some((item) => item.active) 
                || subSections.some(section => section.active)
            )
            return {
                header: t(sectionData.headerKey, language.value),
                icon: sectionData.icon,
                items: items,
                subSections,
                active,
                isSingleItemSection: sectionData.isSingleItemSection ?? false,
            }
        }

        const menuState = computed(
            () => {
                return menu.map(
                    // For now we use root.$route to watch the route reactively
                    // Once upgraded to Vue 2.7 we can change this to useRoute()
                    section => sectionState(section, root.$route)
                )}
        )

        return {
            t,
            language,
            utils,
            menuOpen,
            menuState,
            emit,
        }
    },
})

// Only query the language for the logged in user. Currently this only
// depends on the organisation, but that logic is in the backend

const LOCALIZATION_WITHOUT_MESSAGES = `{
    localizationForLoggedInUser { 
        locale 
    }
}`

/* When we want to load the translation messages live from the backend, 
 * also get the messageDictJson field. This will contain a serialized 
 * json document that can be loaded into vue-i18n. This is transmitted
 * as a JSON string instead of through gql schema because this reduces
 * the workload in frontend and allows it to be cached in the backend.
 * Currently the string is re-generated every 15 minutes, so this will 
 * not immediately reflect changes to the translation data in backend. 
 */

const LOCALIZATION_WITH_MESSAGES = `{
    localizationForLoggedInUser { 
        locale 
        messageDictJson
    }
}`



export default {
    LOCALIZATION_WITH_MESSAGES, LOCALIZATION_WITHOUT_MESSAGES
}
import { UserDTO } from "@/dto/backend-response/usersDTO"
import variables from "@/shared/variables";
import { MutationTree } from 'vuex'
import { State } from './state'

// Mutation contracts
export type Mutations<S = State> = {
    initializeState(state: S): void;
    resetSelection(state: S): void;
    setLanguage(state: S, language: string): void;
    setUsers(state: S, users: Array<UserDTO>): void;
    setRole(state: S, role: string): void;
    setMenuOpen(state: S, menuOpen): void;
    setLeftMenuSidebar(state: S, menuOpen): void;
    setWizardData(state: S, wizardData): void;
    setSignupData(state: S, signupData): void;
    setNormTypes(state: S, normTypes): void;
    setSelectedNorms(state: S, selectedNorms): void;
    setOrganisationName(state: S, organisationName: string): void;
    setNetworkError(state: S, error: boolean): void;
}

// Reset Perium to default values
const clearSelection = (state: State) => {
    state.language = variables.DEFAULT_LANGUAGE
    state.users = []
    state.role = ''
    state.menuOpen = false
    state.visibleLeftMenuBar = false
    ;(state.wizardData = {
        activeStep: 1,
        companySize: '',
        jobRole: '',
        jobRoleOther: '',
        sector: 'OTHER',
        subSector: null,
        suggestedNorms: [],
        otherNorms: [],
        inviteUsers: [],
        twoFact: true,
        completedSteps: [],
    }),
        (state.signupData = {
            FIRSTNAME: '',
            LASTNAME: '',
            EMAIL: '',
            TELEPHONE: '',
            COMPANY_NAME: '',
            COMPANY_NUMBER: '',
            POSTCODE: '',
            CITY: '',
            TERMS_AND_CONDITIONS: false,
            PRIVACY_POLICY: false,
        })
    state.normTypes = []
    state.selectedNorms = []
    state.organisationName = ''
}

// Reset State to default values
const initState = (state: State) => {
    clearSelection(state)
}

// Define mutations
export const mutations: MutationTree<State> & Mutations = {
    initializeState(state: State) {
        initState(state)
    },

    resetSelection(state: State) {
        clearSelection(state)
    },

    // set language
    setLanguage(state: State, language) {
        state.language = language
    },
    // set users
    setUsers(state: State, users: Array<UserDTO>) {
        state.users = users
    },
    // set role
    setRole(state: State, role: string) {
        state.role = role
    },
    setMenuOpen(state: State, menuOpen) {
        state.menuOpen = menuOpen
    },
    setLeftMenuSidebar(state: State, visibleLeft) {
        state.visibleLeftMenuBar = visibleLeft
    },
    setWizardData(state: State, wizardData) {
        /* update wizardData where values are not empty. Otherwise it will replace with empty values from other steps. 
        This will not applicable for boolean value as default value is false of boolean variable. Hence check it by type */
        Object.keys(wizardData).forEach((key) => {
            if ((wizardData[key] && key) || typeof wizardData[key] === 'boolean') {
                state.wizardData[key] = wizardData[key]
            }
        })
    },
    setSignupData(state: State, signupData) {
        /* update signupData where values are not empty. Otherwise it will replace with empty values from other steps. 
        This will not applicable for boolean value as default value is false of boolean variable. Hence check it by type */
        Object.keys(signupData).forEach((key) => {
            if ((signupData[key] && key) || typeof signupData[key] === 'boolean') {
                state.signupData[key] = signupData[key]
            }
        })
    },
    setNormTypes(state: State, normTypes) {
        state.normTypes = normTypes
    },
    setSelectedNorms(state: State, selectedNorms) {
        state.selectedNorms = selectedNorms
    },
    // set organisation name
    setOrganisationName(state: State, organisationName: string){
        state.organisationName = organisationName
    },
    setNetworkError(state: State, error: boolean) {
        state.networkError = error
    }
}

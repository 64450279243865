import Vue from "vue";
import Vuex, { CommitOptions, DispatchOptions, GetterTree, Store as VuexStore } from "vuex";
import { State, state } from './state'
import { Getters, getters } from './getters'
import { Mutations, mutations } from './mutations'
import { Actions, actions } from './actions'

Vue.use(Vuex)
export const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});


/* Setting up store types in order to access mutations(with commit), actions(with dispatch) and getters*/
export type Store = Omit<VuexStore<State>, 'getters' | 'commit' | 'dispatch' | 'state'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>( //set mutations type
      key: K,
      payload: P,
      options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>( //set action types
      key: K,
      payload: Parameters<Actions[K]>[1],
      options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: GetterTree<State, State> & Getters;

}& {
  state;

}


export function useStore() {
  return store as Store
}





















































import Vue from "vue";
import { useState } from "@/shared/mixins/helpers"
import { useI18n } from "vue-i18n-composable"

export default Vue.extend({
    setup() {
        const { t } = useI18n()
        const { language } = useState(["language"])

        return {
            language,
            t,
        }
    }
})




































































import Vue from 'vue'
import { useActions, useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import utils from '@/shared/mixins/utils'

export default Vue.extend({
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { menuOpen } = useState(['menuOpen'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])

        return {
            language,
            t,
            menuOpen,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            utils,
        }
    },
})

import Vue, { VueConstructor } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import PrimeVue from 'primevue/config'
import VueScrollTo from 'vue-scrollto'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Menu from 'primevue/menu'
import Dropdown from 'primevue/dropdown'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import PanelMenu from 'primevue/panelmenu'
import Slider from 'primevue/slider'
import OverlayPanel from 'primevue/overlaypanel'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import Panel from 'primevue/panel'
import MultiSelect from 'primevue/multiselect'
import Card from 'primevue/card'
import TieredMenu from 'primevue/tieredmenu'
import Divider from 'primevue/divider'
import Breadcrumb from 'primevue/breadcrumb'
import Sidebar from 'primevue/sidebar'
import ProgressBar from 'primevue/progressbar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Tag from 'primevue/tag'
import ToggleButton from 'primevue/togglebutton'
import Tooltip from 'primevue/tooltip';

import VueCompositionAPI, { createApp } from '@vue/composition-api'
import '@fortawesome/fontawesome-free/css/all.css'
import '@/style.scss'
import Editor from 'primevue/editor'
import VueColumnsResizable from 'vue-columns-resizable'
import Calendar from 'primevue/calendar'
import Listbox from 'primevue/listbox'
// import VueCompositionAPI from '@vue/composition-api'
import Toast from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'
import Textarea from 'primevue/textarea'
import Message from 'primevue/message'
// display show more option when the paragraph is too long
import ShowMoreText from 'vue-show-more-text'
import variables from './shared/variables'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Dialog from 'primevue/dialog'
import RadioButton from 'primevue/radiobutton'
import ReadMore from 'vue-read-more2'
import MarkdownEditor from '@/shared/components/MarkdownEditor.vue'
import Badge from 'primevue/badge'
import Timeline from 'primevue/timeline'
// import Chart from 'primevue/chart';
import Router from 'vue-router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import 'vue2-animate/dist/vue2-animate.min.css'
import ls from 'localstorage-slim'
import VueHtml2pdf from 'vue-html2pdf'
import * as Sentry from "@sentry/vue";
import { i18n } from '@/shared/services/I18nServices'

// enable global encryption on localStorage values
ls.config.encrypt = true;

// avoid router NavigationDuplicated console error
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}
Vue.use(Router)

Vue.use(VueCompositionAPI)
Vue.use(VueColumnsResizable)
Vue.use(ReadMore)
// For more options see below
Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
    },
})
Vue.directive('tooltip', Tooltip);

// Toast config
const toastOptions = {
    transition: 'Vue-Toastification__bounce',
    maxToasts: 20,
    newestOnTop: true,
    // position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.46,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: false,
    icon: true,
    rtl: false,
}

Vue.use(Toast, toastOptions)

Vue.config.productionTip = false

// add VueScrollTo with configuration
Vue.use(VueScrollTo, {
    el: '#list-table-custom',
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
})

// Sentry configurations (https://docs.sentry.io/platforms/javascript/guides/vue/)
//apply sentry config for encironments except for local environment to see errors on console logs when developing
if (process.env.VUE_APP_SENTRY_ENVIRONMENT !== 'development') 
Sentry.init({
    Vue,
    dsn: variables.SENTRY_CONFIG.DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    trackComponents: true,
    tracesSampleRate: 0.2,
    release: variables.SENTRY_CONFIG.RELEASE 
  });

const app = createApp({
    // eslint-disable-next-line
    render: (h: (arg0: VueConstructor<Vue>) => any) => h(App),
    i18n,
    router,
    store,
})

app.use(PrimeVue)
// app.use(i18n)

// PrimeVue components
app.component('Button', Button)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Menu', Menu)
app.component('Dropdown', Dropdown)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('PanelMenu', PanelMenu)
app.component('OverlayPanel', OverlayPanel)
app.component('InputText', InputText)
app.component('Checkbox', Checkbox)
app.component('Panel', Panel)
app.component('MultiSelect', MultiSelect)
app.component('Editor', Editor)
app.component('Textarea', Textarea)
app.component('Calendar', Calendar)
app.component('Message', Message)
app.component('Listbox', Listbox)
app.component('ShowMoreText', ShowMoreText)
app.component('Slider', Slider)
app.component('Card', Card)
app.component('TieredMenu', TieredMenu)
app.component('Dialog', Dialog)
app.component('Avatar', Avatar)
app.component('AvatarGroup', AvatarGroup)
app.component('RadioButton', RadioButton)
app.component('Divider', Divider)
app.component('Breadcrumb', Breadcrumb)
app.component('Sidebar', Sidebar)
app.component('ProgressBar', ProgressBar)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Badge', Badge)
app.component('Tag', Tag)
app.component('Timeline', Timeline)
app.component('ToggleButton', ToggleButton)
app.component('loading', VueLoading)
app.component('MarkdownEditor', MarkdownEditor)
app.component('VueHtml2pdf', VueHtml2pdf)
app.mount('#app')

// mutations enums. Mapping mutation function name.
export enum MutationTypes {
    RESET_STATES = 'initializeState',
    SET_LANGUAGE = 'setLanguage',
    SET_USERS = 'setUsers',
    SET_ROLE = 'setRole',
    SET_OPEN_MENU = 'setMenuOpen',
    SET_LEFT_MENU_SIDEBAR = 'setLeftMenuSidebar',
    SET_WIZARD_DATA = 'setWizardData',
    SET_SIGNUP_DATA = 'setSignupData',
    SET_NORM_TYPES = 'setNormTypes',
    SET_SELECTED_NORMS = 'setSelectedNorms',
    SET_ORGANISATION_NAME = 'setOrganisationName',
    SET_NETWORK_ERROR = 'setNetworkError'
}

import { ThreatDTO } from '@/dto/backend-response/threatsDTO'
import variables from '@/shared/variables'
import ls from 'localstorage-slim'

// get current role name to set when user refresh the page
const currentRoleName = ls.get(variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE_NAME, { decrypt: true })
// get wizard data from localStorage on page refresh
const wizardDataLoc = ls.get(variables.LOCAL_STORAGE_ITEMS.WIZARD_DATA, { decrypt: true })
// get organisation name from localStorage on page refresh
const orgName: string = ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION_NAME, { decrypt: true })
const menuCollapse: string = ls.get(variables.LOCAL_STORAGE_ITEMS.MENU_COLLAPSED, { decrypt: true })

// Declare states
export class State {
    networkError = false
    threatList!: Array<ThreatDTO>
    language = variables.DEFAULT_LANGUAGE
    users = []
    role = currentRoleName
    menuOpen = menuCollapse ? (menuCollapse === 'true' ? false: true) : true
    visibleLeftMenuBar = false
    wizardData = wizardDataLoc
        ? wizardDataLoc
        : {
              activeStep: 1,
              companySize: '',
              jobRole: '',
              jobRoleOther: '',
              sector: 0,
              subSector: 0,
              suggestedNorms: [],
              otherNorms: [],
              inviteUsers: [],
              twoFact: true,
              completedSteps: [],
          }
    signupData = {
        FIRSTNAME: '',
        LASTNAME: '',
        EMAIL: '',
        TELEPHONE: '',
        COMPANY_NAME: '',
        COMPANY_NUMBER: '',
        POSTCODE: '',
        CITY: '',
        TERMS_AND_CONDITIONS: false,
        PRIVACY_POLICY: false,
    }
    normTypes: []
    selectedNorms: []
    organisationName = orgName
}
// exporting state
export const state: State = new State()

// Action enums. This maping action type values must be same as mutation name(in mutation-types.ts) in order to call particular mutation functions

export enum ActionTypes {
    SET_NETWORK_ERROR = 'SET_NETWORK_ERROR',
    RESET_STATES = 'RESET_STATES',
    SET_LANGUAGE = 'SET_LANGUAGE',
    SET_USERS = 'SET_USERS',
    SET_ROLE = 'SET_ROLE',
    SET_OPEN_MENU = 'SET_OPEN_MENU',
    SET_LEFT_MENU_SIDEBAR = 'SET_LEFT_MENU_SIDEBAR',
    SET_WIZARD_DATA= 'SET_WIZARD_DATA',
    SET_SIGNUP_DATA = 'SET_SIGNUP_DATA',
    SET_NORM_TYPES = 'SET_NORM_TYPES',
    SET_SELECTED_NORMS = 'SET_SELECTED_NORMS',
    SET_ORGANISATION_NAME = 'SET_ORGANISATION_NAME'
}

import { gql } from "@apollo/client/core";

export const GET_SSO_TOKEN = gql`
mutation GetSSOToken ($idToken: String!, $flowName:String!) {
    getSSOFlowToken(flowName: $flowName, idToken: $idToken){
                token
                loginOrganisation {
                    id
                }
        status
        error
        errorCode {
            name
            value
        }
    }
}
`

export const GET_AUTH_FLOW_PARAMETERS = gql`
query GetAuthFlowParameters ($flowName: String!){
getAuthFlowParameters(flowName: $flowName) {
    parameters {
        server
        clientId
        discoveryEndpoint
        flowName
        authCodeScopes
        overrideUserInteractionPrompt
    }
    status
    error
    errorCode {
    name
    value
    }
}
}
`



































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import authService from '@/shared/services/Authentication'
import router from '@/router'
import VueQurious from 'vue-qrious'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'

export default defineComponent({
    props: {
        twofactorActivated: {},
        twofactoractivationUrl: {},
    },
    components: { VueQurious },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const TWOFACTOR_FORM = ref({
            TOKEN: '',
        })
        const errors = ref({
            TOKEN: { missingValue: false, invalidValue: false },
        })
        const loading = ref(false)
        const refToken = ref(null)

        const validateForm = () => {
            // reset errors
            errors.value = {
                TOKEN: { missingValue: false, invalidValue: false },
            }
            // missingValue check
            errors.value.TOKEN.missingValue = utils.validateFields(
                TWOFACTOR_FORM.value.TOKEN,
                'string'
            )
        }

        const submitTwoFactorAuth = async () => {
            // validate login form
            validateForm()
            // check for validation errors
            if (utils.hasErrors(errors.value)) {
                return
            }
            loading.value = true

            // validate two factor token
            let result
            try {
                result = await authService.verifyTwoFactorAuthentication(TWOFACTOR_FORM.value.TOKEN)
                loading.value = false
            } catch {
                loading.value = false
                throw Error('Error while submitting two factor autherntication')
            }

            if (result.status) {
                const loggedInUser: { user: { organisations } } = utils.ls.get(
                    variables.LOCAL_STORAGE_ITEMS.USER,
                    {
                        decrypt: true,
                    }
                )
                // logged in users organisations
                const organisations = loggedInUser && loggedInUser.user.organisations
                let isUserExpired
                /* user has only one organisation,
            - and if the organisation has completed wizard 
                - check the whether user is an active user in particular organisation.
                    - if user is not an active user, display error message in login page 
                    - if user is an active user navigate to Dashboard
            - If the oraganisation hasn't completed wizard
                - navigate to wizard*/
                if (organisations.length === 1) {
                    if (utils.wizardCompletionCheck()) {
                        isUserExpired = utils.checkUserExpire(
                            organisations[0].startDate,
                            organisations[0].endDate
                        )
                        if (isUserExpired) {
                            router.push({ name: 'Login', params: { expiredUser: 'true' } })
                        } else {
                            router.push({ name: 'Dashboard' })
                        }
                    } else {
                        router.push({ name: 'WizardPage1' })
                    }
                } else {
                    // if user has more than one organisation navigate user to `OrganisationSelect` component
                    router.push({ name: 'OrganisationSelect' })
                }

                // timer to refresh token
                setInterval(() => {
                    // refresh the token
                    authService.refreshToken()
                }, variables.SESSION.REFRESH_TOKEN_TIMEOUT)
            } else {
                errors.value.TOKEN.invalidValue = true
            }
        }

        const goBackToLogin = () => {
            router.push({ name: 'Login' })
        }
        onMounted(() => {
            if (refToken.value) {
                document.getElementById('token').focus()
            }
        })
        return {
            t,
            language,
            TWOFACTOR_FORM,
            submitTwoFactorAuth,
            errors,
            goBackToLogin,
            loading,
            refToken,
            utils,
        }
    },
})

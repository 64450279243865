import { GetterTree } from 'vuex'
import { State } from './state'

export type Getters = {
    getLanguage(state: State): string;
}

export const getters: GetterTree<State, State> & Getters = {

    getLanguage(state: State) {
        return state.language
    }
}

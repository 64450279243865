






























import { defineComponent } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'

export default defineComponent({
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])

        return {
            t,
            ...useI18n(),
            language,
            utils,
            variables,
        }
    },
})

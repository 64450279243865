import apolloClient from '@/shared/services/ApolloCLientAPI'
import { Status, UserRole } from '@/shared/enum/general-enum'
import { TaskStatus } from '@/shared/enum/tasks-enum'
import moment from 'moment'
import variables from '@/shared/variables'
import marked from '@/shared/mixins/markdown2html'
import { Interval } from '@/shared/enum/general-enum'
import countriesLoc from 'i18n-iso-countries'
// keep ls import in utils file to avoid repeating ls additional import everywhere
import ls from 'localstorage-slim'
import { useState } from './helpers'
import { RiskAssessmentDTO } from '@/dto/backend-response/riskAssessmentsDTO'
import { UserDTO } from '@/dto/backend-response/usersDTO'

// to limit string characters
const limittedText = (text: string, limit: number) => {
    if (text === null) return ''
    if (text.length > limit) {
        return text.slice(0, limit) + '...'
    }
    return text
}

// convert id values to name values
const idToName = (data: Array<{ ID: number; VALUE: string }>, value: number) => {
    if (value) {
        if (data.find((val: { ID: number }) => val.ID === value)) {
            return data.find((val: { ID: number }) => val.ID === value).VALUE
        } else {
            console.log('Value not found ' + value)
            return ''
        }
    } else {
        return ''
    }
}

// convert key values to name values
const keyToName = (data: Array<{ KEY: string; VALUE: string }>, value: string) => {
    if (value) {
        if (data.find((val: { KEY: string }) => val.KEY === value)) {
            return data.find((val: { KEY: string }) => val.KEY === value).VALUE
        } else {
            console.log('Value not found ' + value)
            return ''
        }
    } else {
        return ''
    }
}

// Check if the url is a valid url with the given protocol.
// Protocol should end in a colon, e.g. "http:" or "file:"
const isURLWithProtocol = (input: string, protocol: string): boolean => {
    try {
        // The URL constructor will throw if the parameter is not a valid url
        const url = new URL(input)
        return url.protocol === protocol
    } catch (err) {
        return false
    }
}

// Network share urls like \\hostname\some\path are valid documentation urls
// but cannot be checked with isURLWithProtocol, so check them with a regex
const isNetworkShareURL = (input: string): boolean =>
    /^(\\)(\\[\w.-_]+){2,}(\\?)$/.test(input)

const isInternalDocumentationURL = (input: string): boolean => (
    isNetworkShareURL(input) || isURLWithProtocol(input, 'file:')
)

const isExternalDocumentationURL = (input: string): boolean => (
    isURLWithProtocol(input, 'https:')  || isURLWithProtocol(input, 'http:')
)

const isValidDocumentationURL = (input: string): boolean => (
    isInternalDocumentationURL(input) || isExternalDocumentationURL(input)
)

// validate fields and return true if field has errors
const validateFields = (field, type) => {
    // Validate submitted email.
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    switch (type) {
        case 'string':
            return !field ? true : false
        case 'array':
            return field.length === 0 ? true : false
        case 'number':
            return field === 0 ? true : false
        case 'boolean':
            return !field ? true : false
        // email type validate
        case 'email':
            return !emailRegex.test(String(field).toLowerCase()) ? true : false
        case 'documentationURL':
            return !isValidDocumentationURL(field)
        default:
            return false
    }
}

const validateNumber = (event) => {
    const keyCode = event.keyCode
    if (keyCode < 48 || keyCode > 57) {
        if (keyCode != 13) {
            event.preventDefault()
        }
    }
}

//  calculate open days for action plan
const calculateDaysOpen = (endDate: Date): number => {
    const now = new Date()
    if (endDate > now) {
        return 0
    }
    const diffDays = moment.duration(moment(now).diff(moment(endDate))).asDays()
    return Math.round(diffDays)
}

// return id of username
const getUserId = (username, usersArray) => {
    return usersArray.find((val) => val.username === username).id
}

/* update assessments/action plans state
create = 1
in progress = 2
submitted = 3
approved = 4
declined = 5 */
const updateDocumentState = async (assessmentId, returnQuery, input) => {
    const mutationQuery = `mutation ($input: UpdateStateInput!) {
        updateState(id: ${assessmentId}, input: $input) {
            ${returnQuery}
                status
                error
            }
        }`
    let result
    try {
        // update data api call
        result = await apolloClient.updateGraphqlData(mutationQuery, input)
    } catch {
        return 'error'
    }
    if (result.data.status) {
        return result.data.updateState
    }

    return result.data.updateState
}

// find mouse pointer location for overlay popup
const findMousePointerLocationOP = (event: MouseEvent) => {
    // calculate top height of the page
    const top = event.pageY
    // add global style
    const style = document.createElement('style')
    style.innerHTML = `
                #label_overlay_panel{
                    top: ${top}px !important;
                    }
                #heatmap_overlay_panel {
                    top: ${top}px !important;
                }
                `

    document.querySelector('.p-sidebar') && document.querySelector('.p-sidebar').appendChild(style)
}


// Get user 2FA status
const isTwoFactorAuthenticated = () => {
    if (typeof localStorage !== 'undefined') {
        if (
            ls.get(variables.LOCAL_STORAGE_ITEMS.TWO_FACTOR_PASS, { decrypt: true }) == 'validated'
        ) {
            return true
        }
    }
    return false
}



// Check the authentication status stored in localStorage
// AUTHORIZED means we stored a token and a role
// ONLY_USER_AUTHENTICATED means we stored a token (for the user), but no role
const getStoredAuthStatus = (): 'AUTHORIZED' | 'ONLY_USER_AUTHENTICATED' | 'ANONYMOUS' | 'TWO_FACTOR_PENDING' => {
    if (typeof localStorage !== 'undefined') {
        const authToken: string = ls.get(variables.LOCAL_STORAGE_ITEMS.AUTH_TOKEN, {
            decrypt: true,
        })
        const role: string = ls.get(variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE, {
            decrypt: true,
        })
        const organisation: string = ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })

        if (authToken && authToken.length > 10) {
            //TODO: Can try a token verification endpoint. Check for overhead of usage.
            if (isTwoFactorAuthenticated()) {
                if (Number(role) || Number(organisation)) {
                    return 'AUTHORIZED'
                }
                return 'ONLY_USER_AUTHENTICATED'
            } else {
                return 'TWO_FACTOR_PENDING'
            }
        }
    }
    return 'ANONYMOUS'
}

// Get user authentication status.
const isAuthenticated = () => {
    const status = getStoredAuthStatus()
    if (status == 'AUTHORIZED' || status == 'ONLY_USER_AUTHENTICATED') {
        return true
    }
    return false
}

// Filter items by state from router query
const filterByState = (result, states) => {
    const filteredByState = states.map((state) => {
        const data = result.filter((item) => {
            // received string convert into Number type in order to match the type
            return item.state === Number(state)
        })
        return data
    })
    // merging array into one
    return [].concat(...filteredByState)
}

// get class name for states in order to display in colors
const getClassName = (state: number) => {
    switch (state) {
        case Status.created:
            return 'created'
        case Status.inprogress:
            return 'inprogress'
        case Status.submitted:
            return 'submitted'
        case Status.approved:
            return 'approved'
        case Status.ready:
            return 'ready'
        case Status.declined:
            return 'declined'
        default:
            return 'no state found'
    }
}
// get class name of tasks statuses in order to display in colors
const getTaskClassName = (state: string) => {
    switch (state) {
        case TaskStatus.TEMPLATE:
            return 'template'
        case TaskStatus.TOSTART:
            return 'to-start'
        case TaskStatus.INPROGRESS:
            return 'inprogress'
        case TaskStatus.COMPLETED:
            return 'completed'
        default:
            return 'no status found'
    }
}
// net score color set
const netRiskStyleClass = (score, clientConfigData): 'no-color' | 'net-risk-green' | 'net-risk-orange' | 'net-risk-red' => {
    if (score <= 0) return 'no-color'
    // if client configuration values are available
    if (
        clientConfigData &&
        clientConfigData.riskToleranceGreen &&
        clientConfigData.riskToleranceOrange &&
        clientConfigData.riskToleranceRed
    ) {
        if (score <= clientConfigData.riskToleranceGreen) {
            return 'net-risk-green'
        } else if (score <= clientConfigData.riskToleranceOrange) {
            return 'net-risk-orange'
        } else if (score <= clientConfigData.riskToleranceRed) {
            return 'net-risk-red'
        } else {
            return 'no-color'
        }
    } else {
        // default values
        if (score <= 9) {
            return 'net-risk-green'
        } else if (score <= 12) {
            return 'net-risk-orange'
        } else if (score <= 25) {
            return 'net-risk-red'
        } else {
            return 'no-color'
        }
    }
}
// Get a sorted copy of a list of risk assessments
const sortedRiskAssessments = (assessments: Array<RiskAssessmentDTO>): Array<RiskAssessmentDTO> => {
    return [...assessments].sort(
        (a, b) =>
            a.executionDate < b.executionDate ? 1 : -1
    )
}
 
/* Validation pages (assessments and action plan)
- Red if validation status is declined
- Red if Submitted is >= 14 days from today (so anything that is older than 14 days)
- Orange Submitted is < 14 days from today (so anything that is shorter than 14 days)
- Orange if the state is Declined
- Green if not Orange
*/
const setValidationAlertColors = (status: number, submittedDate: moment.MomentInput) => {
    let color
    const today = new Date()
    const daysOfSubmitted = moment.duration(moment(today).diff(moment(submittedDate))).asDays()

    // assessment/action plan submit due date check
    if ((status === Status.submitted && daysOfSubmitted >= 14) || status === Status.declined) {
        //red
        color = variables.ALERT_COLORS.RED
        // assessment/action plan submit due date and status check
    } else if (status === Status.submitted && daysOfSubmitted < 14) {
        // orange
        color = variables.ALERT_COLORS.ORANGE
    } else {
        // green
        color = variables.ALERT_COLORS.GREEN
    }

    return color
}
// get alert color name by it's color code
const getAlertColorByName = (alertColorCode) => {
    return Object.keys(variables.ALERT_COLORS).find(
        (key) => variables.ALERT_COLORS[key] === alertColorCode
    )
}

// disable approval section in order to avoid validator approving his own assessment
const disableApprovalSection = (username) => {
    const loggedInUser: { user: { username; organisations: [{ id; wizardCompleted }] } } = ls.get(
        variables.LOCAL_STORAGE_ITEMS.USER,
        {
            decrypt: true,
        }
    )
    if (username === (loggedInUser && loggedInUser.user.username)) {
        return true
    } else {
        return false
    }
}

// check for errors in forms
const hasErrors = (obj) => {
    const errors = []
    for (const [key] of Object.entries(obj)) {
        errors.push(obj[key])
        for (const value of Object.entries(obj[key])) {
            /* value will contain [key, value] of obj[key]. Here only value is required to access from [key, value]. 
            Hence it's value[1] */
            errors.push(value[1])
        }
    }
    //  return true if errors contains in any of fields
    return errors.includes(true)
}

/**
 * Data structure for holding error status for a form with multiple fields.
 * 
 * Each field in the error data is a map, where the value is another map
 * where names are possible errors for that form field, and values are true
 * if that error is currently set.
 */
export interface FormErrorData {
    [fieldName: string]: {
        [error: string]: boolean;
    };
}


/**
 * Collect all form errors from a form data object into an boolean array.
 */ 
const collectFormErrorValues = (formErrorData: FormErrorData): Array<boolean> => {
    return Object.values(formErrorData).flatMap(Object.values)
}


/**
 * Check that no errors are set to true in the form error data object.
 */
const noErrorsSet = (formErrorData: FormErrorData) => {
    return !collectFormErrorValues(formErrorData).includes(true)
}

// get number of days for particular interval
const getNumberOfDays = (intervalValue, intervalUnit) => {
    let numberOfDays
    switch (intervalUnit) {
        case Interval.day:
            numberOfDays = 1
            break
        case Interval.week:
            numberOfDays = 7
            break
        case Interval.month:
            numberOfDays = 31
            break
        case Interval.quarter:
            numberOfDays = 92
            break
        case Interval.year:
            numberOfDays = 366
            break
        default:
            'No unit found'
            break
    }

    return intervalValue * numberOfDays
}

/* limit selections -> selectedList - selected list array, LIMIT_NUMBER - maximum selection count */
const limitMultiSelection = (selectedList: number[], LIMIT_NUMBER: number) => {
    if (selectedList.length > LIMIT_NUMBER) {
        selectedList.pop()
    }
}

// detect a mobile device
const mobileDeviceCheck = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
}
// open left menu
const openNav = (menuOpen) => {
    const menuBarEle = document.getElementById('mySidebar')
    const mainEle = document.getElementById('main')
    if (menuOpen && !mobileDeviceCheck()) {
        ls.set(variables.LOCAL_STORAGE_ITEMS.MENU_COLLAPSED, 'false')
        if (menuBarEle) menuBarEle.style.width = '270px'
        if (mainEle) mainEle.style.marginLeft = '270px'
    } else {
        ls.set(variables.LOCAL_STORAGE_ITEMS.MENU_COLLAPSED, 'true')
        // when user is on mobile view
        if (mobileDeviceCheck()) {
            if (menuBarEle) menuBarEle.style.width = '0px'
            if (mainEle) mainEle.style.marginLeft = '0px'
        } else {
            // display icons of the left menu when the left panel is collapsed
            if (menuBarEle) menuBarEle.style.width = '80px'
            if (mainEle) mainEle.style.marginLeft = '80px'
        }
    }
}
// add padding to front for numbers
const padLeft = (pad, number) => {
    if (typeof number !== 'undefined' && number.length <= 3)
        return (pad + number).slice(-pad.length)
    else return number
}
// convert level values to percentages to display in bar
const converLevelsToPercentage = (level) => {
    return (level / 5) * 100
}

// get explanation for alert colors
const getAlertContent = (color) => {
    switch (color) {
        case variables.ALERT_COLORS.GREEN:
            return 'ALERT_INFO_GREEN'
        case variables.ALERT_COLORS.ORANGE:
            return 'ALERT_INFO_ORANGE'
        case variables.ALERT_COLORS.RED:
            return 'ALERT_INFO_RED'
        default:
            return 'No color found'
    }
}

// get names initials from full name
const getNameInitials = (fullNameOwner: string): string | null => {
    return (
        fullNameOwner &&
        fullNameOwner
            .split(' ')
            .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
            .join('')
    )
}
const countries = (language) => {
    //  register the languages you want to use to minimize the file size. doc: https://www.npmjs.com/package/i18n-iso-countries
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    countriesLoc.registerLocale(require('i18n-iso-countries/langs/' + language + '.json'))
    const list = countriesLoc.getNames(language, { select: 'official' })
    return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
}

// download file from url
const downloadFile = (url, fileName) => {
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
        })
        .catch(console.error)
}

// get error messages based on error codes
const getErrorMessage = (errorCode: number): string => {
    switch (errorCode) {
        case 5201:
            return 'SIGNUP_ERROR_ORGANISATION_EXIST'
        case 5103:
            return 'INVITE_USER_ERROR_USER_EXISTS_IN_ORG'
        case 5104:
            return 'INVITE_USER_ERROR_USER_ALREADY_INVITED'
        case 5105:
            return 'ERROR__USER_NOT_FOUND'
        case 5504:
            return 'ERROR__INVALID_2FA_CODE'
        case 5500:
            return 'ERROR__AUTHERIZATION_FAIL'
        case 5402:
            return 'ERROR_CONTROL_NUMBER_NOT_UNIQUE_FOR_NORM'

        default:
            return 'An error occured'
    }
}
// check if the organisation has completed wizard(the way to find new organisation logged in)
const wizardCompletionCheck = (): boolean => {
    /* Eventhough `loggedInUser` is using in other functions, it cannot make it as a public variable in the file 
    as only functions are getting called in this file(the entire file will not be executed) */
    const loggedInUser: { user: { username; organisations: [{ id; wizardCompleted }] } } = ls.get(
        variables.LOCAL_STORAGE_ITEMS.USER,
        {
            decrypt: true,
        }
    )
    const organisationId = ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
        decrypt: true,
    })
    // logged in users organisations
    const organisations: [{ id; wizardCompleted }] = loggedInUser && loggedInUser.user.organisations
    // get wizardCompleted status of current organisation
    const currentOrganisation =
        organisations && organisations.find((val) => val.id === organisationId)
    const wizardCompleted = currentOrganisation ? currentOrganisation.wizardCompleted : false

    // if wizard is completed, navigate to Dashboard page, or else navigate to wizard page
    if (wizardCompleted) {
        ls.set(variables.LOCAL_STORAGE_ITEMS.WIZARD_COMPLETION_STATUS, 'true')
        return true
    } else {
        ls.set(variables.LOCAL_STORAGE_ITEMS.WIZARD_COMPLETION_STATUS, 'false')
        return false
    }
}

// get filter options for entities (Only using for controls at the moment)
const getFilterOptions = async (entity: string, req: {}, controlVariables: {}) => {
    const query = `
        query filterOptions($args:OptionType){
            filterOptions(args:$args){
                ${entity}{
                    ${req}
                }
            }
        }
        `
    let result
    try {
        result = await apolloClient.getGraphqlData(query, controlVariables)
    } catch (err) {
        return { err: err }
    }
    if (result) {
        return { result: result.data.filterOptions }
    }
}
//  get last record of the list
const getLastRecord = (dataSet: { no: string | number  }[]): string | number | null => {
    if (!(dataSet.length > 0)) {
        return
    }
    // get the length of list
    const lengthOfList = dataSet.length
    // get the index of last record
    const indexOfLastRecord = lengthOfList - 1
    // get the no of last record
    return dataSet[indexOfLastRecord] && dataSet[indexOfLastRecord].no
}

/**
 * Get the "active" assessment, which determines the status of the assessed entity
 * (defined currently as the last executed assessment).
 * 
 * @param entity Entity which has an optional property assessments that contains a list of assessments
 * @returns Active item in the .assessments list or null if the list is empty or undefined
 */
const getActiveAssessment = <T extends {executionDate: Date}>(entity: {assessments?: Array<T>}): T | null => {
    if (!entity?.assessments) {
        return null
    }
    return [...entity.assessments].sort(
        (a, b) => a.executionDate < b.executionDate ? -1 : 1
    ).pop() ?? null
}


const recordsSortedOnField = <RecordType>(
    records: Array<RecordType>,
    field: keyof RecordType,
    naturalSort = false
): Array<RecordType> => {
    const collator = Intl.Collator(undefined, {numeric: true})
    const defaultCompare = (a: unknown, b: unknown) => {
        if (a < b) {
            return -1
        }
        if (a > b){
            return 1
        }
        return 0
    }
    const stringCompare = (a: unknown, b: unknown) => collator.compare(
        // Since the caller explicitly requested a string sort, we may assume
        // that the field is a string field here.
        a as string,
        b as string
    )
    const compareFunction = naturalSort ? stringCompare : defaultCompare
    return [...records].sort(
        (a: RecordType, b: RecordType) => compareFunction(a[field], b[field])
    )
}

// find the record of index based on 'next' or 'previous'
const getRecordByIndex = (
    dataSet: { no: string }[],
    typeOfButton: string,
    no: string,
    tableRef = null
    // type is any as object type can be anything based on entity
): { id; no } => {
    let recordIndex
    // find the index of current record
    const indexOfNo = dataSet.findIndex((object) => {
        return object.no === no
    })

    // get the index of next record
    if (typeOfButton === 'next') {
        recordIndex = indexOfNo + 1
        // get the index of previous record
    } else if (typeOfButton === 'previous') {
        recordIndex = indexOfNo - 1
    } else {
        recordIndex = indexOfNo
    }
    // get record from copyOfTableData based on index
    const record = tableRef.copyOfTableData[recordIndex]
    // make active row of table
    if (record) tableRef.activeRow(record.no)
    return record
}

// check user expiration based on user startDate and endDate
const checkUserExpire = (startDate: Date, endDate: Date): boolean => {
    const today = new Date()
    if (!startDate) return true
    // expire if today date is not within startDate and endDate
    if (endDate) {
        return !(today >= new Date(startDate) && today <= new Date(endDate))
    } else {
        // expire if startDate is a future date
        return today <= new Date(startDate)
    }
}

// update element's position of an object array
const updateElementPositionOfArray = (dataSet: { value: null; key: number }[], value, toIndex) => {
    // find the index of value which want to change position
    const fromIndex = dataSet.findIndex((x) => x.value === value)
    // remove it from existing array and capture the removed element
    const element = dataSet.splice(fromIndex, 1)[0]
    // switch in into nex position (toIndex)
    dataSet.splice(toIndex, 0, element)
}

// add delay to scroll (infinity scroll)
const debounce = (
    fn: { apply: (context, args) => void },
    delay: number,
    context: null
): ((...args) => void) => {
    let timer: NodeJS.Timeout

    return (...args) => {
        clearTimeout(timer)

        timer = setTimeout(() => {
            fn.apply(context, args)
        }, delay)
    }
}


/**
 * Get translation key for the entity info message in the context of a specific view.
 * @param {string} entityName - The entity name in kebab-case (see source).
 * @param {string} routeName - The name of the active route as defined in `router/index.ts`
 * @return {string} The translation key for this combination.
 */
const getInfoTextTranslationKeyFor = (entityName: string, routeName: string): string => {
    switch (entityName) {
        case 'action-plan':
            switch(routeName) {
                case 'ValidateControlAssessments':
                    return 'ACTION_PLAN_INFO_TEXT_CONTROL_ASSESSMENTS_VALIDATION'
                case 'ValidateActionPlans':
                    return 'ACTION_PLAN_INFO_TEXT_VALIDATION'
                case 'ControlAssessments':
                    return 'ACTION_PLAN_INFO_TEXT_CONTROL_ASSESSMENTS'
                case 'Controls':
                    return 'ACTION_PLAN_INFO_TEXT_CONTROL_DETAILS'
                default:
                    return 'ACTION_PLAN_INFO_TEXT'
            }
        case 'control-assessment':
            switch(routeName) {
                case 'ValidateControlAssessments':
                    return 'CONTROL_ASSESSMENT_INFO_TEXT_VALIDATION'
                case 'ControlAssessments':
                    return 'CONTROL_ASSESSMENT_INFO_TEXT_RISK_MANAGEMENT'
                case 'Controls':
                    return 'CONTROL_ASSESSMENT_INFO_TEXT_CONTROL_DETAILS'
                default:
                    return 'CONTROL_ASSESSMENT_INFO_TEXT'
            }
        case 'asset-control-assessment':
            switch (routeName) {
                case 'ValidateAssetControlAssessments':
                    return 'ASSET_CONTROL_ASSESSMENT_INFO_TEXT_VALIDATION'
                case 'AssetControlAssessments':
                    return 'ASSET_CONTROL_ASSESSMENT_INFO_TEXT_RISK_MANAGEMENT'
                case 'Controls':
                    return 'ASSET_CONTROL_ASSESSMENT_INFO_TEXT_CONTROL_DETAILS'
                case 'Assets':
                    return 'ASSET_CONTROL_ASSESSMENT_INFO_TEXT_ASSET_DETAILS'
                default:
                    return 'ASSET_CONTROL_ASSESSMENT_INFO_TEXT'
            }
        case 'msr-assessment':
            switch(routeName) {
                case 'MSRequirements':
                    return 'MSR_ASSESSMENTS_MSR_DETAILS_INFO_TEXT'
                case 'ValidateMSRAssessments':
                    return 'MSR_ASSESSMENTS_VALIDATION_INFO_TEXT'
                default:
                    return 'MSR_ASSESSMENTS_MS_INFO_TEXT'
            }
        case 'msr-action-plan':
            switch(routeName) {
                case 'ValidateMSRAssessments':
                    return 'ACTION_PLAN_INFO_TEXT_MSR_ASSESSMENTS_VALIDATION'
                case 'ValidateMSRActionPlans':
                    return 'MSR_ACTION_PLAN_INFO_TEXT_VALIDATION'
                case 'MSRAssessments':
                    return 'ACTION_PLAN_INFO_TEXT_MSR_ASSESSMENTS'
                case 'MSRequirements':
                    return 'ACTION_PLAN_INFO_TEXT_MSR_DETAILS'
                default:
                    return 'MSR_ACTION_PLAN_INFO_TEXT'
            }
        case 'risk-assessment':
            switch(routeName) {
                case 'Risks':
                    return 'RISK_ASSESSMENT_INFO_TEXT_RISK_DETAILS'
                    case 'RiskAssessments':
                        return 'RISK_ASSESSMENT_INFO_TEXT_RISK_ANALYSIS'
                case 'ValidateRiskAssessments':
                    return 'RISK_ASSESSMENT_INFO_TEXT_VALIDATION'
                default:
                    return 'RISK_ASSESSMENT_INFO_TEXT'

            }
        case 'default':
            return ''
    }

}


// get formatted user name (name with inactive indicator)
const getUserFname = (userId: number) => {
    const { users } = useState(['users'])
    const owner = users.value.find((employee) => employee.id === userId)

    if (owner) {
        return owner.fname
    }
}

/* check if the given text is already exists in the list array
(case in-sensitively and removing space around the text). */
const checkItemAlreadyExists = (list: string[], text: string) => {
    const index = list.findIndex((item) => text.toLowerCase().trim() === item.toLowerCase().trim())
    return index > -1
}

// get alert color, info and alertColor name of each entities
const getAlertInfo = (
    menuType: string,
    entityDetails: {
        validationAlertColor?: { color: string; info: string; infoKey: string };
        alertColor?: { color: string; info: string; infoKey: string };
    }
) => {
    // return if `entityDetails` are not available
    if (!entityDetails) {
        return { color: '', info: '', infoKey: '', alertColorName: '' }
    }
    // if user navigates to the component via validation menu, use `validationAlertColor`, else `alertColor`
    if (menuType === 'menu-validation') {
        return entityDetails.validationAlertColor
            ? {
                  color: variables.ALERT_COLORS[entityDetails.validationAlertColor.color],
                  info: entityDetails.validationAlertColor.info,
                  infoKey: entityDetails.validationAlertColor.infoKey,
                  // get the name of alert color in order to use in filter
                  alertColorName: entityDetails.validationAlertColor.color,
              }
            : { color: '', info: '', infoKey: '', alertColorName: '' }
    } else {
        return entityDetails.alertColor
            ? {
                  color: variables.ALERT_COLORS[entityDetails.alertColor.color],
                  info: entityDetails.alertColor.info,
                  infoKey: entityDetails.alertColor.infoKey,
                  // get the name of alert color in order to use in filter
                  alertColorName: entityDetails.alertColor.color,
              }
            : { color: '', info: '', infoKey: '', alertColorName: '' }
    }
}
// sort list by alphabetic order
const sortbyAlphabeticOrder = (dataList: Array<{}>, key: string): Array<{}> => {
    return dataList.sort((a, b) => {
        if (a[key] < b[key]) {
            return -1
        }
        if (a[key] > b[key]) {
            return 1
        }
        return 0
    })
}

/* display delete icon if logged in user role is Perium admin for assessments and actionplan. 
Display delete icon for other entities if logged in user role is Perium admin and if they are user created items. 
Having role condition is enough for entities as delete icon is placed in a section which displays only for user created items */
const displayDelete = () => {
    const { role } = useState(['role'])
    return role.value === UserRole.PERIUM_ADMIN
}

// strings with numeric numbers sort by ascending order(This is called as 'natural compare')
const naturalCompare = (dataSet, field: string | number): [] => {
    return dataSet.sort((a, b) => {
        // if the comparison value is a number, exit
        if (typeof a[field] === 'number') {
            return
        }
        if (a[field]) return a[field].localeCompare(b[field], undefined, { numeric: true })
    })
}

// dynamically calculate available width for full desciption
const resetWidth = (
    titleWrapId: string,
    entityNumberClassName: string,
    titleDescriptionClassName: string
): number | undefined => {
    const titleWrap = document.querySelector('#' + titleWrapId)
    const entityNumber = document.querySelector('#' + titleWrapId + ' .' + entityNumberClassName)
    const titleDescription = document.querySelector(
        '#' + titleWrapId + ' .' + titleDescriptionClassName
    )
    if (titleWrap === null || entityNumber === null || titleDescription === null) {
        return
    }
    const parentWidth = titleWrap.clientWidth
    // full width of all elements exclude full desciption.
    const leftWidth = entityNumber.clientWidth + titleDescription.clientWidth
    const margin = 40 // safe margin in px to show elipsis
    // fullWidth is equal to "leftWidth + margin + fullDescriptionWidth"
    // because of that we can calculate width of fullDescription as follows
    return parentWidth - leftWidth - margin
}

const getLoggedInUser = (): UserDTO => {
    // TODO: When current user is available in store, replace calls to this function with getters
    const userData = ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
        decrypt: true,
    }) as {user: UserDTO } | undefined
    if (userData?.user) {
        return userData.user
    }
}

const getOrganisationId = (): number => {
    return ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
        decrypt: true,
    })
}

const getEmployees = (users: Array<UserDTO>): Array<UserDTO> => {
    if (!users) return []
    const organisationId = getOrganisationId()
    const isEmployeeForCurrentOrg = (user: UserDTO) => {
        // Return true if user has role EMPLOYEE for the current org
        // If not, one of the find calls will return undefined which is falsy
        return user.organisations.find(
            (org) => org.id === organisationId
        )?.roles?.find(
            (role) => role.name === UserRole.EMPLOYEE
        )
    }
    return users.filter(isEmployeeForCurrentOrg)
}

const getFormattedControlFilters = (filterObject: {[key: string]: unknown}) => {
    const applicableArray = filterObject.applicable;
  
    if (Array.isArray(applicableArray)) 
      filterObject.applicable = applicableArray.length === 1 ? applicableArray[0] : null;
    
    return filterObject;
}

export default {
    limittedText,
    idToName,
    validateFields,
    calculateDaysOpen,
    getUserId,
    updateDocumentState,
    findMousePointerLocationOP,
    filterByState,
    getClassName,
    netRiskStyleClass,
    setValidationAlertColors,
    getStoredAuthStatus,
    isAuthenticated,
    keyToName,
    disableApprovalSection,
    hasErrors,
    marked,
    ls,
    getNumberOfDays,
    limitMultiSelection,
    openNav,
    mobileDeviceCheck,
    padLeft,
    converLevelsToPercentage,
    getAlertContent,
    getNameInitials,
    validateNumber,
    countries,
    getAlertColorByName,
    downloadFile,
    getErrorMessage,
    wizardCompletionCheck,
    getFilterOptions,
    recordsSortedOnField,
    getLastRecord,
    getActiveAssessment,
    getRecordByIndex,
    checkUserExpire,
    updateElementPositionOfArray,
    debounce,
    getUserFname,
    checkItemAlreadyExists,
    getAlertInfo,
    sortbyAlphabeticOrder,
    displayDelete,
    naturalCompare,
    getTaskClassName,
    resetWidth,
    sortedRiskAssessments,
    getLoggedInUser,
    getOrganisationId,
    getEmployees,
    isInternalDocumentationURL,
    isExternalDocumentationURL,
    isValidDocumentationURL,
    getInfoTextTranslationKeyFor,
    noErrorsSet,
    getFormattedControlFilters
}



































































































































































































import { Ref, computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import dashboardQuery from '@/shared/queries/dashboardQueries'
import { ParentSectorDTO, SectorDTO, SuggestedNormDTO } from '@/dto/backend-response/generalDTO'


// get sectors => subsectors => norms
const getSectors = async (): Promise<ParentSectorDTO[]> => {
    const sectorQuery = `
        query {
            sectors{
                ${dashboardQuery.WIZARD_STEP3_SECTORS}
            }
        }
    `
    let result
    try {
        result = await apolloClient.getGraphqlData(sectorQuery)
    } catch {
        throw Error('Error while retrieving sectors')
    }
    if (result) {
        return result.data.sectors
    }
}

export default defineComponent({
    props: {
        editable: {
            type: Boolean,
        },
        wizardData: {
            type: Object,
        },
        step: {
            type: Number,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { SET_WIZARD_DATA } = useActions(['SET_WIZARD_DATA'])
        const sectorData: Ref<ParentSectorDTO[]> = ref([])
        const compSubSectorDropdown: Ref<SectorDTO[]> = ref([])
        const COMPANY_SECTOR: Ref<ParentSectorDTO | null> = ref(null)
        const COMPANY_SUB_SECTOR: Ref<SectorDTO | null> = ref(null)
        const SELECTED_SUGGESTED_NORMS: Ref<number[]> = ref([])
        const SELECTED_OTHER_NORMS: Ref<number[]> = ref([])
        const SELECTED_MSR_NORMS: Ref<number[]> = ref([])
        const errors = ref({
            COMPANY_SUB_SECTOR: { missingValue: false },
            COMPANY_SECTOR: { missingValue: false },
            NORMS: { missingValue: false },
        })
        const labelInfomationOpNorm = ref(null)
        const normInfoContent = ref('')
        const msrNorms = ref([])

        const subSectorOptions: Ref<SectorDTO[]> = computed(
            () => COMPANY_SECTOR.value?.subSectors ?? []
        )

        const suggestedNormsOptions: Ref<SuggestedNormDTO[]> = computed(
            () => {
                const sector = COMPANY_SECTOR.value;
                const subSector = COMPANY_SUB_SECTOR.value;
                if (subSector?.suggestedNorms?.length) {
                    return subSector.suggestedNorms
                }
                if (sector?.suggestedNorms?.length) {
                    return sector.suggestedNorms
                }
                return []
            }
        )

        const otherNormsOptions: Ref<SuggestedNormDTO[]> = computed(
            () => {
                const sector = COMPANY_SECTOR.value;
                const subSector = COMPANY_SUB_SECTOR.value;
                if (subSector?.otherNorms?.length) {
                    return subSector.otherNorms
                }
                if (sector?.otherNorms?.length) {
                    return sector.otherNorms
                }
                return []
            },
        )
        watch(
            () => suggestedNormsOptions.value,
            (options) => SELECTED_SUGGESTED_NORMS.value = SELECTED_SUGGESTED_NORMS.value.filter(
                normId => options.find(option => option.id == normId)
            )
        )
        watch(
            () => otherNormsOptions.value,
            (options) => SELECTED_OTHER_NORMS.value = SELECTED_OTHER_NORMS.value.filter(
                normId => options.find(option => option.id == normId)
            )
        )
        // validate company size field
        const validateFields = () => {
            errors.value = {
                COMPANY_SUB_SECTOR: { missingValue: false },
                COMPANY_SECTOR: { missingValue: false },
                NORMS: { missingValue: false },
            }
            errors.value.COMPANY_SECTOR.missingValue = utils.validateFields(
                COMPANY_SECTOR.value?.key,
                'string'
            )
            if (COMPANY_SECTOR.value && compSubSectorDropdown.value.length)
                // validate subsector and norms if user has selected a company sector and that sector has subsectors
                errors.value.COMPANY_SUB_SECTOR.missingValue = utils.validateFields(
                    COMPANY_SUB_SECTOR.value?.key,
                    'string'
                )
            // combinedNorms = suggested norms + other norms
            const combinedNorms = SELECTED_SUGGESTED_NORMS.value.concat(SELECTED_OTHER_NORMS.value)
            if (COMPANY_SECTOR.value)
                // Check that there is at least one selected norm
                errors.value.NORMS.missingValue = utils.validateFields(combinedNorms, 'array')
        }

        // store step 3 data
        const next = () => {
            // check for validation errors
            validateFields()
            if (utils.hasErrors(errors.value)) {
                return
            }
            const step3Data = {
                sector: COMPANY_SECTOR.value?.key,
                subSector: COMPANY_SUB_SECTOR.value?.key,
                suggestedNorms: SELECTED_SUGGESTED_NORMS.value,
                otherNorms: SELECTED_OTHER_NORMS.value,
                selectedMsrNorms: SELECTED_MSR_NORMS.value,
            }
            SET_WIZARD_DATA(step3Data)
            emit('next', { step: props.step, formData: step3Data })
        }

        const previous = () => {
            emit('previous', props.step)
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, info) => {
            utils.findMousePointerLocationOP(event)
            normInfoContent.value = info

            //  toggle overlay popup for each label field(reference)
            labelInfomationOpNorm.value.toggle(event)
        }

        const getMSRNorms = async () => {
            msrNorms.value = []
            const getMSRNormsQuery = `
                query{
                    msrNorms{
                        items{
                            id
                            name
                        }
                        
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getMSRNormsQuery)
            } catch (err) {
                throw Error('Error while retrieving msr norms')
            }
            if (result) {
                const normsDataResult = result
                // Sentry issue https://stekz.sentry.io/issues/3872847306/?project=6370116 return empty array instead of null
                const normsResultData = normsDataResult?.data?.msrNorms?.items ?? []
                const uniqueNorms = normsResultData
                    .slice()
                    .filter(
                        (element: { name: string }, index: number) =>
                            normsResultData.findIndex(
                                (obj: { name: string }) => obj.name === element.name
                            ) === index
                    )
                msrNorms.value = uniqueNorms
            }
        }

        // set wizard data on refresh
        watch(
            () => Object.assign({}, props.wizardData),
            async () => {
                getMSRNorms()
                sectorData.value = await getSectors()
                if (props.wizardData) {
                    COMPANY_SECTOR.value = sectorData.value.find(sector => sector.key === props.wizardData.sector)
                    COMPANY_SUB_SECTOR.value = COMPANY_SECTOR.value?.subSectors?.find(
                        sector => sector.key === props.wizardData.subSector
                    )

                    SELECTED_SUGGESTED_NORMS.value = Array.isArray(props.wizardData?.suggestedNorms)
                        ? props.wizardData.suggestedNorms
                        : []
                    SELECTED_OTHER_NORMS.value = Array.isArray(props.wizardData?.otherNorms)
                        ? props.wizardData.otherNorms
                        : []
                    SELECTED_MSR_NORMS.value = Array.isArray(props.wizardData?.selectedMsrNorms) 
                        ? props.wizardData?.selectedMsrNorms 
                        : []
                }
            },
            { immediate: true }
        )

        return {
            t,
            language,
            next,
            COMPANY_SUB_SECTOR,
            COMPANY_SECTOR,
            SELECTED_SUGGESTED_NORMS,
            SELECTED_OTHER_NORMS,
            SELECTED_MSR_NORMS,
            sectorData,
            suggestedNormsOptions,
            otherNormsOptions,
            subSectorOptions,
            msrNorms,
            previous,
            errors,
            labelInfomationOpNorm,
            toggleInformation,
            normInfoContent,
        }
    },
})

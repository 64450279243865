

























































































import { Ref, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import dashboardQuery from '@/shared/queries/dashboardQueries'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'

interface Tile {
    id: number;
    entity: string;
    name: string;
    icon?: string;
    total: number;
    informationOp: Ref;
    details: Array<{
        section: boolean;
        data: Array<{
            label: string;
            value: number;
        }>;
    }>;
}

export default defineComponent({
    props: {
        organisationName: String,
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tiles: Ref<Array<Tile | null>> = ref([
            // Set some value immediately so we can show the empty tiles
            // while loading
            null,
            null,
            null
        ])

        const activeItems = ref({
            risk: {
                ownerAssigned: 0,
                assessmentsPerformed: 0,
                assessmentsToBePerformed: 0,
                assessmentsInProgress: 0,
                assessmentsToBeReviewed: 0,
                assessmentsRejected: 0,
                total: 0,
            },
            control: {
                ownerAssigned: 0,
                assessmentsPerformed: 0,
                assessmentsToBePerformed: 0,
                assessmentsInProgress: 0,
                assessmentsToBeReviewed: 0,
                assessmentsRejected: 0,
                total: 0,
            },
            actionPlan: {
                toBeCreated: 0,
                inProgress: 0,
                submitted: 0,
                approved: 0,
                rejected: 0,
                overdue: 0,
                ready: 0,
                total: 0,
            },
        })
        const loading = ref(false)
        const riskPercentageInfomationOP = ref(null)
        const controlPercentageInfomationOP = ref(null)
        const actionplanPercentageInfomationOP = ref(null)

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)
            //  toggle overlay popup
            field.value.toggle(event)
        }

        // set active item tile info
        const setTiles = () => {
            const activeItemRisk = activeItems.value.risk
            const activeItemControl = activeItems.value.control
            const activeItemsActionplan = activeItems.value.actionPlan

            tiles.value = [
                // risk
                {
                    id: 1,
                    entity: 'risk',
                    name: t('DASHBOARD_ACTIVE_ITEMS_RISK_TITLE', language.value),
                    total: activeItemRisk && activeItemRisk.total,
                    informationOp: riskPercentageInfomationOP,
                    details: [
                        // 1st section
                        {
                            section: true,
                            data: [
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_RISK_OWNERSHIP_ASSIGNED',
                                        language.value
                                    ),
                                    value: activeItemRisk && activeItemRisk.ownerAssigned,
                                },
                                ],
                        },
                        // 2nd section
                        {
                            section: true,
                            data: [
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_RISK_ASSESSMENTS_PERFORMED',
                                        language.value
                                    ),
                                    value: activeItemRisk && activeItemRisk.assessmentsPerformed,
                                },
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_RISK_ASSESSMENTS_TOBE_PERFORMED',
                                        language.value
                                    ),
                                    value:
                                        activeItemRisk && activeItemRisk.assessmentsToBePerformed,
                                },
                            ],
                        },
                        // 3rd section
                        {
                            section: true,
                            data: [
                            {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_RISK_ASSESSMENTS_IN_PROGRESS',
                                        language.value
                                    ),
                                    value: activeItemRisk && activeItemRisk.assessmentsInProgress,
                                },
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_RISK_ASSESSMENTS_TOBE_REVIEWED',
                                        language.value
                                    ),
                                    value: activeItemRisk && activeItemRisk.assessmentsToBeReviewed,
                                },
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_RISK_REJECTED_ASSESSMENTS',
                                        language.value
                                    ),
                                    value: activeItemRisk && activeItemRisk.assessmentsRejected,
                                },
                            ],
                        },
                    ],
                },
                // control
                {
                    id: 3,
                    entity: 'control',
                    name: t('DASHBOARD_ACTIVE_ITEMS_CONTROLS_TITLE', language.value),
                    icon: 'fas fa-eye',
                    total: activeItemControl && activeItemControl.total,
                    informationOp: controlPercentageInfomationOP,
                    details: [
                        // 1st section
                        {
                            section: true,
                            data: [
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_CONTROL_OWNERSHIP_ASSIGNED',
                                        language.value
                                    ),
                                    value: activeItemControl && activeItemControl.ownerAssigned,
                                },
                            ],
                        },
                        // 2nd section
                        {
                            section: true,
                            data: [
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_CONTROL_ASSESSMENTS_PERFORMED',
                                        language.value
                                    ),
                                    value:
                                        activeItemControl && activeItemControl.assessmentsPerformed,
                                },
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_CONTROL_ASSESSMENTS_TOBE_PERFORMED',
                                        language.value
                                    ),
                                    value:
                                        activeItemControl &&
                                        activeItemControl.assessmentsToBePerformed,
                                },
                            ],
                        },
                        // 3rd section
                        {
                            section: true,
                            data: [
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_CONTROL_ASSESSMENTS_TOBE_REVIEWED',
                                        language.value
                                    ),
                                    value:
                                        activeItemControl &&
                                        activeItemControl.assessmentsToBeReviewed,
                                },
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_CONTROL_REJECTED_ASSESSMENTS',
                                        language.value
                                    ),
                                    value:
                                        activeItemControl && activeItemControl.assessmentsRejected,
                                },
                            ],
                        },
                    ],
                },
                // action plan
                {
                    id: 5,
                    entity: 'actionplan',
                    name: t('DASHBOARD_ACTIVE_ITEMS_ACTIONPLANS_TITLE', language.value),
                    icon: 'fas fa-bullhorn',
                    total: activeItemsActionplan && activeItemsActionplan.total,
                    informationOp: actionplanPercentageInfomationOP,
                    details: [
                        // 1st section
                        {
                            section: true,
                            data: [
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_ACTION_PLAN_TOBE_CREATED',
                                        language.value
                                    ),
                                    value:
                                        activeItemsActionplan && activeItemsActionplan.toBeCreated,
                                },
                            ],
                        },
                        // 2nd section
                        {
                            section: true,
                            data: [
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_ACTION_PLAN_INPROGRESS',
                                        language.value
                                    ),
                                    value:
                                        activeItemsActionplan && activeItemsActionplan.inProgress,
                                },
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_ACTION_PLAN_SUBMITTED',
                                        language.value
                                    ),
                                    value: activeItemsActionplan && activeItemsActionplan.submitted,
                                },
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_ACTION_PLAN_APPROVED',
                                        language.value
                                    ),
                                    value: activeItemsActionplan && activeItemsActionplan.approved,
                                },
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_ACTION_PLAN_REJECTED',
                                        language.value
                                    ),
                                    value: activeItemsActionplan && activeItemsActionplan.rejected,
                                },
                            ],
                        },
                        // 3rd section
                        {
                            section: true,
                            data: [
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_ACTION_PLAN_LATE',
                                        language.value
                                    ),
                                    value: activeItemsActionplan && activeItemsActionplan.overdue,
                                },
                                {
                                    label: t(
                                        'DASHBOARD_ACTIVE_ITEMS_ACTION_PLAN_READY',
                                        language.value
                                    ),
                                    value: activeItemsActionplan && activeItemsActionplan.ready,
                                },
                            ],
                        },
                    ],
                },
            ]
        }

        // get active percentages for each entities
        const getActiveItemsInfo = async () => {
            loading.value = true
            const getactiveItemCount = `
                query{
                    progressIndicators{
                        ${dashboardQuery.ACTIVE_ITEMS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getactiveItemCount)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving progress indicator items')
            }
            if (result) {
                activeItems.value = result.data.progressIndicators
            }
        }

        // watch for language change from store and set tiles for roles
        watch(language, () => {
            setTiles()
        })

        onMounted(async () => {
            await getActiveItemsInfo()
            setTiles()
        })

        return {
            t,
            language,
            tiles,
            loading,
            riskPercentageInfomationOP,
            controlPercentageInfomationOP,
            actionplanPercentageInfomationOP,
            toggleInformation,
        }
    },
})
















































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import jobFuntions from '@/assets/data/wizard-data.json'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    props: {
        editable: {
            type: Boolean,
        },
        wizardData: {
            type: Object,
        },
        step: {
            type: Number,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const jobRolesDropdown = ref([])
        const JOB_ROLE_SELECT = ref('')
        const JOB_ROLE_OTHER = ref('')
        const jobRole = ref('')
        const { SET_WIZARD_DATA } = useActions(['SET_WIZARD_DATA'])
        const errors = ref({
            JOB_ROLE_SELECT: { missingValue: false },
            JOB_ROLE_OTHER: { missingValue: false },
        })

        // validate company size field
        const validateFields = () => {
            errors.value.JOB_ROLE_SELECT.missingValue = utils.validateFields(
                JOB_ROLE_SELECT.value,
                'string'
            )
            // make text field missingValue only if user has selected `OTHER` from dropdown
            if (JOB_ROLE_SELECT.value === 'OTHER') {
                errors.value.JOB_ROLE_OTHER.missingValue = utils.validateFields(
                    JOB_ROLE_OTHER.value,
                    'string'
                )
            }
        }

        const next = () => {
            // check for validation errors
            validateFields()
            if (utils.hasErrors(errors.value)) {
                return
            }
            // set store values (dropdown value / text value)
            SET_WIZARD_DATA({ jobRole: JOB_ROLE_SELECT.value, jobRoleOther: JOB_ROLE_OTHER.value })
            emit('next', {
                step: props.step,
                formData: {
                    jobFunction: JOB_ROLE_SELECT.value,
                    jobRoleOther: JOB_ROLE_OTHER.value,
                },
            })
        }

        const previous = () => {
            emit('previous', props.step)
        }

        // set job roles dropdown values
        const setJobRolesDropdown = () => {
            jobRolesDropdown.value = []

            jobFuntions.JOB_FUNCTIONS.map((jobrole) => {
                jobRolesDropdown.value.push({
                    key: jobrole.KEY,
                    value: t(jobrole.VALUE, language.value),
                })
            })
        }
        // set wizard data on refresh
        watch(
            () => Object.assign({}, props.wizardData),
            () => {
                if (props.wizardData) {
                    JOB_ROLE_SELECT.value = props.wizardData.jobRole
                    JOB_ROLE_OTHER.value = props.wizardData.jobRoleOther
                }
            },
            { immediate: true }
        )

        onMounted(() => {
            setJobRolesDropdown()
        })
        return {
            t,
            language,
            jobRolesDropdown,
            JOB_ROLE_SELECT,
            next,
            JOB_ROLE_OTHER,
            jobFuntions,
            jobRole,
            previous,
            errors,
        }
    },
})

// norm type
export enum NormType {
    INFORMATION_SECURITY = "INFORMATION_SECURITY",
    PRIVACY = "PRIVACY",
    FINANCIAL = "FINANCIAL",
    STRATEGIC = "STRATEGIC",
    OPERATIONAL = "OPERATIONAL",
    STAFF = "STAFF",
    PURCHASE = "PURCHASE",
    MARKETING = "MARKETING",
    OUTSOURCING = "OUTSOURCING",
    WEALTH_MANAGEMENT = "WEALTH_MANAGEMENT",
    WORKING_CONDITIONS = "WORKING_CONDITIONS",
    INTEGRITY = "INTEGRITY",
    OTHER = "OTHER"
}

export enum Norms {
    NORM_1 = "NORM_ISO27001_27002",
    NORM_2 = "NORM_NEN7510",
    NORM_3 = "NORM_NEN7512",
    NORM_4 = "NORM_NEN7513",
    NORM_5 = "NORM_DIGID",
    NORM_6 = "NORM_ICT_SECURITY",
    NORM_7 = "NORM_ISO27701",
    NORM_8 = "NORM_ASSESSMENT_FRAMEWORK_IGJ",
    NORM_9 = "NORM_DNB",
    NORM_10 = "NORM_BIO",
    NORM_11 = "NORM_IBP",
    NORM_12 = "NORM_BIC",
    NORM_13 = "NORM_NTA7516",
}









































import { defineComponent, Ref, ref, watch } from '@vue/composition-api'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import dashboardQuery from '@/shared/queries/dashboardQueries'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import variables from '@/shared/variables'
import utils from '@/shared/mixins/utils'
import { UserRole } from '@/shared/enum/general-enum'

export default defineComponent({
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const openItemCount = ref({})
        const tiles: Ref = ref([])
        const { role } = useState(['role'])
        const loading = ref(false)
        // get logged in user infomation
        const loggedInUserInfo: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER)
        // get user id of logged in user
        const userId = loggedInUserInfo.user.id
        // get logged in user
        const loggedInUser: { user: { firstName; lastName } } = utils.ls.get(
            variables.LOCAL_STORAGE_ITEMS.USER,
            {
                decrypt: true,
            }
        )
        // set logged in user full name
        const userFullName =
            (loggedInUser && loggedInUser.user.firstName) +
            ' ' +
            (loggedInUser && loggedInUser.user.lastName)
        const totalItemCount = ref(0)

        // get total open items for user
        const getTotalOpenedItem = (openitems: [{ openItemCount: { user: number } }]) => {
            let count = 0

            for (const item in openitems) {
                count =
                    count + (openitems[item].openItemCount && openitems[item].openItemCount.user)
            }
            return count
        }

        // when the logged in user role is Employee, setting tiles
        const employeeTiles = () => {
            tiles.value = [
                {
                    id: 1,
                    name: t('HOME_MY_RISK', language.value),
                    router: {
                        name: 'Risks',
                        query: { loggedInUser: userFullName, alertColor: 'RED' },
                    },
                    openItemCount: openItemCount.value['risk'],
                },
                {
                    id: 2,
                    name: t('HOME_MY_RISK_ASSESSMENTS', language.value),
                    router: {
                        name: 'RiskAssessments',
                        query: { alertColor: ['RED', 'ORANGE'], loggedInUser: userFullName },
                    },
                    openItemCount: openItemCount.value['riskAssessment'],
                },
                {
                    id: 3,
                    name: t('HOME_MY_CONTROLS', language.value),
                    router: {
                        name: 'Controls',
                        query: { alertColor: ['RED'], loggedInUser: String(userId) },
                    },
                    openItemCount: openItemCount.value['control'],
                },
                {
                    id: 4,
                    name: t('HOME_MY_CONTROL_ASSESSMENTS', language.value),
                    router: {
                        name: 'ControlAssessments',
                        query: { alertColor: ['RED', 'ORANGE'], loggedInUser: userFullName },
                    },
                    openItemCount: openItemCount.value['controlAssessment'],
                },
                {
                    id: 5,
                    name: t('HOME_MY_ACTION_PLANS', language.value),
                    router: {
                        name: 'ImproveControls',
                        query: { alertColor: ['RED', 'ORANGE'], loggedInUser: userFullName },
                    },
                    openItemCount: openItemCount.value['actionPlan'],
                },
            ]
            totalItemCount.value = getTotalOpenedItem(tiles.value)
        }

        // when the logged in user role is Valodator, setting tiles
        const validatorTiles = () => {
            tiles.value = [
                {
                    id: 1,
                    name: t('HOME_REVIEW_RISK_ASSESSMENTS', language.value),
                    router: {
                        name: 'ValidateRiskAssessments',
                        query: {
                            alertColor: ['RED', 'ORANGE'],
                            loggedInUser: userFullName,
                            status: [t('SUBMITTED', language)]
                        },
                    },
                    openItemCount: openItemCount.value['riskAssessment'],
                },
                {
                    id: 2,
                    name: t('HOME_REVIEW_CONTROL_ASSESSMENTS', language.value),
                    router: {
                        name: 'ValidateControlAssessments',
                        query: {
                            alertColor: ['RED', 'ORANGE'],
                            loggedInUser: userFullName,
                            status: [t('SUBMITTED', language)]
                        },
                    },
                    openItemCount: openItemCount.value['controlAssessment'],
                },
                {
                    id: 3,
                    name: t('HOME_REVIEW_ACTIONPLANS', language.value),
                    router: {
                        name: 'ValidateActionPlans',
                        query: {
                            alertColor: ['RED', 'ORANGE'],
                            loggedInUser: userFullName,
                            status: [t('SUBMITTED', language)]
                        },
                    },
                    openItemCount: openItemCount.value['actionPlan'],
                },
            ]
            totalItemCount.value = getTotalOpenedItem(tiles.value)
        }

        // calling functions for set tiles based on logged in user roles
        const setTilesForRoles = () => {
            switch (role.value) {
                case UserRole.EMPLOYEE:
                    employeeTiles()
                    break
                case UserRole.VALIDATOR:
                    validatorTiles()
                    break
                default:
                    'No role found'
                    break
            }
        }

        //  get open items count for the logged in user and toltal open items for dashboard action items
        const getOpenItemsCount = async () => {
            loading.value = true
            const getOpenItemCount = `
                query{
                    openItemsCount{
                        ${dashboardQuery.OPEN_ITEMS_COUNT}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getOpenItemCount)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving open item counts')
            }
            if (result) {
                openItemCount.value = result.data.openItemsCount
            }
        }

        // watch for language change from store and set tiles for roles
        watch(language, () => {
            setTilesForRoles()
        })

        // watch for role change from store and get data, set tiles
        watch(
            role,
            async () => {
                await getOpenItemsCount()
                setTilesForRoles()
            },
            {
                immediate: true,
            }
        )

        return {
            t,
            language,
            openItemCount,
            tiles,
            totalItemCount,
            utils,
            loading,
        }
    },
})















































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import InviteUser from '@/components/client-configuration/InviteUser.vue'
import ToggleSwitch from '@/shared/components/ToggleSwitch.vue'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { useToast } from 'vue-toastification/composition'
import query from '@/shared/queries/clientConfigQuery'
import variables from '@/shared/variables'
import Confirmation from '@/shared/components/Confirmation.vue'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    props: {
        editable: {
            type: Boolean,
        },
        wizardData: {
            type: Object,
        },
        step: {
            type: Number,
        },
    },
    components: { InviteUser, ToggleSwitch, Confirmation },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { SET_WIZARD_DATA } = useActions(['SET_WIZARD_DATA'])
        const users = ref([])
        const displayInviteUserDialog = ref(false)
        const usesMultiFactorAuth = ref(true)
        const loading = ref(false)
        const toast = useToast()
        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        const displayRemoveConfirmation = ref(false)
        const removeUid = ref(0)

        // store step 4 data
        const next = () => {
            const step4Data = { inviteUsers: users.value, twoFact: usesMultiFactorAuth.value }
            emit('next', {
                step: 4,
                formData: step4Data,
            })
            SET_WIZARD_DATA(step4Data)
        }

        const previous = () => {
            emit('previous', props.step)
        }
        // get list of users
        const getUsers = async () => {
            const usersQuery = `query {users {${query.USERS}}}`
            let result
            try {
                result = await apolloClient.getGraphqlData(usersQuery)
            } catch {
                throw Error('Error while retrieving users')
            }

            if (result) {
                const usersResult = result.data.users
                // store users except logged in user
                users.value = usersResult.filter((val) => val.id !== userId)
            }
        }
        // send invitation to the user
        const inviteUser = async (formData) => {
            const input = {
                email: formData.EMAIL,
                firstName: formData.FIRST_NAME,
                lastName: formData.LAST_NAME,
                roles: formData.ROLES.map((role) => role.id),
                startDate: formData.START_DATE,
                endDate: formData.END_DATE,
            }

            const mutationQuery = `
                    mutation($input: CreateUserInputPayload){
                        inviteUser(input: $input){
                            status
                            error
                            errorCode{
                                value
                            }
                        }
                    }
                `
            // set loader for response wait
            loading.value = true
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
                loading.value = false
            } catch {
                loading.value = false
                throw Error('Error while saving user data')
            }

            if (result.data.inviteUser.status) {
                toast.success(t('INVITE_USER_SAVE_SUCCESS', language.value))
                displayInviteUserDialog.value = false
                SET_WIZARD_DATA({ inviteUsers: users.value })
                // refresh user list
                getUsers()
            } else {
                if (result.data.inviteUser.errorCode) {
                    const error = result.data.inviteUser.errorCode.value
                    toast.error(t(utils.getErrorMessage(error), language.value))
                } else {
                    toast.error(t('INVITE_USER_SAVE_ERROR', language.value))
                }
            }
        }

        // open confirmation box
        const displayRemoveUserDialog = (id) => {
            removeUid.value = id
            displayRemoveConfirmation.value = true
        }

        // remove selected user
        const removeUser = async () => {
            const mutationQuery = `
                 mutation{
                    removeUser(id: ${removeUid.value}){
                            status
                            error
                            errorCode{
                                value
                            }
                    }
                }
                `
            // set loader for response wait
            loading.value = true
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, null)
                loading.value = false
            } catch (err) {
                loading.value = false
                toast.error(t('REMOVE_USER_ERROR_MESSAGE', language.value))
                throw Error('Error while removing user')
            }
            if (result.data.removeUser.status) {
                toast.success(t('REMOVE_USER_SUCCESS_MESSAGE', language.value))
                displayRemoveConfirmation.value = false
                // refresh user list
                getUsers()
            } else {
                toast.error(t('REMOVE_USER_ERROR_MESSAGE', language.value))
            }
        }

        // multi factor auth toggle
        const multiFactorAuthToggle = (toggleValue) => {
            usesMultiFactorAuth.value = toggleValue
            SET_WIZARD_DATA({ twoFact: toggleValue })
        }

        onMounted(() => {
            getUsers()
        })
        // set wizard data on refresh
        watch(
            () => props.wizardData,
            () => {
                if (props.wizardData) {
                    users.value = props.wizardData.inviteUsers
                    usesMultiFactorAuth.value = props.wizardData.twoFact
                }
            },
            { immediate: true }
        )

        const finishWizard = () => {
            emit('finish-wizard')
        }

        return {
            t,
            language,
            users,
            inviteUser,
            displayInviteUserDialog,
            multiFactorAuthToggle,
            usesMultiFactorAuth,
            displayRemoveUserDialog,
            next,
            displayRemoveConfirmation,
            removeUser,
            finishWizard,
            previous,
        }
    },
})

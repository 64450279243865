



























































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import router from '@/router'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'
import { useToast } from 'vue-toastification/composition'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    props: {
        formType: {
            type: String,
        },
    },
    methods: {
        // #661 is raised to reuse recaptcha function
        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "reset password".
            const token = await this.$recaptcha('submit')
            return token

            // Do stuff with the received token.
        },
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const resetToken = ref(router.app.$route.params.resetToken)
        const loading = ref(false)
        const passwordMatched = ref(true)
        const PASSWORD_RESET_FORM = ref({
            PASSWORD1: '',
            PASSWORD2: '',
        })
        const serverErrorMessage = ref('')
        const errors = ref({
            PASSWORD1: { missingValue: false, invalidValue: false },
            PASSWORD2: { missingValue: false },
            serverError: false,
        })
        const toast = useToast()
        /* password that has at least one lowercase letter (?=.*[a-z]), one uppercase letter (?=.*[A-Z]), 
        one digit (?=.*[0-9]), one special character (?=.*[^A-Za-z0-9]), and is at least eight characters long(?=.{8,}). */
        const strongPassword = new RegExp(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
        )
        // If the password is at least six characters long and meets all the other requirements, or has no digit but meets the rest of the requirements
        const mediumPassword = new RegExp(
            '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))'
        )

        const validatePasswordMatch = () => {
            if (PASSWORD_RESET_FORM.value.PASSWORD1 == PASSWORD_RESET_FORM.value.PASSWORD2) {
                passwordMatched.value = true
            } else {
                passwordMatched.value = false
            }
        }

        const validateForm = () => {
            // reset errors
            errors.value = {
                PASSWORD1: { missingValue: false, invalidValue: false },
                PASSWORD2: { missingValue: false },
                serverError: false,
            }
            // missingValue check
            errors.value.PASSWORD1.missingValue = utils.validateFields(
                PASSWORD_RESET_FORM.value.PASSWORD1,
                'string'
            )
            // check password validation if password has enter
            if (PASSWORD_RESET_FORM.value.PASSWORD1)
                errors.value.PASSWORD1.invalidValue = !strongPassword.test(
                    PASSWORD_RESET_FORM.value.PASSWORD1
                )

            // check confirm password(PASSWORD2) has entered only if valid password(PASSWORD1) has entered
            if (PASSWORD_RESET_FORM.value.PASSWORD1 && !errors.value.PASSWORD1.invalidValue)
                errors.value.PASSWORD2.missingValue = utils.validateFields(
                    PASSWORD_RESET_FORM.value.PASSWORD2,
                    'string'
                )
        }

        // input event of password field
        const typePassword = () => {
            errors.value.PASSWORD1.invalidValue = false
            // traversing the DOM and getting the span using their IDs
            const strengthBadge: {
                textContent: TranslateResult;
                style: { backgroundColor: string; display: string };
            } = document.getElementById('strengthDisp')
            // change the badge's color and text based on the password strength
            if (strongPassword.test(PASSWORD_RESET_FORM.value.PASSWORD1)) {
                strengthBadge.style.backgroundColor = '#00ac56'
                strengthBadge.textContent = t('PASSWORD_STRENGTH_STRONG', language.value)
            } else if (mediumPassword.test(PASSWORD_RESET_FORM.value.PASSWORD1)) {
                strengthBadge.style.backgroundColor = '#0080ff'
                strengthBadge.textContent = t('PASSWORD_STRENGTH_MEDIUM', language.value)
            } else {
                strengthBadge.style.backgroundColor = '#ef5012'
                strengthBadge.textContent = t('PASSWORD_STRENGTH_WEAK', language.value)
            }
            //The badge is hidden by default, so we show it
            strengthBadge.style.display = 'block'
            //Incase a user clears the text, the badge is hidden again
            if (PASSWORD_RESET_FORM.value.PASSWORD1.length !== 0) {
                strengthBadge.style.display != 'block'
            } else {
                strengthBadge.style.display = 'none'
            }
        }
        const goBackToLogin = () => {
            router.push({ name: 'Login' })
        }

        // check whether token is expired
        const checkTokenExpire = async () => {
            const query = `
                query isResetTokenValid($token: String!){
                    isResetTokenValid(token:$token){
                        valid
                    }
                }
            `
            // variable params for query
            const variables = {
                token: resetToken.value,
            }
            let result
            try {
                result = await apolloClient.getGraphqlData(query, variables)
            } catch (err) {
                return false
            }
            if (result) {
                return result.data.isResetTokenValid.valid
            }
        }

        const resetPassword = async (token: string) => {
            loading.value = true
            // User login takes place
            const input = {
                resetToken: resetToken.value,
                password: PASSWORD_RESET_FORM.value.PASSWORD2,
                reCaptchaToken: token,
            }
            const mutationQuery = `
                mutation($input: PasswordResetInputPayload!) {
                    resetPassword(input: $input) {
                        status
                        error
                    }
                }
                `
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
                loading.value = false
            } catch (err) {
                loading.value = false
                errors.value.serverError = true
                throw Error('Error while resetting the password')
            }
            if (result.data.resetPassword.status) {
                // password reset is successful
                errors.value.serverError = false
                toast.success(t('PASSWORD_RESET_CONFIRMATION_MESSAGE', language.value))
                goBackToLogin()
            } else {
                errors.value.serverError = true
                // TODO: refactor below when updating UI/UX to show certain errors
                if (result.data.resetPassword.error.includes('expired')) {
                    serverErrorMessage.value = result.data.resetPassword.error
                }
            }
        }
        async function submitPasswordReset() {
            // validate form
            validateForm()
            // check for validation errors
            if (utils.hasErrors(errors.value)) {
                return
            }
            const token = await this.recaptcha()
            resetPassword(token)
        }

        onMounted(async () => {
            // if the token has expired, navigate to `TokenExpire` component to display message
            if (!(await checkTokenExpire())) {
                router.push({ name: 'TokenExpire' })
            }
        })

        return {
            language,
            t,
            submitPasswordReset,
            PASSWORD_RESET_FORM,
            validatePasswordMatch,
            passwordMatched,
            resetToken,
            loading,
            errors,
            serverErrorMessage,
            goBackToLogin,
            typePassword,
        }
    },
})

const OPEN_ITEMS_COUNT = `
    risk{
        user
        total
    }
    riskAssessment{
        user
        total   
    }
    control{
        user
        total           
    }
    controlAssessment{
        user
        total         
    }
    actionPlan{
        user
        total        
    }
    `
const ACTIVE_ITEMS = `
    risk{
        ownerAssigned
        assessmentsPerformed
        assessmentsToBePerformed
        assessmentsInProgress
        assessmentsToBeReviewed
        assessmentsRejected
        total
    }
    control{
        ownerAssigned
        assessmentsPerformed
        assessmentsToBePerformed
        assessmentsInProgress
        assessmentsToBeReviewed
        assessmentsRejected
        total
    }
    actionPlan{
        toBeCreated
        inProgress
        submitted
        approved
        total
        rejected
        overdue
        ready
    }
    `

const CLIENT_CONFIGURATION_DETAILS = `
    riskToleranceGreen
    riskToleranceOrange
    riskToleranceRed
    `
const CLIENT_CONFIGURATION_NORMS = `
    selectedNorms{
        id
        name
    }
    `

const HEATMAP = `
    chance
    netRiskValues{
        netImpact
        netChance
        assessmentCount
    }
    `
const MATURITY_OVERVIEW = `
    totalControls
    totalAssessments
    items{
        maturityScore
        assessmentCount
    }
`

const CONTROL_MATURITY_LEVEL = `
    category
    maturityAverage
    ambitionAverage
    `
const WIZARD_STEP3_SECTORS = `
    id
    name
    key
    subSectors{
        id
        name
        key
        suggestedNorms{
            id
            name
            referenceKey
            info
        }
        otherNorms{
            id
            name
            referenceKey
            info
        }
    }
    suggestedNorms{
        id
        name
        referenceKey
        info
    }
    otherNorms{
        id
        name
        referenceKey
        info
    }
    `
export default {
    OPEN_ITEMS_COUNT,
    ACTIVE_ITEMS,
    CLIENT_CONFIGURATION_DETAILS,
    HEATMAP,
    MATURITY_OVERVIEW,
    CLIENT_CONFIGURATION_NORMS,
    CONTROL_MATURITY_LEVEL,
    WIZARD_STEP3_SECTORS
}

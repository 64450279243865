











import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import dashboardQuery from '@/shared/queries/dashboardQueries'
import { Chart, registerables } from 'chart.js'
import router from '@/router'

export default defineComponent({
    props: {
        normId: Number,
        normName: String,
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const labelCategories = ref([])
        const maturityAverageValues = ref([])
        const ambitionAverageValues = ref([])
        const loading = ref(false)
        const chartData = ref({
            labels: [],
            datasets: [],
        })
        const chartOptions = ref({})
        // register chart types
        Chart.register(...registerables)
        const originalResponse = ref([])

        const generateChart = () => {
            const ctx = document.getElementById('radarChart' + props.normId) as HTMLCanvasElement
            new Chart(ctx, {
                type: 'radar',
                data: chartData.value,
                options: chartOptions.value,
            })
        }

        // indicate mouse hover on labels
        const mouseHoverOnLabels = (evt: {
            chart?: { scales };
            native?: { target };
            x?: number;
            y?: number;
        }) => {
            const { x, y } = evt
            let index = -1
            /* listen to the hover event and then loop through all the pointLabels check if the hovered is in that box 
                    and get the corresponding label from the array containing all the labels.*/
            evt.chart.scales.r._pointLabelItems.forEach((item, i) => {
                const { bottom, top, left, right } = item
                // get the selected label index
                if (x >= left && x <= right && y >= top && y <= bottom) {
                    index = i
                    return
                }
            })
            // find whether label is available in hover options(to identify that user is hovering on labels).
            evt.native.target.style.cursor = index > -1 ? 'pointer' : ''
        }

        // radar chart specific configurations
        const setChartOptions = () => {
            chartOptions.value = {
                onClick: (evt) => {
                    const { x, y } = evt
                    let index = -1
                    /* listen to the click event and then loop through all the pointLabels check if the click is in that box 
                    and get the corresponding label from the array containing all the labels.*/
                    evt.chart.scales.r._pointLabelItems.forEach((item, i) => {
                        const { bottom, top, left, right } = item
                        // get the selected label index
                        if (x >= left && x <= right && y >= top && y <= bottom) {
                            index = i
                            return
                        }
                    })

                    if (index === -1) {
                        return
                    }
                    // get the selected label from index
                    const clickedLabel = evt.chart.scales.r._pointLabels[index]

                    /* Since categories are limited to 25chars ending with dots, get full category text to send as params for filter*/
                    let selectedCategory = ''
                    originalResponse.value.map((item) => {
                        // if the selected category label ends with 3 dots, slice 3 dots to find the string from original response category
                        if (clickedLabel.includes('...')) {
                            if (
                                item.category &&
                                item.category.includes(clickedLabel.slice(0, -3))
                            ) {
                                selectedCategory = item.category
                            }
                        } else {
                            // if there are no 3 dots at the end, use clicked label directly
                            selectedCategory = clickedLabel
                        }
                    })
                    // navigate to control with selected category and norm to filter
                    router.push({
                        name: 'Controls',
                        query: { category: selectedCategory, norm: String(props.normId) },
                    })
                },
                onHover: (evt: {
                    chart?: { scales };
                    native?: { target };
                    x?: number;
                    y?: number;
                }) => mouseHoverOnLabels(evt),
                scale: {
                    ticks: {
                        stepSize: 1,
                    },
                },
                plugins: {
                    legend: {
                        labels: {
                            color: '#172433',
                            fillStyle: '#778da0',
                        },
                    },
                    tooltip: {
                        // Disable the on-canvas tooltip
                        enabled: false,
                        external: function (context) {
                            // Tooltip Element
                            let tooltipEl = document.getElementById('chartjs-tooltip')

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div')
                                tooltipEl.id = 'chartjs-tooltip'
                                tooltipEl.innerHTML = '<table></table>'
                                document.body.appendChild(tooltipEl)
                            }

                            // Hide if no tooltip
                            const tooltipModel = context.tooltip
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = '0'
                                return
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform')
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign)
                            } else {
                                tooltipEl.classList.add('no-transform')
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                let formattedToolTipMaturity = ''
                                let formattedToolTipAmbition = ''
                                /* By default tooltipModel.body gives both maturity and ambition values only when they overlay on each other. 
                            Otherwise it will be either maturity or ambition. Hence manually setting both of values even when they don't overlap on each other */
                                // index of maturity/ambition from graph(this index matches with listing index)
                                const indexOfDataPoint = tooltipModel.dataPoints[0].dataIndex

                                const containsZeros = []
                                /* Remove tooltip from 0,0 position. 
                                (when records are all zero, it will display list of 0's in the 0,0 position.) */
                                if (tooltipModel.body.length > 0) {
                                    tooltipModel.body.map((tooltip) => {
                                        const maturityText = t(
                                            'DASHBOARD_RADARCHART_MATURITY_LABEL',
                                            language.value
                                        )
                                        const ambitionText = t(
                                            'DASHBOARD_RADARCHART_MATURITY_AMBITION_LABEL',
                                            language.value
                                        )
                                        /* if maturity or ambition values are not available (zero), 'zero' string will be pushed to containsZero. 
                                        else none-zero */
                                        if (
                                            tooltip.lines[0].includes(`${maturityText}: 0`) ||
                                            tooltip.lines[0].includes(`${ambitionText}: 0`)
                                        )
                                            containsZeros.push('zero')
                                        else containsZeros.push('none-zero')
                                    })
                                }
                                // check whether all records are 'zero'(0,0).
                                const isAllRecordsZero =
                                    containsZeros.includes('zero') &&
                                    tooltipModel.body.length === containsZeros.length
                                // if all records are zero, maturity and ambition will be null
                                if (isAllRecordsZero) {
                                    formattedToolTipMaturity = null
                                    formattedToolTipAmbition = null
                                } else {
                                    if (
                                        tooltipModel.body[0].lines[0].includes(
                                            t('DASHBOARD_RADARCHART_MATURITY_LABEL', language.value)
                                        )
                                    ) {
                                        // get ambitionAverageValues by maping maturityAverage index(these two have similar index in array)
                                        const mappedAmbitionValue =
                                            ambitionAverageValues.value[indexOfDataPoint]
                                        // ambition value with text
                                        formattedToolTipAmbition =
                                            t(
                                                'DASHBOARD_RADARCHART_MATURITY_AMBITION_LABEL',
                                                language.value
                                            ) +
                                            ': ' +
                                            mappedAmbitionValue
                                        // maturity value with text
                                        formattedToolTipMaturity = tooltipModel.body[0].lines[0]
                                    } else {
                                        // get mappedMaturityValue by maping ambitionAverage index(these two have similar index in array)
                                        const mappedMaturityValue =
                                            maturityAverageValues.value[indexOfDataPoint]
                                        // maturity value with text
                                        formattedToolTipMaturity =
                                            t('DASHBOARD_RADARCHART_MATURITY_LABEL') +
                                            ': ' +
                                            mappedMaturityValue
                                        // ambition value with text
                                        formattedToolTipAmbition = tooltipModel.body[0].lines[0]
                                    }
                                }

                                let innerHtml = '<tbody>'
                                // display tooltip only if there are values for maturity and ambition
                                if (formattedToolTipMaturity && formattedToolTipAmbition) {
                                    // display ambition record
                                    innerHtml +=
                                        '<tr><td>' + formattedToolTipAmbition + '</td></tr>'
                                    // display maturity record
                                    innerHtml +=
                                        '<tr><td>' + formattedToolTipMaturity + '</td></tr>'
                                }

                                innerHtml += '</tbody>'

                                const tableRoot = tooltipEl.querySelector('table')
                                tableRoot.innerHTML = innerHtml
                            }

                            // `this` will be the overall tooltip
                            const position = this._chart.canvas.getBoundingClientRect()

                            // Display, position, and set styles for font
                            tooltipEl.style.opacity = '1'
                            tooltipEl.style.position = 'absolute'
                            tooltipEl.style.left =
                                position.left + window.pageXOffset + tooltipModel.caretX + 'px'
                            tooltipEl.style.top =
                                position.top + window.pageYOffset + tooltipModel.caretY + 'px'
                            ;(tooltipEl.style.fontFamily = 'PT Sans'), 'sans-serif'
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
                            tooltipEl.style.padding =
                                tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
                            tooltipEl.style.pointerEvents = 'none'
                        },
                    },
                },
                scales: {
                    r: {
                        pointLabels: {
                            color: '#0080ff',
                            font: '1rem',
                        },
                        grid: {
                            color: '#dfe5ea',
                        },
                        angleLines: {
                            color: '#dfe5ea',
                        },
                        suggestedMin: 0,
                        suggestedMax: 5,
                    },
                },
            }
            // gerenate chart from canvas
            generateChart()
        }
        // set radar chart data
        const setChartData = () => {
            chartData.value = {
                labels: labelCategories.value,
                datasets: [
                    {
                        label: t('DASHBOARD_RADARCHART_MATURITY_LABEL', language.value),
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        borderColor: 'rgba(237, 121, 42, 1)',
                        pointBackgroundColor: 'rgba(237, 121, 42, 1)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(237, 121, 42, 1)',
                        data: maturityAverageValues.value,
                        borderWidth: 2,
                    },
                    {
                        label: t('DASHBOARD_RADARCHART_MATURITY_AMBITION_LABEL', language.value),
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        borderColor: 'rgba(119, 141, 160, 1)',
                        pointBackgroundColor: 'rgba(119, 141, 160, 1)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(119, 141, 160, 1)',
                        data: ambitionAverageValues.value,
                        borderWidth: 2,
                    },
                ],
            }
        }

        // format response to map with chart data
        const formatChartData = (controlMaturityLevelResult) => {
            labelCategories.value = []
            maturityAverageValues.value = []
            ambitionAverageValues.value = []

            controlMaturityLevelResult.map((controlMaturity) => {
                const categoryCharacterLength =
                    controlMaturity.category && controlMaturity.category.length
                const limitedCategory =
                    categoryCharacterLength > 22
                        ? controlMaturity.category.substring(0, 22) + '...'
                        : controlMaturity.category
                labelCategories.value.push(limitedCategory)
                maturityAverageValues.value.push(controlMaturity.maturityAverage)
                ambitionAverageValues.value.push(controlMaturity.ambitionAverage)
            })
        }

        // get maturity and ambition data for radar chart
        const getChartData = async () => {
            originalResponse.value = []
            loading.value = true
            const controlMaturityLevel = `
                query{
                    controlMaturityLevel(normId: ${props.normId}){
                        ${dashboardQuery.CONTROL_MATURITY_LEVEL}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(controlMaturityLevel)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving control maturity level')
            }
            if (result) {
                const controlMaturityLevelResult = result.data.controlMaturityLevel
                if (controlMaturityLevelResult) {
                    originalResponse.value = controlMaturityLevelResult
                    formatChartData(controlMaturityLevelResult)
                }
            }
        }

        // watch for language change from store and update chart labels
        watch(language, () => {
            setChartData()
        })

        onMounted(async () => {
            await getChartData()
            setChartData()
            setChartOptions()
        })

        return {
            chartData,
            chartOptions,
            loading,
        }
    },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-heapmap-wrapper"},[_c('div',{staticClass:"dashboard-heatmap-title p-mb-3"},[_vm._v(" "+_vm._s(_vm.t('DASHBOARD_HEATMAP_NET_RISK', _vm.language))+" ")]),_c('div',{staticClass:"dashboard-heatmap-header p-mb-3"},[_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"content-wrapper p-col-12"},[_c('span',{staticClass:"p-mr-2"},[_vm._v(_vm._s(_vm.t('RISK_SET', _vm.language))+":")]),_c('span',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('Dropdown',{attrs:{"options":_vm.riskSetOptions,"optionValue":"value","optionLabel":"label","placeholder":_vm.t('SHOW_ALL', _vm.language),"showClear":true},model:{value:(_vm.SELECTED_RISK_SET),callback:function ($$v) {_vm.SELECTED_RISK_SET=$$v},expression:"SELECTED_RISK_SET"}})],1)])])])])]),_c('div',{staticClass:"dashboard-heatmap"},[_c('loading',{attrs:{"active":_vm.loading,"can-cancel":true,"is-full-page":false,"color":"#05BE36","transition":"none"}}),_c('div',{staticClass:"dashboard-axis-table-wrapper"},[_c('div',{staticClass:"y-axis-label-name"},[_vm._v(" "+_vm._s(_vm.t('DASHBOARD_HEATMAP_NET_CHANCE_LABEL', _vm.language))+" ")]),_c('table',{staticClass:"dashboard-heatmap-table"},[_c('tbody',[_vm._l((_vm.heatmap.chance.map(function (chance, i) { return [chance, i]; }).reverse()),function(ref){
var chance = ref[0];
var i = ref[1];
return _c('tr',{key:i},[_c('td',{staticClass:"y-axis"},[_vm._v(" "+_vm._s(chance)+" ")]),_vm._l((_vm.heatmap.frequencyChanceImpact[i]),function(assessmentCount,j){return _c('td',{key:j,class:[
                                'cell',
                                _vm.utils.netRiskStyleClass(
                                    _vm.calculateRiskScore(_vm.heatmap.impact[j], _vm.heatmap.chance[i]),
                                    _vm.heatmap.riskTolerance
                                ),
                                (assessmentCount === 0) ? 'empty-cell' : 'assessment-count' ],on:{"click":function($event){assessmentCount !== 0
                                    ? _vm.goToAssessments(_vm.heatmap.impact[j], _vm.heatmap.chance[i], _vm.SELECTED_RISK_SET)
                                    : ''}}},[_vm._v(" "+_vm._s(assessmentCount !== 0 ? assessmentCount : '')+" ")])})],2)}),_c('tr',[_c('td',{staticClass:"x-axis"}),_vm._l((_vm.heatmap.impact),function(impact,j){return _c('td',{key:j,staticClass:"'x-axis'"},[_vm._v(" "+_vm._s(impact)+" ")])})],2)],2)])]),_c('div',{staticClass:"x-axis-label-name p-pb-2"},[_vm._v(" "+_vm._s(_vm.t('DASHBOARD_HEATMAP_NET_IMPACT_LABEL', _vm.language))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }











































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useActions, useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import variables from '@/shared/variables'
import { useToast } from 'vue-toastification/composition'
import utils from '@/shared/mixins/utils'
import generalData from '@/assets/data/general-data.json'
import { YesNo } from '@/shared/enum/general-enum'
import generalQueries from '@/shared/queries/generalQueries'

export default defineComponent({
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const toast = useToast()
        const loggedInUser: { user: { id; twoFactorRequired; organisations; twoFactorActivated } } =
            utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
                decrypt: true,
            })
        const twoFactorRequiredDropdown = ref([])
        const twoFactorRequiredSelected = ref('')
        const twoFactorDisabled = ref(false)
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { menuOpen } = useState(['menuOpen'])
        const visibleLeft = ref(false)
        const displayTwoFactAuthPopup = ref(false)
        const TWOFACTOR_FORM = ref({
            TOKEN: '',
        })
        const errors = ref({
            TOKEN: { missingValue: false, invalidValue: false },
        })
        const serverError = ref('')
        const refToken = ref(null)
        const twofactorActivated = ref(loggedInUser && loggedInUser.user.twoFactorActivated)

        const validateForm = () => {
            // reset errors
            errors.value = {
                TOKEN: { missingValue: false, invalidValue: false },
            }
            // missingValue check
            errors.value.TOKEN.missingValue = utils.validateFields(
                TWOFACTOR_FORM.value.TOKEN,
                'string'
            )
        }

        // populate two-factor dropdown values
        const setTwoFactorRequiredDropdown = () => {
            // create a dropdown to contain 'YES', 'NO' values
            twoFactorRequiredDropdown.value = [
                {
                    key: YesNo.YES,
                    value: t(YesNo.YES, language.value),
                },
                {
                    key: YesNo.NO,
                    value: t(YesNo.NO, language.value),
                },
            ]

            // check if any organisation has 2FA required.
            const organisation =
                loggedInUser &&
                loggedInUser.user.organisations.find((org) => org.twoFactorRequired === true)
            twoFactorDisabled.value = !(organisation == null)
            // if organisation has set 2fa to be missingValue, user cannot change it to be non-missingValue by own choice
            if (twoFactorDisabled.value) twoFactorRequiredSelected.value = YesNo.YES
            // if not, user can set it to missingValue/non-missingValue by own choice
            else
                twoFactorRequiredSelected.value =
                    loggedInUser && loggedInUser.user.twoFactorRequired ? YesNo.YES : YesNo.NO
        }

        // updateTwoFactor on dropdown change
        const updateTwoFactor = async () => {
            if (displayTwoFactAuthPopup.value) {
                // validate twofact auth
                validateForm()
                // check for validation errors
                if (utils.hasErrors(errors.value)) {
                    return
                }
            }

            const mutationQuery = `
            mutation ($input: ProfileInputPayload!) {
                updateProfile(input: $input) {
                    user{
                        ${generalQueries.USERS}
                    }
                    status
                    error
                    errorCode{
                        value
                        name
                    }
                }
            }`
            const input = {
                twoFactorRequired: twoFactorRequiredSelected.value === YesNo.YES ? true : false,
                twoFactorToken: TWOFACTOR_FORM.value.TOKEN,
            }
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('CLIENT_CONFIGURATION_USER_INFORMATION__SAVE_ERROR', language.value))
                throw Error('Error while saving user information')
            }
                    if (result) {
                    if (result.data.updateProfile.status) {
                        displayTwoFactAuthPopup.value = false
                        // update localStorage
                        utils.ls.set(variables.LOCAL_STORAGE_ITEMS.USER, {
                            user: result.data.updateProfile.user,
                        })
                        twofactorActivated.value =
                            result.data.updateProfile.user &&
                            result.data.updateProfile.user.twoFactorActivated

                        toast.success(
                            t('CLIENT_CONFIGURATION_USER_INFORMATION_SAVE_SUCCESS', language.value)
                        )
                    } else {
                        if (utils.getErrorMessage(result.data.updateProfile.errorCode.value)) {
                            errors.value.TOKEN.invalidValue = true
                            serverError.value = utils.getErrorMessage(
                                result.data.updateProfile.errorCode.value
                            )
                            return
                        }
                        toast.error(
                            t('CLIENT_CONFIGURATION_USER_INFORMATION__SAVE_ERROR', language.value)
                        )
                    }
                }
        }

        const twoFaRequiredDropdownHandler = () => {
            /* display twofact code popup when updating twofactor auth to `not required` and user has already registered the QR code
            (twoFactorActivated) */
            twoFactorRequiredSelected.value === YesNo.YES ||
            (!twofactorActivated.value && twoFactorRequiredSelected.value !== YesNo.YES)
                ? updateTwoFactor()
                : (displayTwoFactAuthPopup.value = true)
        }

        const cancelConfirmation = () => {
            displayTwoFactAuthPopup.value = false
            // reset form data, errors
            TWOFACTOR_FORM.value.TOKEN = ''
            errors.value = {
                TOKEN: { missingValue: false, invalidValue: false },
            }
            serverError.value = ''
        }

        onMounted(() => {
            setTwoFactorRequiredDropdown()
        })

        return {
            t,
            language,
            twoFactorRequiredDropdown,
            twoFactorRequiredSelected,
            twoFactorDisabled,
            updateTwoFactor,
            generalData,
            utils,
            menuOpen,
            visibleLeft,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            displayTwoFactAuthPopup,
            cancelConfirmation,
            TWOFACTOR_FORM,
            errors,
            twoFaRequiredDropdownHandler,
            serverError,
        }
    },
})

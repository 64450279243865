import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Maturity from '@/views/Maturity.vue'
import Profile from '@/views/Profile.vue'
import Login from '@/views/login/Login.vue'
import LoginWrapper from '@/views/login/LoginWrapper.vue'
import StartSSO from '@/views/login/sso/StartSSO.vue'
import CompleteSSO from '@/views/login/sso/CompleteSSO.vue'
import ForgotPassword from '@/views/login/ForgotPassword.vue'
import TwoFactorAuthentication from '@/views/login/TwoFactorAuthentication.vue'
import Reset from '@/views/login/Reset.vue'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import Dashboard from '@/views/dashboard/Dashboard.vue'
import SignUp from '@/views/login/SignUp.vue'
import TermsAndConditions from '@/views/login/TermsAndConditions.vue'
import PrivacyPolicy from '@/views/login/PrivacyPolicy.vue'
import WizardWrapper from '@/views/dashboard/wizard/WizardWrapper.vue'
import WizardPage1 from '@/views/dashboard/wizard/WizardPage1.vue'
import WizardPage2 from '@/views/dashboard/wizard/WizardPage2.vue'
import RiskAssessmentsExplanation from '@/views/risk-assessments/RiskAssessmentsExplanation.vue'
import OrganisationSelect from '@/views/login/OrganisationSelect.vue'
import ControlAssessmentsExplanation from '@/views/control-assessments/ControlAssessmentsExplanation.vue'
import TokenExpire from '@/shared/components/TokenExpire.vue'
import ls from 'localstorage-slim'
import { configureOrganisationTranslations, loadedOrganisationLanguage } from '@/shared/services/I18nServices'
import { ensureArray } from './route-helpers'
Vue.use(VueRouter)
const routes: Array<RouteConfig> = [
    {
        path: '/login',
        component: LoginWrapper,
        children: [
            {
                path: '',
                name: 'Login',
                component: Login,
                props: true,
            },
            {
                path: '/forgotPassword',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: '/two-factor-authentication',
                name: 'TwoFactorAuthentication',
                component: TwoFactorAuthentication,
                props: true,
                // block navigation for TwoFactorAuthentication without login
                beforeEnter: (to, from, next) => {
                    if (ls.get(variables.LOCAL_STORAGE_ITEMS.AUTH_TOKEN, { decrypt: true })) {
                        next()
                    } else {
                        next({ name: 'Login' })
                    }
                },
            },
            {
                path: '/reset/:resetToken',
                name: 'ResetToken',
                component: Reset,
                props: { formType: 'RESET' },
            },
            {
                path: '/activation/:resetToken',
                name: 'ActivationResetToken',
                component: Reset,
                props: { formType: 'ACTIVATION' },
            },
            // navigate when password reset link is expired
            { path: '/token-expire', name: 'TokenExpire', component: TokenExpire },
            {

                path: '/login/sso/:flowName',
                name: 'StartSSO',
                component: StartSSO,
            },
            {
        
                path: '/oauth2/callback',
                name: 'CompleteSSO',
                component: CompleteSSO,
            },
        ],
        
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp,
    },
    {
        path: '/select-organisation',
        name: 'OrganisationSelect',
        component: OrganisationSelect,
    },
    {
        path: '/terms-and-condition',
        name: 'TermsAndConditions',
        component: TermsAndConditions,
    },
    {
        path: '/privacypolicy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/wizard',
        component: WizardWrapper,
        // navigate to wizard only if user has selected an organisation
        beforeEnter: (to, from, next) => {
            if (
                ls.get(variables.LOCAL_STORAGE_ITEMS.WIZARD_COMPLETION_STATUS, {
                    decrypt: true,
                })
            ) {
                next()
            } else {
                next({ name: 'WizardPage1' })
            }
        },
        children: [
            {
                path: 'page1',
                name: 'WizardPage1',
                component: WizardPage1,
            },
            {
                path: 'page2',
                name: 'WizardPage2',
                component: WizardPage2,
            },
        ],
    },
    {
        path: '/',
        component: Home,
        // navigate to Home if wizard is in completed status
        beforeEnter: (to, from, next) => {
            if (
                ls.get(variables.LOCAL_STORAGE_ITEMS.WIZARD_COMPLETION_STATUS, {
                    decrypt: true,
                }) === 'true'
            ) {
                next()
            } else {
                next({ name: 'WizardPage1' })
            }
        },
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: Dashboard,
            },

            {
                path: '/threats',
                component: () => import('@/views/threats/ThreatsWrapper.vue'),
                children: [
                    {
                        path: '',
                        name: 'Threats',
                        component: () => import('@/views/threats/Threats.vue'),
                        props: true,
                    },
                    {
                        path: 'archived-list',
                        name: 'ArchivedThreats',
                        component: () => import('@/views/threats/Threats.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: '/vulnerabilities',
                name: 'Vulnerabilities',
                component: () => import('@/views/vulnerabilities/Vulnerabilities.vue'),
                props: true,
            },
            {
                path: '/risks',
                name: 'Risks',
                component: () => import('@/views/risks/Risks.vue'),
                props: (route) => {
                    return {
                        idfilter: route.query.idfilter,
                        loggedInUser: route.query.loggedInUser,
                        alertColor: route.query.alertColor,
                    }
                },
            },
            {
                path: '/riskassessments',
                name: 'RiskAssessments',
                component: () => import('@/views/risk-assessments/RiskAssessments.vue'),
                props: (route) => {
                    return {
                        loggedInUser: route.query.loggedInUser,
                        netChance: route.query.netChance,
                        netImpact: route.query.netImpact,
                        alertColor: route.query.alertColor,
                    }
                },
            },
            {
                path: '/overview/validation/risk-assessments',
                name: 'ValidateRiskAssessments',
                component: () => import('@/views/risk-assessments/RiskAssessments.vue'),
                props: (route) => {
                    return {
                        validationMode: true,
                        loggedInUser: route.query.loggedInUser,
                        netChance: route.query.netChance,
                        netImpact: route.query.netImpact,
                        alertColor: route.query.alertColor,
                    }
                },
            },
            {
                path: '/risk-extra-explanation',
                name: 'RiskAssessmentsExplanation',
                component: RiskAssessmentsExplanation,
            },
            {
                path: '/controls',
                name: 'Controls',
                component: () => import('@/views/controls/Controls.vue'),
                props: (route) => {
                    return {
                        maturity: route.query.maturity,
                        norm: route.query.norm,
                        category: route.query.category,
                        idfilter: route.query.idfilter,
                        loggedInUser: route.query.loggedInUser,
                        // The alertColor query param may occur zero, one, or multiple times.
                        // Use helper to make sure we always pass an array to the component
                        alertColor: ensureArray(route.query.alertColor),
                    }
                },
            },
            {
                path: '/controlassessments',
                name: 'ControlAssessments',
                component: () => import('@/views/control-assessments/ControlAssessments.vue'),
                props: (route) => {
                    return {
                        loggedInUser: route.query.loggedInUser,
                        alertColor: route.query.alertColor,
                    }
                },
            },
            {
            path: '/overview/validation/control-assessments',
            name: 'ValidateControlAssessments',
            component: () => import('@/views/control-assessments/ControlAssessments.vue'),
                props: (route) => {
                    return {
                        validationMode: true,
                        loggedInUser: route.query.loggedInUser,
                        alertColor: route.query.alertColor,
                    }
                },
            },
            {
                path: '/control-extra-explanation',
                name: 'ControlAssessmentsExplanation',
                component: ControlAssessmentsExplanation,
            },
            {
                path: '/assetcontrolassessments',
                name: 'AssetControlAssessments',
                component: () => import('@/views/ac-assessments/AssetControlAssessments.vue'),
                props: (route) => {
                    return {
                        menuHeader: +route.query.menuHeader,
                        loggedInUser: route.query.loggedInUser,
                        status: +route.query.status,
                    }
                },
            },
            {
                path: '/overview/validation/asset-control-assessments',
                name: 'ValidateAssetControlAssessments',
                component: () => import('@/views/ac-assessments/AssetControlAssessments.vue'),
                props: (route) => {
                    return {
                        validationMode: true,
                        loggedInUser: route.query.loggedInUser,
                        status: +route.query.status,
                    }
                },
            },
            {
                path: '/assets',
                name: 'Assets',
                component: () => import('@/views/assets/Assets.vue'),
                props: true,
            },
            {
                path: '/improve/controls',
                name: 'ImproveControls',
                component: () => import('@/views/action-plans/ActionPlans.vue'),
                props: (route) => {
                    return {
                        loggedInUser: route.query.loggedInUser,
                    }
                },
            },
            {
                path: '/improve/msrs',
                name: 'ImproveMSRs',
                component: () => import('@/views/msr-assessments/actionplan/MSRActionPlans.vue'),
                props: (route) => {
                    return {
                        loggedInUser: route.query.loggedInUser,
                        alertColor: route.query.alertColor,
                    }
                },
            },
            {
                path: '/actionplans',
                name: 'ActionPlans',
                component: () => import('@/views/action-plans/ActionPlans.vue'),
                props: (route) => {
                    return {
                        loggedInUser: route.query.loggedInUser,
                        alertColor: route.query.alertColor,
                    }
                },
            },
            {
                path: '/overview/validation/actionplans',
                name: 'ValidateActionPlans',
                component: () => import('@/views/action-plans/ActionPlans.vue'),
                props: (route) => {
                    return {
                        validationMode: true,
                        loggedInUser: route.query.loggedInUser,
                        alertColor: route.query.alertColor,
                    }
                },
            },
            {
                path: '/management-system',
                name: 'MSRequirements',
                component: () => import('@/views/management-system/MSRequirements.vue'),
                props: true,
            },
            {
                path: '/msr-assessments',
                name: 'MSRAssessments',
                component: () => import('@/views/msr-assessments/MSRAssessments.vue'),
                props: (route) => ({
                    loggedInUser: route.query.loggedInUser,
                }),
            },
            {
                path: '/overview/validation/msr-assessments',
                name: 'ValidateMSRAssessments',
                component: () => import('@/views/msr-assessments/MSRAssessments.vue'),
                props: (route) => ({
                    validationMode: true,
                    loggedInUser: route.query.loggedInUser,
                }),
            },
            {
                path: '/msr-actionplans',
                name: 'MSRActionPlans',
                component: () => import('@/views/msr-assessments/actionplan/MSRActionPlans.vue'),
                props: (route) => {
                    return {
                        loggedInUser: route.query.loggedInUser,
                        alertColor: route.query.alertColor,
                    }
                },
            },
            {
                path: '/overview/validation/msr-actionplans',
                name: 'ValidateMSRActionPlans',
                component: () => import('@/views/msr-assessments/actionplan/MSRActionPlans.vue'),
                props: (route) => {
                    return {
                        validationMode: true,
                        loggedInUser: route.query.loggedInUser,
                        alertColor: route.query.alertColor,
                    }
                },
            },
            {
                path: '/task-register',
                name: 'TaskRegisterList',
                component: () => import('@/views/action-plans/TaskRegisterList.vue'),
            },
            {
                path: '/msr-norms',
                name: 'MSRNorms',
                component: () => import('@/views/msr-norms/MSRNorms.vue'),
            },
            {
                path: '/about',
                name: 'About',
                component: () => import('@/views/About.vue'),
            },
            {
                path: '/help',
                name: 'Help',
                component: () => import('@/views/help/Help.vue'),
            },
            {
                path: '/help-content',
                name: 'HelpRoutePage',
                component: () => import('@/views/help/HelpRoutePage.vue'),
                props: (route) => {
                    return {
                        docType: +route.query.docType
                    }
                },
            },
            {
                path: '/profile',
                name: 'Profile',
                component: Profile,
            },

            {
                path: '/audittrail',
                name: 'AuditTrail',
                component: () => import('@/views/reports/audittrail/AuditTrail.vue'),
            },
            {
                path: '/relations',
                name: 'Relations',
                component: () => import('@/views/reports/Relations.vue'),
            },
            {
                path: '/control-references',
                name: 'ControlReferences',
                component: () => import('@/views/reports/ControlReferences.vue'),
            },
            {
                path: '/maturity',
                name: 'Maturity',
                component: Maturity,
            },
            {
                path: '/client-configuration',
                name: 'ClientConfiguration',
                component: () => import('@/views/settings/ClientConfiguration.vue'),
            },
            {
                path: '/norms',
                name: 'Norms',
                component: () => import('@/views/settings/Norms.vue'),
            },
            {
                path: '/maturity-level-info',
                name: 'MaturiryInfo',
                component: () => import('@/views/control-assessments/MaturiryInfo.vue'),
            },
        ],
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars 
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
    },
})

// these routes do not required any authentication
const noAuthRequiredRoutes = [
    'Login',
    'ForgotPassword',
    'StartSSO',
    'CompleteSSO',
    'TwoFactorAuthentication',
    'ResetToken',
    'ActivationResetToken',
    'SignUp',
    'TokenExpire',
    'TermsAndConditions',
    'PrivacyPolicy',
]

// Routes that are accessible in two factor pending state
const twoFactorPendingRoutes = [
    'TwoFactorAuthentication',
    ...noAuthRequiredRoutes,
]

// these routes require an authenticated user, but not an active role and org
const onlyUserAuthRequiredRoutes = [
    'OrganisationSelect',
    ...noAuthRequiredRoutes,
]


// auth guard
router.beforeEach(async (to, from, next) => {
    /**
     * Redirect user to the right page depending on the stored auth status
     */
    const authStatus = utils.getStoredAuthStatus()
    if (authStatus == 'ANONYMOUS' && !noAuthRequiredRoutes.includes(to.name as string)) {
        next({ name: 'Login' })
    }
    else if (authStatus == 'TWO_FACTOR_PENDING' && !twoFactorPendingRoutes.includes(to.name as string)) {
        // If the user tries to navigate to a protected page while 2fa is pending, redirect them
        // to the login page
        next({ name: 'Login' })
    }
    else if (authStatus == 'ONLY_USER_AUTHENTICATED' && !onlyUserAuthRequiredRoutes.includes(to.name as string)) {
        next({ name: 'OrganisationSelect' })
    }
    else if  (!to.matched.length){
         
            // URL does not match any of the registered routes
            if (authStatus == 'ONLY_USER_AUTHENTICATED') {
                next({ name: 'OrganisationSelect' })
            }
            else if (authStatus == 'AUTHORIZED') {
                next({ name: 'Dashboard' })
            } else {
                next({ name: 'Login' })
            }
    } else {
        // User is accessing a protected route and is authorized, so continue to the page
        if (authStatus == 'AUTHORIZED' && !loadedOrganisationLanguage) {
            /* We are navigating to an organisation-specific page
            * so if it has not been done yet, load organisation language
            * Currently we need to do this before the view is loaded, because not all translations are done 
            * in a way that picks up changes in the i18n translation data after they have been loaded
            */
            await configureOrganisationTranslations();
        }
        next()
        
    }

})
export default router

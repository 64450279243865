






































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import Step1 from '@/components/dashboard/wizard/page1/Step1.vue'
import Step2 from '@/components/dashboard/wizard/page1/Step2.vue'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import Step3 from '@/components/dashboard/wizard/page1/Step3.vue'
import Step4 from '@/components/dashboard/wizard/page1/Step4.vue'
import router from '@/router'
import variables from '@/shared/variables'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    components: {
        Step1,
        Step2,
        Step3,
        Step4,
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const wizardItems = ref([
            { title: 'WIZARD_STEP1_TITLE', step: 1, status: 'start' },
            { title: 'WIZARD_STEP2_TITLE', step: 2, status: 'start' },
            { title: 'WIZARD_STEP3_TITLE', step: 3, status: 'start' },
            { title: 'WIZARD_STEP4_TITLE', step: 4, status: 'start' },
        ])
        const activeStep = ref(1)
        const { wizardData } = useState(['wizardData'])
        const { SET_WIZARD_DATA } = useActions(['SET_WIZARD_DATA'])
        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const loading = ref(false)
        interface FormData {
            companySize?: string;
            jobFunction?: string;
            jobRoleOther?: string;
            subSector?: string;
            sector?: string;
            suggestedNorms?: [];
            selectedMsrNorms?: [];
            otherNorms?: [];
            twoFact?: boolean;
        }
        const completedSteps = ref(wizardData.value.completedSteps)

        // update user information (jobRole/jobRoleOther)
        const updateUser = async (input: {}) => {
            loading.value = true
            const mutationQuery = `mutation ($input: UpdateUserInputPayload!) {
                updateUser(id: ${userId}, input: $input) {
                    status
                    error
                }
            }`
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
                loading.value = false
            } catch (err) {
                loading.value = false
                return 'error'
            }
            if (result) {
                if (result.data.updateUser.status) {
                    return 'success'
                } else {
                    return 'error'
                }
            }
        }

        const updateOrganisationConfigurations = async (input: {}) => {
            loading.value = true
            // update client configuration
            const mutationQuery = `mutation ($input: OrganisationConfigInput!) {
                    updateOrganisationConfig(orgId:${organisationId}, input: $input) {
                            status
                            error
                    }
              }`
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
                loading.value = false
            } catch (err) {
                loading.value = false
                return 'error'
            }
            if (result) {
                const clientConfigResult = result.data.updateOrganisationConfig

                if (clientConfigResult.status) {
                    return 'success'
                } else {
                    return 'error'
                }
            }
        }
        //  save wizard data
        const saveWizardFormData = async (step: number, formData: FormData) => {
            let input
            switch (step) {
                case 1:
                    input = { companySize: formData.companySize }
                    return await updateOrganisationConfigurations(input)
                case 2:
                    input = { jobRole: formData.jobFunction, jobRoleOther: formData.jobRoleOther }
                    return await updateUser(input)
                case 3:
                    input = {
                        companySector: formData.sector,
                        companySubSector: formData.subSector,
                        selectedNorms: formData.suggestedNorms.concat(formData.otherNorms),
                        selectedMsrNorms: formData.selectedMsrNorms
                    }
                    return await updateOrganisationConfigurations(input)
                case 4:
                    input = { twoFactorRequired: formData.twoFact }
                    return await updateOrganisationConfigurations(input)
            }
        }

        // apply activestep class on active step
        const makeActiveStep = (step: number) => {
            const activeEle = document.querySelector('.activestep')
            if (activeEle) {
                activeEle.classList.remove('activestep')
            }
            const ele = document.querySelector('#stepnumber' + step)
            ele ? ele.classList.add('activestep') : ''
        }

        const next = async (params: { step: number; formData: FormData }) => {
            const step = params.step
            const formData = params.formData
            // save wizard data
            const status = await saveWizardFormData(step, formData)
            if (status === 'success') {
                // to keep completed records on refresh, store them. Converting them to strings as `includes` function cannot be used with numbers
                completedSteps.value.push(String(step))
                // remove duplicates
                completedSteps.value = [...new Set(completedSteps.value)]

                // update wizard items with completed status
                wizardItems.value.find((val) => val.step === step).status = 'completed'
                activeStep.value = step + 1
                // navigate to 2nd wizard page(recap) from 4th step
                if (step === 4) {
                    activeStep.value = step
                    router.push({ name: 'WizardPage2' })
                }
                // activate next step

                makeActiveStep(activeStep.value)
                // save wizard data in store upon success api call
                SET_WIZARD_DATA({
                    activeStep: activeStep.value,
                    completedSteps: completedSteps.value,
                })

                // save wizard data in localStorage to get them when user refresh data
                utils.ls.set(variables.LOCAL_STORAGE_ITEMS.WIZARD_DATA, wizardData.value)
            }
        }

        // Go to previous step
        const previous = (step) => {
            // make active previous step
            activeStep.value = step - 1
            makeActiveStep(activeStep.value)
            // update wizard items with current status of the step as start
            wizardItems.value.find((val) => val.step === activeStep.value).status = 'start'
            //Converting them to strings as `includes` function cannot be used with numbers
            const index = completedSteps.value.indexOf(String(activeStep.value))
            // remove the current step from completedSteps array to indicate that it's in progress
            if (index > -1) {
                completedSteps.value.splice(index, 1)
            }
            // save wizard data in store to keep current status of records on refresh
            SET_WIZARD_DATA({ activeStep: activeStep.value, completedSteps: completedSteps.value })
            // save wizard data in localStorage to get them when user refresh data
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.WIZARD_DATA, wizardData.value)
        }
        // go to recap(overview) page
        const gotoOverview = () => {
            router.push({ name: 'WizardPage2' })
        }

        // keep step active on refresh page
        watch(
            () => Object.assign({}, wizardData),
            () => {
                if (wizardData.value) {
                    activeStep.value = wizardData.value.activeStep
                    makeActiveStep(activeStep.value)
                }
            },
            { immediate: true }
        )
        return {
            wizardItems,
            t,
            language,
            next,
            activeStep,
            gotoOverview,
            wizardData,
            loading,
            previous,
        }
    },
})

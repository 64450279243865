var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step3-wrapper"},[_c('div',{staticClass:"sub-text p-mb-3"},[_vm._v(" "+_vm._s(_vm.t('STEP_3_SUB_TEXT', _vm.language))+" ")]),_c('div',[_c('div',{staticClass:"label-section"},[_vm._v(" "+_vm._s(_vm.t('CLIENT_CONFIGURATION_COMPANY_DETAILS_COMPANY_SECTOR', _vm.language))+" ")]),_c('div',{staticClass:"data-section"},[_c('div',{staticClass:"p-fluid"},[_c('div',{class:['p-field', _vm.errors.COMPANY_SECTOR.missingValue ? 'input-error' : '']},[_c('Dropdown',{attrs:{"options":_vm.sectorData,"optionLabel":"name","placeholder":_vm.t('SELECT', _vm.language),"disabled":!_vm.editable},model:{value:(_vm.COMPANY_SECTOR),callback:function ($$v) {_vm.COMPANY_SECTOR=$$v},expression:"COMPANY_SECTOR"}}),(_vm.errors.COMPANY_SECTOR.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()],1)])])]),(_vm.subSectorOptions.length)?_c('div',[_c('div',{staticClass:"label-section"},[_vm._v(" "+_vm._s(_vm.t('CLIENT_CONFIGURATION_COMPANY_DETAILS_COMPANY_SUB_SECTOR', _vm.language))+" ")]),_c('div',{staticClass:"data-section"},[_c('div',{staticClass:"p-fluid"},[_c('div',{class:[
                        'p-field',
                        _vm.errors.COMPANY_SUB_SECTOR.missingValue ? 'input-error' : '' ]},[(_vm.subSectorOptions.length)?_c('Dropdown',{attrs:{"options":_vm.subSectorOptions,"optionLabel":"name","placeholder":_vm.t('SELECT', _vm.language),"disabled":!_vm.editable},model:{value:(_vm.COMPANY_SUB_SECTOR),callback:function ($$v) {_vm.COMPANY_SUB_SECTOR=$$v},expression:"COMPANY_SUB_SECTOR"}}):_vm._e(),(_vm.errors.COMPANY_SUB_SECTOR.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()],1)])])]):_vm._e(),_c('div',{staticClass:"norms-section"},[(
                (_vm.editable && _vm.suggestedNormsOptions.length > 0) ||
                (!_vm.editable && _vm.SELECTED_SUGGESTED_NORMS.length > 0)
            )?_c('div',{staticClass:"suggested-norms-section"},[_c('div',{staticClass:"norm-title p-mb-2"},[_vm._v(" "+_vm._s(_vm.t('STEP3_PROPOSED_NORMS_TITLE', _vm.language))+" ")]),_c('div',{staticClass:"norm-subtext"},[_vm._v(" "+_vm._s(_vm.t('STEP3_PROPOSED_NORMS_SUBTEXT', _vm.language))+" ")]),_c('div',{staticClass:"suggested-norms p-mt-3"},_vm._l((_vm.suggestedNormsOptions),function(snorms,index){return _c('div',{key:index,staticClass:"p-mb-1"},[(
                            (!_vm.editable && _vm.SELECTED_SUGGESTED_NORMS.includes(snorms.id)) ||
                            _vm.editable
                        )?_c('span',[_c('Checkbox',{attrs:{"id":snorms.id,"name":snorms.name,"value":snorms.id,"disabled":!_vm.editable},model:{value:(_vm.SELECTED_SUGGESTED_NORMS),callback:function ($$v) {_vm.SELECTED_SUGGESTED_NORMS=$$v},expression:"SELECTED_SUGGESTED_NORMS"}}),_c('span',{staticClass:"p-ml-2",attrs:{"v-if":snorms.name}},[_vm._v(_vm._s(snorms.name))]),_c('i',{staticClass:"p-ml-2 fa fa-info-circle help",on:{"click":function($event){return _vm.toggleInformation($event, snorms.info)}}})],1):_vm._e()])}),0)]):_vm._e(),(
                (_vm.editable && _vm.otherNormsOptions.length > 0) ||
                (!_vm.editable && _vm.SELECTED_OTHER_NORMS.length > 0)
            )?_c('div',{staticClass:"other-norms-section p-mt-3"},[_c('div',{staticClass:"norm-title"},[_vm._v(" "+_vm._s(_vm.t('STEP3_OTHER_NORMS_TITLE', _vm.language))+" ")]),_c('div',{staticClass:"other-norms p-mt-3"},_vm._l((_vm.otherNormsOptions),function(onorms,index){return _c('div',{key:index,staticClass:"p-mb-1"},[(
                            (!_vm.editable && _vm.SELECTED_OTHER_NORMS.includes(onorms.id)) || _vm.editable
                        )?_c('span',[_c('Checkbox',{attrs:{"id":onorms.id,"name":onorms.name,"value":onorms.id,"disabled":!_vm.editable},model:{value:(_vm.SELECTED_OTHER_NORMS),callback:function ($$v) {_vm.SELECTED_OTHER_NORMS=$$v},expression:"SELECTED_OTHER_NORMS"}}),_c('span',{staticClass:"p-ml-2",attrs:{"v-if":onorms.name}},[_vm._v(_vm._s(onorms.name))]),_c('i',{staticClass:"p-ml-2 fa fa-info-circle help",on:{"click":function($event){return _vm.toggleInformation($event, onorms.info)}}})],1):_vm._e()])}),0)]):_vm._e(),(_vm.errors.NORMS.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('NORM_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()]),_c('div',{staticClass:"msr-norms-section p-mt-3"},[_c('div',{staticClass:"norm-title"},[_vm._v(" "+_vm._s(_vm.t('STEP3_MSR_NORMS_TITLE', _vm.language))+" ")]),(
                (_vm.editable && _vm.msrNorms.length > 0) ||
                (!_vm.editable && _vm.SELECTED_MSR_NORMS.length > 0)
            )?_c('div',{staticClass:"p-mt-3"},_vm._l((_vm.msrNorms),function(norm,index){return _c('div',{key:index,staticClass:"p-mb-1"},[((!_vm.editable && _vm.SELECTED_MSR_NORMS.includes(norm.id)) || _vm.editable)?_c('span',[_c('Checkbox',{attrs:{"id":norm.id,"name":norm.name,"value":norm.id,"disabled":!_vm.editable},model:{value:(_vm.SELECTED_MSR_NORMS),callback:function ($$v) {_vm.SELECTED_MSR_NORMS=$$v},expression:"SELECTED_MSR_NORMS"}}),_c('span',{staticClass:"p-ml-2",attrs:{"v-if":norm.name}},[_vm._v(_vm._s(norm.name))]),_c('i',{staticClass:"p-ml-2 fa fa-info-circle help",on:{"click":function($event){return _vm.toggleInformation($event, norm.info)}}})],1):_vm._e()])}),0):_vm._e()]),(_vm.editable)?_c('div',{staticClass:"button-section"},[_c('div',{staticClass:"previous-btn"},[_c('Button',{staticClass:"previous p-mr-3",attrs:{"label":_vm.t('PREVIOUS')},on:{"click":function($event){return _vm.previous()}}})],1),_c('div',{staticClass:"next-btn"},[_c('Button',{staticClass:"next",attrs:{"label":_vm.t('NEXT')},on:{"click":function($event){return _vm.next()}}})],1)]):_vm._e(),_c('OverlayPanel',{ref:"labelInfomationOpNorm",staticClass:"information-overlay-panel",attrs:{"appendTo":"body","showCloseIcon":true,"id":"label_overlay_panel"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.normInfoContent)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }


















































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import authService from '@/shared/services/Authentication'
import router from '@/router'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import idleTimeout from 'idle-timeout'
import OrganisationSelect from './OrganisationSelect.vue'

export default defineComponent({
    props: {
        // to find user navigated by session timed out
        autoLogout: {
            type: String,
        },
        expiredUser: {
            type: String,
        },
        existingUser: {
            type: String,
        },
    },
    components: { OrganisationSelect },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const loginPassed = ref(false)
        const forgotPassword = ref(false)
        const refToken = ref(null)
        const LOGIN_FORM = ref({
            USERNAME: '',
            PASSWORD: '',
        })
        const twoFactor = ref({
            activated: false,
            activationUrl: '',
            required: false,
        })
        const errors = ref({
            USERNAME: { missingValue: false },
            PASSWORD: { missingValue: false },
            serverError: false,
            networkError: false,
        })
        let refreshTokenTimer
        const validateUserExpiration = ref(false)
        const isUserExpired = ref(false)
        const loading = ref(false)
        localStorage.clear()
        const { RESET_STATES } = useActions(['RESET_STATES'])
        RESET_STATES()

        const validateForm = () => {
            // reset errors
            errors.value = {
                USERNAME: { missingValue: false },
                PASSWORD: { missingValue: false },
                serverError: false,
                networkError: false,
            }
            // missingValue check
            errors.value.USERNAME.missingValue = utils.validateFields(
                LOGIN_FORM.value.USERNAME,
                'string'
            )
            errors.value.PASSWORD.missingValue = utils.validateFields(
                LOGIN_FORM.value.PASSWORD,
                'string'
            )
        }

        // initialize idle-timeout
        const idleRunner = idleTimeout(
            () => {
                // stop refreshTokenTimer if created
                if (refreshTokenTimer) {
                    clearInterval(refreshTokenTimer)
                }
                authService.clearAuthentication()
                router.push({ name: 'Login' })
            },
            {
                element: document.body,
                timeout: variables.SESSION.IDLE_TIMEOUT,
                loop: false,
            }
        )
        // navigate post success login
        const navigate = () => {
            const loggedInUser: { user: { id; organisations } } = utils.ls.get(
                variables.LOCAL_STORAGE_ITEMS.USER,
                {
                    decrypt: true,
                }
            )
            // logged in users organisations
            const organisations = loggedInUser && loggedInUser.user.organisations
            /* user has only one organisation,
            - and if the organisation has completed wizard 
                - check the whether user is an active user in particular organisation.
                    - if user is not an active user, display error message 
                    - if user is an active user navigate to Dashboard
            - If the oraganisation hasn't completed wizard
                - navigate to wizard*/
            if (organisations.length === 1) {
                if (utils.wizardCompletionCheck()) {
                    isUserExpired.value = utils.checkUserExpire(
                        organisations[0].startDate,
                        organisations[0].endDate
                    )
                    if (isUserExpired.value) {
                        return
                    }
                    router.push({ name: 'Dashboard' })
                } else {
                    router.push({ name: 'WizardPage1' })
                }
            } else {
                // if user has more than one organisation navigate user to `OrganisationSelect` component
                router.push({ name: 'OrganisationSelect' })
            }
        }

        const submitLogin = async () => {
            // validate login form
            validateForm()
            // check for validation errors
            if (utils.hasErrors(errors.value)) {
                return
            }
            loading.value = true
            // User login takes place
            let result
            try {
                result = await authService.setAuthentication(
                    LOGIN_FORM.value.USERNAME,
                    LOGIN_FORM.value.PASSWORD
                )
            } catch (err){
                errors.value.networkError = true
                loading.value = false
            }

            if (result['status']) {
                // get 2FA information
                twoFactor.value.activated = result['twoFactorActivated']
                twoFactor.value.activationUrl = result['twoFactorUrl']
                twoFactor.value.required = result['twoFactorRequired']
                if (!twoFactor.value.required) {
                    // timer to refresh token
                    refreshTokenTimer = setInterval(() => {
                        // refresh the token
                        authService.refreshToken()
                    }, variables.SESSION.REFRESH_TOKEN_TIMEOUT)
                    loading.value = false
                    navigate()
                } else {
                    loading.value = false
                    router.push({
                        name: 'TwoFactorAuthentication',
                        params: {
                            twofactorActivated: twoFactor.value.activated
                                ? 'activated'
                                : 'not-activated',
                            twofactoractivationUrl: twoFactor.value.activationUrl,
                        },
                    })
                }
                // start idle checker
                idleRunner.resume()
            } else {
                loading.value = false
                errors.value.serverError = true
            }
        }

        const navigateToForgotPassword = () => {
            router.push({
                name: 'ForgotPassword',
            })
        }

        onMounted(() => {
            idleRunner.pause()
        })

        return {
            language,
            t,
            refToken,
            LOGIN_FORM,
            loginPassed,
            forgotPassword,
            submitLogin,
            navigateToForgotPassword,
            errors,
            validateUserExpiration,
            isUserExpired,
            loading
        }
    },
})

/* Calling mutations via actions. 
Even though this required for asynchronous mutations, 
it's always good practice to call mutations via actions */

import { ActionTree} from 'vuex'
import { State } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes } from './mutation-types'

// Actions contracts. Define action interface
export interface Actions {
    [ActionTypes.SET_NETWORK_ERROR](
        { commit },
        payload: boolean,
    ): void;

    [ActionTypes.SET_LANGUAGE](
        { commit },
        payload: string,
    ): void;

    [ActionTypes.SET_USERS](
        { commit },
        payload: string,
    ): void;

    [ActionTypes.SET_ROLE](
        { commit },
        payload: string,
    ): void;

    [ActionTypes.SET_OPEN_MENU](
        { commit },
        payload: [],
    ): void;

    [ActionTypes.SET_LEFT_MENU_SIDEBAR](
        { commit },
        payload: [],
    ): void;

    [ActionTypes.SET_WIZARD_DATA](
        { commit },
        payload: [],
    ): void;
    [ActionTypes.SET_SIGNUP_DATA](
        { commit },
        payload: [],
    ): void;
    [ActionTypes.RESET_STATES](
        { commit },
        payload: [],
    ): void;
    [ActionTypes.SET_NORM_TYPES](
        { commit },
        payload: [],
    ): void;
    [ActionTypes.SET_SELECTED_NORMS](
        { commit },
        payload: [],
    ): void;
    [ActionTypes.SET_ORGANISATION_NAME](
        { commit },
        payload: [],
    ): void;
}

// Define actions
export const actions: ActionTree<State, State> & Actions = {
    // eslint-disable-next-line
    [ActionTypes.SET_NETWORK_ERROR]({ commit }, payload) {
        commit(MutationTypes.SET_NETWORK_ERROR, payload) 
    },
    // using mutation function name to call mutations by action commit
    [ActionTypes.RESET_STATES]({ commit }) {
        commit(MutationTypes.RESET_STATES) 
    },
    // set language
    [ActionTypes.SET_LANGUAGE]({ commit }, payload) {
        commit(MutationTypes.SET_LANGUAGE, payload) 
    },
    // set users
    [ActionTypes.SET_USERS]({ commit }, payload) {
        commit(MutationTypes.SET_USERS, payload) 
    },
    // set role
    [ActionTypes.SET_ROLE]({ commit }, payload) {
        commit(MutationTypes.SET_ROLE, payload) 
    },
    [ActionTypes.SET_OPEN_MENU]({ commit }, payload) {
        commit(MutationTypes.SET_OPEN_MENU, payload) 
    },
    [ActionTypes.SET_LEFT_MENU_SIDEBAR]({ commit }, payload) {
        commit(MutationTypes.SET_LEFT_MENU_SIDEBAR, payload) 
    },
    [ActionTypes.SET_WIZARD_DATA]({ commit }, payload) {
        commit(MutationTypes.SET_WIZARD_DATA, payload) 
    },
    [ActionTypes.SET_SIGNUP_DATA]({ commit }, payload) {
        commit(MutationTypes.SET_SIGNUP_DATA, payload) 
    },
    [ActionTypes.SET_NORM_TYPES]({ commit }, payload) {
        commit(MutationTypes.SET_NORM_TYPES, payload) 
    },
    [ActionTypes.SET_SELECTED_NORMS]({ commit }, payload) {
        commit(MutationTypes.SET_SELECTED_NORMS, payload) 
    },
    [ActionTypes.SET_ORGANISATION_NAME]({ commit }, payload) {
        commit(MutationTypes.SET_ORGANISATION_NAME, payload) 
    },
}

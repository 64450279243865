

































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import RadarChart from '@/shared/components/dashboard/RadarChart.vue'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import dashboardQuery from '@/shared/queries/dashboardQueries'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import variables from '@/shared/variables'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    components: { RadarChart },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const configuredNorms = ref([])

        // get client config norms data
        const getClientConfig = async () => {
            const clientConfig = `
                query{
                    organisationconfig(orgId:${organisationId}){
                        ${dashboardQuery.CLIENT_CONFIGURATION_NORMS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(clientConfig)
            } catch (err) {
                throw Error('Error whilte retrieving client config data')
            }
            if (result) {
                const clientConfigResult = result.data.organisationconfig
                configuredNorms.value = clientConfigResult && clientConfigResult.selectedNorms
            }
        }

        onMounted(() => {
            getClientConfig()
        })

        return {
            t,
            language,
            configuredNorms,
        }
    },
})

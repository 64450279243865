









































































import { Ref, computed, defineComponent, onMounted, provide, ref, watch } from '@vue/composition-api'
import LeftMenu from '@/shared/components/left-panel/LeftMenu.vue'
import variables from '@/shared/variables'
import utils from '@/shared/mixins/utils'
import authService from '@/shared/services/Authentication'
import router from '@/router'
import { useActions, useState } from '@/shared/mixins/helpers'
import generalData from '@/assets/data/general-data.json'
import { useI18n } from 'vue-i18n-composable'
import CollapsedMenuSection from './CollapsedMenuSection.vue'
import { MenuSectionState } from '@/dto/leftMenuDTO'
export default defineComponent({
    name: 'LeftPanel',
    components: {
        LeftMenu,
        CollapsedMenuSection,
    },
    setup() {
        // We inject shared state into the child components to allow them to syncronize
        // which overlay menus are opened, and close other menus when a new one opens.
        // This is a bit less verbose than using props, and more efficient because
        // we avoid causing a re-render of the menu whenever a section opens.
        const LEFT_MENU_OPEN_SECTION: Ref<string> = ref(null)
        const setOpenSection = (newSection: string): void => {
            // console.log('setOpenSection', newSection)
            LEFT_MENU_OPEN_SECTION.value = newSection
        }

        // Close a specific section, or omit the identifier to close any section that may be open
        const closeSection = (sectionIdentifier?: string): void => {
            if (LEFT_MENU_OPEN_SECTION.value === sectionIdentifier || sectionIdentifier === null) {
                LEFT_MENU_OPEN_SECTION.value = null
            }
        }
        provide('LEFT_MENU_OPEN_SECTION', {
            LEFT_MENU_OPEN_SECTION,
            setOpenSection,
            closeSection,
        })
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { menuOpen } = useState(['menuOpen'])
        const profileMenuRef = ref(null)
        const loggedInUserName = ref('')
        const { users } = useState(['users'])
        const organisationDropdownOptions = ref([])
        const currentOrganisation = ref(0)
        const orgsWithRoles = ref([])
        const organisationName = ref('')
        const { SET_ROLE } = useActions(['SET_ROLE'])
        const roles = ref([])
        const selectedRole = ref(0)
        const selectedRoleName = ref('')

        // logout
        const logout = () => {
            authService.clearAuthentication()
            router.push({ name: 'Login' })
        }
        
        // Model of items in the PrimeVue menu when clicking on user name
        // in the expanded menu
        const profileMenuModel = computed(
            () => [{
            label: t('USER_MENU_PROFILE', language.value),
            command: () => router.push({name: 'Profile'}),
            icon: 'fas fa-user',
        },
        {
            label: t('USER_MENU_LOGOUT', language.value),
            command: () => logout(),
            icon: 'fas fa-sign-out-alt',
        }
        ])

        const setRoles = () => {
            roles.value = []
            // set roles dropdown array
            const rolesCopy: [{ id; name }] = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ROLES, {
                decrypt: true,
            })
            // i18n convered dropdown
            rolesCopy.map((val) => {
                roles.value.push({
                    id: val.id,
                    name: val.name,
                    value: t(utils.keyToName(generalData.ROLE, val.name), language.value),
                })
            })

            // set current role id
            const currentRoleId: number = utils.ls.get(
                variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE,
                {
                    decrypt: true,
                }
            )
            selectedRole.value = currentRoleId
            selectedRoleName.value = utils.ls.get(
                variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE_NAME,
                {
                    decrypt: true,
                }
            )
        }

        const setSelectedRole = () => {
            selectedRoleName.value =
                roles.value.find((role) => role.id === selectedRole.value) &&
                roles.value.find((role) => role.id === selectedRole.value).name

            // set role for store in order to set permissions
            SET_ROLE(selectedRoleName.value)
            // set role name
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE_NAME, selectedRoleName.value)
            // store role id in local storage in order to send role in request header
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE, selectedRole.value)

            //  to indicate user role has changed
            window.location.reload()
        }

        // set role when selecting from overlay panel popup sub menu
        const setSelectedOPRole = (id) => {
            selectedRole.value = id
            setSelectedRole()
        }
        // set full name
        const setUserName = () => {
            const loggedInUser: { user: { firstName; lastName } } = utils.ls.get(
                variables.LOCAL_STORAGE_ITEMS.USER,
                {
                    decrypt: true,
                }
            )
            // userdata && userdata.user check to handle null/undefined
            const firstName = loggedInUser && loggedInUser.user.firstName
            const lastName = loggedInUser && loggedInUser.user.lastName

            // check firstname and lastname to avoid displayuserdata name as undefined or null
            loggedInUserName.value =
                (firstName !== undefined && firstName !== null ? firstName : '') +
                ' ' +
                (lastName !== undefined && lastName !== null ? lastName : '')
        }

        const setRoleForOrg = () => {
            // find the role of selected organization
            const selectedOrg = orgsWithRoles.value.find(
                (val) => val.id === currentOrganisation.value
            )

            if (!selectedOrg) {
                return
            }
            // set selected role on localStorage
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE, selectedOrg.roles[0].id)
            // set roles on localStorage
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.ROLES, selectedOrg.roles)

            // set selected role name to store
            SET_ROLE(selectedOrg.roles[0].name)
            // set selected role name on localStorage
            utils.ls.set(
                variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE_NAME,
                selectedOrg.roles[0].name
            )
        }

        // set organisation dropdown items
        const setOrganisationDropdownValues = () => {
            organisationDropdownOptions.value = []
            for (const orgAndRoles of orgsWithRoles.value) {
                organisationDropdownOptions.value.push({
                    id: orgAndRoles.id,
                    name: orgAndRoles.name,
                })
            }

            currentOrganisation.value = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
                decrypt: true,
            })

            organisationName.value = organisationDropdownOptions.value.find(
                (val) => val.id === currentOrganisation.value
            ).name
        }

        const organizationSwitch = () => {
            // remove menu active
            // utils.removeActiveMenu()

            // update local storage with selected organization
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, currentOrganisation.value)
            organisationName.value = organisationDropdownOptions.value.find(
                (val) => val.id === currentOrganisation.value
            ).name
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.ORGANISATION_NAME, organisationName.value)

            // // refresh after few seconds .TODO #531
            setTimeout(() => {
                // full refresh to render UI for to indicate values got refresh
                window.location.reload()
            }, 100)

            // set role based on selected organization
            setRoleForOrg()

            utils.wizardCompletionCheck()

            // clear current seleted norms from local-storage
            utils.ls.remove(variables.LOCAL_STORAGE_ITEMS.SELECTED_NORMS)
        }

        // set selected role, when selecting from overlay panel sub menu
        const setSelectedOrg = (id) => {
            currentOrganisation.value = id
            organizationSwitch()
        }

        // display organisations only which user is active
        const getUserActiveOrganisations = () => {
            orgsWithRoles.value = authService
                .loadOrgsWithRoles()
                .filter(
                    (organisation: { startDate: Date; endDate: Date }) =>
                        !utils.checkUserExpire(organisation.startDate, organisation.endDate)
                )
        }

        // replace class names of Primevue dropdown to use different arrow icon
        const replaceDropdownIcon = (className: string) => {
            const node = document.querySelector(className).classList
            node.remove('pi-chevron-down')
            node.remove('pi')
            node.add('fas')
            node.add('fa-sort-down')
        }

        // get first letter of organisation name
        const orgFirstLetter = computed(() => {
            return organisationName.value && organisationName.value.charAt(0)
        })

        // Build menu data for the user actions menu (switch org, profile, role)
        // that is shown when hovering over the user icon. 
        const userMenuState: Ref<MenuSectionState> = computed(
            () => ({
                icon: {name: 'fa-user'},
                isSingleItemSection: false,
                items: [],
                active: false,
                subSections: [
                    {
                        header: organisationName.value,
                        isSingleItemSection: false,
                        subSections: [],
                        items: orgsWithRoles.value.map(
                            (org) => ({
                                header: org.name,
                                active: org.name === organisationName.value,
                                command: () => {setSelectedOrg(org.id)},
                            })
                        ),
                        active: false,
                        icon: {},
                    },
                    {
                        header: loggedInUserName.value,
                        isSingleItemSection: false,
                        subSections: [],
                        items: [
                            {
                                header: t('USER_MENU_PROFILE', language.value),
                                active: false,
                                command: () => router.push({name: 'Profile'}),
                            },
                            {
                                header: t('USER_MENU_LOGOUT', language.value),
                                active: false,
                                command: () => logout(),
                            },
                        ],
                        active: false,
                        icon: {},
                    },
                    {
                        header: t(
                            utils.keyToName(generalData.ROLE, selectedRoleName.value),
                            language.value
                        ),
                        isSingleItemSection: false,
                        subSections: [],
                        items: roles.value.map(
                            (role) => ({
                                header: t(
                                    utils.keyToName(generalData.ROLE, role.name),
                                    language.value
                                ),
                                active: role.name === selectedRoleName.value,
                                command: () => setSelectedOPRole(role.id)
                            })
                        ),
                        active: false,
                        icon: {},
                    },
                ],
            })
        )
        
        // We need to track when the user menu overlay is visible. 
        // This way the overlay can be synced with the left panel overlays.
        const userMenuOverlayOpened = ref(false);
        const handleHideUserMenu = () => {
            userMenuOverlayOpened.value = false;
        }

        const handleShowUserMenu = () => {
            userMenuOverlayOpened.value = true;
        }

        onMounted(() => {
            getUserActiveOrganisations()
            setOrganisationDropdownValues()
            setUserName()

            // replace dropdown icon of user roles dropdown
            replaceDropdownIcon('.user-roles-dropdown .p-dropdown-trigger-icon')
            // replace dropdown icon of organisation dropdown
            replaceDropdownIcon('.organisations-dropdown .p-dropdown-trigger-icon')
        })
        watch(
            users,
            () => {
                setRoles()
            },
            { immediate: true }
        )

        return {
            t,
            language,
            utils,
            orgFirstLetter,
            menuOpen,
            organisationDropdownOptions,
            currentOrganisation,
            organizationSwitch,
            orgsWithRoles,
            organisationName,
            loggedInUserName,
            selectedRole,
            roles,
            setSelectedRole,
            setSelectedOrg,
            selectedRoleName,
            setSelectedOPRole,
            userMenuState,
            userMenuOverlayOpened,
            handleHideUserMenu,
            handleShowUserMenu,
            profileMenuModel,
            profileMenuRef,
        }
    },
})



























import { defineComponent, ref, watch } from '@vue/composition-api'
import { useActions, useState } from '@/shared/mixins/helpers'
import clientConfigStoredData from '@/assets/data/client-configuration-data.json'
import SelectButtons from '@/shared/components/SelectButtons.vue'
import { useI18n } from 'vue-i18n-composable'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    props: {
        editable: {
            type: Boolean,
        },
        wizardData: {
            type: Object,
        },
        step: {
            type: Number,
        },
    },
    components: { SelectButtons },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const compSizes = ref(clientConfigStoredData.COMP_SIZE)
        const { SET_WIZARD_DATA } = useActions(['SET_WIZARD_DATA'])
        const compSize = ref('')
        const errors = ref({
            COMPANY_SIZE: { missingValue: false },
        })

        // validate company size field
        const validateFields = () => {
            errors.value.COMPANY_SIZE.missingValue = utils.validateFields(compSize.value, 'string')
        }

        // get selected company size value
        const getSelectedValue = (companySize) => {
            compSize.value = companySize
        }

        const next = () => {
            // check for validation errors
            validateFields()
            if (utils.hasErrors(errors.value)) {
                return
            }
            // store company size
            SET_WIZARD_DATA({ companySize: compSize.value })
            emit('next', { step: props.step, formData: { companySize: compSize.value } })
        }

        // set wizard data on refresh
        watch(
            () => Object.assign({}, props.wizardData),
            () => {
                if (props.wizardData) compSize.value = props.wizardData.companySize
            },
            { immediate: true }
        )

        return {
            t,
            language,
            compSizes,
            getSelectedValue,
            compSize,
            next,
            errors,
        }
    },
})











import { defineComponent } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'

export default defineComponent({
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])

        return {
            language,
            t,
        }
    },
})

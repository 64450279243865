// set default user for users dropdown
const DEFAULT_USER = 'arjan.kremer'
// set default language 'en' or 'nl'
const DEFAULT_LANGUAGE = 'nl'
// Load translation data from the backend over graphql instead of using the 
// bundled translation files
const USE_BACKEND_TRANSLATIONS = false
// default username coming from backend
const DEFAULT_USERNAME = 'default'

// column alert colors
const ALERT_COLORS = {
    GREEN: '#5bae56',
    ORANGE: '#ff9800',
    RED: '#f44336',
}

const LOCAL_STORAGE_ITEMS = {
    AUTH_TOKEN: 'token',
    USER: 'user',
    ORGANISATION: 'organisation',
    ORGANISATION_NAME: 'organisation-name',
    ROLES: 'roles',
    SELECTED_ROLE: 'role',
    SELECTED_ROLE_NAME: 'role-name',
    ACTIVE_MENU: 'active-menu',
    SELECTED_NORMS: 'selected-norms',
    TWO_FACTOR_PASS: 'two-factor-pass',
    WIZARD_DATA: 'wizard-data',
    WIZARD_COMPLETION_STATUS: 'wizard-status',
    PRIORITY_FILTER_TOGGLE: 'priority-filter-toggle',
    MENU_COLLAPSED: 'menu-collapsed',
    SSO_VERIFIER: 'sso-verifier',
}

const SESSION = {
    IDLE_TIMEOUT: 1000 * 60 * 30,
    REFRESH_TOKEN_TIMEOUT: 1000 * 60 * 20,
}

const DEFAULT_NORM = 'My own Norm'

// doughnut chart settings
const DOUGHNUT_CHART_SETTINGS = {
    responsive: true,
    cutoutPercentage: 85,
    radius: 1000,
    legend: {
        display: false,
    },
}

const LANGUAGES = {
    ENGLISH: 'en',
    DUTCH: 'nl'
}

const DOCUMENT_URLS = {
    HELP: 'https://perium-docs.s3.eu-central-1.amazonaws.com/Definities_v20210821.pdf',
    HELP_NORM_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/01_Normen.pdf',
    HELP_ORGANISATION_SETTINGS_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/02_Organisatie_instellingen.pdf',
    HELP_THREATS_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/03_Bedreigingen.pdf',
    HELP_VULNERABILITIES_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/04_Kwetsbaarheden.pdf',
    HELP_RISKS_DOC: "https://perium-docs.s3.eu-central-1.amazonaws.com/help/05_Risico_s.pdf",
    HELP_ASSETS_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/06_Asset.pdf',
    HELP_RISK_ASSESSMENTS_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/07_Risico_assessments.pdf',
    HELP_CONTROLS_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/08_Beheersmaatregelen.pdf',
    HELP_CONTROL_ASSESSMENTS_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/09_Beheersmaatregel_assessment.pdf',
    HELP_ASSET_CONTROL_ASSESSMENTS_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/10_Asset_-_Beheersmaatregelen_assessments.pdf',
    HELP_ACTIONPLANS_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/11_Actieplannen.pdf',
    HELP_DASHBOARD_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/12_Dashboard.pdf',
    HELP_INTRODUCTION_DOC: 'https://perium-docs.s3.eu-central-1.amazonaws.com/help/Introductie_Perium.pdf',
    TERMS_AND_CONDITIONS:
        'https://perium-docs.s3.eu-central-1.amazonaws.com/terms/NLdigital+Voorwaarden+-+NL.pdf',
    PRIVACY_POLICY:
        'https://perium-docs.s3.eu-central-1.amazonaws.com/privacy/Privacy+statement+Perium+v0.1.docx.pdf',
}

// if it's a mobile device load 10 records initially, if not 20
// Cannot use `mobileDeviceCheck` function from utils files as variable.ts file reads before utils file
// const userAgent = navigator.userAgent.toLowerCase()
// const mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//     userAgent
// )
// switching off infinity scrolling by calling for maximum data
const LIST_ITEMS_PER_PAGE = 100000

const SENTRY_CONFIG = {
    // DSN url will create the connection from applicatioon to sentry
    DSN: "https://f6c9c1645e2d42aaafb7db50fe0f6ffa@o1112141.ingest.sentry.io/6370116",
    // current release
    RELEASE: '0.26.4'
}
export default {
    DEFAULT_USER,
    DEFAULT_LANGUAGE,
    USE_BACKEND_TRANSLATIONS,
    ALERT_COLORS,
    LOCAL_STORAGE_ITEMS,
    SESSION,
    DEFAULT_NORM,
    DEFAULT_USERNAME,
    DOUGHNUT_CHART_SETTINGS,
    DOCUMENT_URLS,
    LIST_ITEMS_PER_PAGE,
    SENTRY_CONFIG,
    LANGUAGES,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useStore } from '@/store/index';
import { useStore } from '@/store'
import { computed } from '@vue/composition-api'
// import { useStore } from '@vueblocks/vue-use-vuex';
/* receiving states as an array to useState function. it will return stored states as an Object. 
( instead of writing const store = useStore(); computed(()=> store.state[<state_name>] for multiple state declarations ) */
export const useState = (states: any[])=> {
    const store = useStore()
    const keypair = states.map(s => [s, computed(()=> store.state[s])])
    return Object.fromEntries(keypair)
}

/* receiving getters as an array to useGetters function. it will return stored getters as an Object. 
( instead of writing const store = useStore(); computed(()=> store.getters[<getter_name>] for calling multiple getter functions  ) */
export const useGetters = (getters: any[]) => {
    const store = useStore()
    const keypair = getters.map(g => [g, computed(() => store.getters[g])])
    return Object.fromEntries(keypair)
}

/* receiving mutations as an array to useMutations function. it will return mutations as an Object. 
( instead of writing const store = useStore(); computed(()=> store.commit[<mutation_function_name>] for calling multiple mutations functions  ) */
export const useMutations = (mutations: any[]) => {
    const store = useStore()
    const keypair = mutations.map(m => [m, (input: any) => store.commit(m, input)]) 
    return Object.fromEntries(keypair)
}

/* receiving actions as an array to useActions function. it will return mutations as an Object. 
( instead of writing const store = useStore(); computed(()=> store.dispatch[<action_function_name>] for calling multiple action functions  ) */
export const useActions = (actions: any[]) => {
    const store = useStore()
    const keypair = actions.map(m => [m, (input: any) => store.dispatch(m, input)]) 
    return Object.fromEntries(keypair)
}
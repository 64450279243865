


































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import authService from '@/shared/services/Authentication'
import router from '@/router'
export default defineComponent({
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const organisations = ref([])
        const SELECTED_ORG = ref(0)
        const { SET_ROLE } = useActions(['SET_ROLE'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const displayUserExpireMessage = ref(false)
        const loading = ref(false)
        const { SET_ORGANISATION_NAME } = useActions(['SET_ORGANISATION_NAME'])
        const validateUserExpiration = (selectedOrg) => {
            displayUserExpireMessage.value = utils.checkUserExpire(
                selectedOrg.startDate,
                selectedOrg.endDate
            )
            return false
        }

        const setRoleForOrg = () => {
            // find the role of selected organization
            const selectedOrg = organisations.value.find((val) => val.id === SELECTED_ORG.value)

            if (!selectedOrg) {
                return
            }
            // update local storage with selected organization
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, SELECTED_ORG.value)
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.ORGANISATION_NAME, selectedOrg.name)
            SET_ORGANISATION_NAME(selectedOrg.name)

            // set selected role on localStorage
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE, selectedOrg.roles[0].id)
            // set roles on localStorage
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.ROLES, selectedOrg.roles)

            // set selected role name to store
            SET_ROLE(selectedOrg.roles[0].name)
            // set selected role name on localStorage
            utils.ls.set(
                variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE_NAME,
                selectedOrg.roles[0].name
            )
        }
        // proceed to dashboard/wizard based on wizardCompletion status
        const proceed = () => {
            loading.value = true
            setRoleForOrg()
            if (utils.wizardCompletionCheck()) {
                loading.value = false
                router.push({ name: 'Dashboard' })
                const isMobileDevice = utils.mobileDeviceCheck()
                // open left menu by default when user logs in
                if (!isMobileDevice) {
                    SET_OPEN_MENU(true)
                    utils.openNav(true)
                }
            } else {
                loading.value = false
                router.push({ name: 'WizardPage1' })
            }
        }

        const organisationSelect = () => {
            // find the role of selected organization
            const selectedOrg = organisations.value.find((val) => val.id === SELECTED_ORG.value)
            if (!validateUserExpiration(selectedOrg)) {
                return
            }
        }

        onMounted(() => {
            organisations.value = authService.loadOrgsWithRoles()
            // if user is not active in any organisations navigate to Login with error message
            if (organisations.value.length === 0) {
                router.push({ name: 'Login', params: { expiredUser: 'true' } })
            }
        })

        return {
            t,
            language,
            organisations,
            SELECTED_ORG,
            proceed,
            organisationSelect,
            displayUserExpireMessage,
            loading
        }
    },
})

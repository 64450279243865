







































import { defineComponent, ref, computed, PropType } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import type { MenuSectionState } from '@/dto/leftMenuDTO';

export default defineComponent({
    name: 'AccordionMenuSection',
    props: {
        section: {
            required: true,
            type: Object as PropType<MenuSectionState>
        },
        level: {
            type: Number,
            default: 0,
        }
    },
    setup (props) {
        const { t } = useI18n()
        const { language } = useState(['language'])

        // isOpened is intentionally not reactive, since we don't want to close the old menu section
        // when navigating to a new page. 
        const isOpened = ref(props.section.active && !props.section?.isSingleItemSection);
        const handleHeaderClick = () =>  {
            if (props.section?.isSingleItemSection) {
                props.section.items[0].command()
            } else {
                isOpened.value = !isOpened.value;
            }
        }

        // Turn our menu state into the structure expected by PrimeVue
        // This will reactively update when language is changed
        const menuModel = computed(
            () => props.section.items.map(
                item => ({
                    label: item.header,
                    class: item.active ? 'active-item' : '',
                    command: item.command,
                })
            )
        )

        return {
            t, 
            language,
            isOpened,
            handleHeaderClick,
            menuModel,
        }
    }
})

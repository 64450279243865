


















import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
    props: {
        selections: {
            type: Array,
        },
        disabled: {
            type: Boolean,
        },
        value: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const SELECTED_ITEM = ref('')

        // selection change event
        const onChangeSelection = () => {
            emit('changeSelection', SELECTED_ITEM.value)
        }

        // set value on load
        const setData = () => {
            SELECTED_ITEM.value = props.value
        }

        watch(
            () => props.value,
            () => {
                if (props.value) setData()
            },
            {
                immediate: true,
            }
        )

        return {
            onChangeSelection,
            SELECTED_ITEM,
        }
    },
})

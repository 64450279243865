var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-wrapper"},[_c('loading',{attrs:{"active":_vm.loading,"can-cancel":true,"is-full-page":true,"color":"#414bb3"}}),_c('div',{staticClass:"signup-form"},[_c('form',{attrs:{"action":"signup"},on:{"submit":function($event){$event.preventDefault();return _vm.submitSignUp($event)}}},[_c('Card',{staticStyle:{"width":"25em"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"perium-logo-middle"},[_c('img',{staticClass:"perium-logo-img",attrs:{"src":require("@/assets/images/Logo-Perium-middel.jpg")}})]),(!_vm.successInviteSent)?_c('div',{staticClass:"signup-title p-mt-3"},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_TITLE'))+" ")]):_vm._e(),(_vm.successInviteSent)?_c('div',{staticClass:"signup-title p-mt-3"},[_vm._v(" "+_vm._s(_vm.t('EMAIL_CONFIRMATION', _vm.language))+" "),_c('span',{staticClass:"success-icon"},[_c('i',{staticClass:"far fa-check-circle"})])]):_vm._e(),(!_vm.successInviteSent)?_c('div',{staticClass:"signup-sub-text p-mt-3"},[_vm._v(" Maak een account aan en begin vandaag nog met Perium. ")]):_vm._e()])]},proxy:true},{key:"content",fn:function(){return [(!_vm.successInviteSent)?_c('div',{staticClass:"login-form-content"},[_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('div',{class:[
                                        _vm.utils.hasErrors(_vm.errors.FIRSTNAME) ? 'label-error' : '',
                                        'label-text' ],attrs:{"for":"sfirstname"}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_FIRSTNAME_LABEL', _vm.language))+" "),_c('span',{staticClass:"mandatory-mark"},[_vm._v("*")])]),_c('InputText',{class:[_vm.errors.FIRSTNAME.missingValue ? 'input-error' : ''],attrs:{"id":"sfirstname","type":"text","autofocus":""},model:{value:(_vm.SIGNUP_FORM.FIRSTNAME),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "FIRSTNAME", $$v)},expression:"SIGNUP_FORM.FIRSTNAME"}}),(_vm.errors.FIRSTNAME.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('div',{class:[
                                        _vm.utils.hasErrors(_vm.errors.LASTNAME) ? 'label-error' : '',
                                        'label-text' ],attrs:{"for":"slastname"}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_LASTNAME_LABEL', _vm.language))),_c('span',{staticClass:"mandatory-mark"},[_vm._v("*")])]),_c('InputText',{class:[_vm.errors.LASTNAME.missingValue ? 'input-error' : ''],attrs:{"id":"slastname"},model:{value:(_vm.SIGNUP_FORM.LASTNAME),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "LASTNAME", $$v)},expression:"SIGNUP_FORM.LASTNAME"}}),(_vm.errors.LASTNAME.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('div',{class:[
                                        _vm.utils.hasErrors(_vm.errors.EMAIL) ? 'label-error' : '',
                                        'label-text' ],attrs:{"for":"semail"}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_EMAIL_LABEL', _vm.language))),_c('span',{staticClass:"mandatory-mark"},[_vm._v("*")])]),_c('InputText',{class:[_vm.errors.EMAIL.missingValue ? 'input-error' : ''],attrs:{"id":"semail","type":"email"},model:{value:(_vm.SIGNUP_FORM.EMAIL),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "EMAIL", $$v)},expression:"SIGNUP_FORM.EMAIL"}}),(_vm.errors.EMAIL.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e(),(_vm.errors.EMAIL.invalidValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('ERROR__INVALID_EMAIL', _vm.language)))]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('div',{staticClass:"label-text",attrs:{"for":"stelephone"}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_TELE_LABEL', _vm.language))+" ")]),_c('InputText',{attrs:{"id":"stelephone"},model:{value:(_vm.SIGNUP_FORM.TELEPHONE),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "TELEPHONE", $$v)},expression:"SIGNUP_FORM.TELEPHONE"}})],1)]),_c('div',{staticClass:"p-mb-3 sub-headaer"},[_vm._v(" "+_vm._s(_vm.t('CLIENT_CONFIGURATION_COMPANY_DETAILS_TITLE', _vm.language))+" ")]),_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('div',{class:[
                                        _vm.utils.hasErrors(_vm.errors.COMPANY_NAME)
                                            ? 'label-error'
                                            : '',
                                        'label-text' ],attrs:{"for":"companyname"}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_COMPANY_NAME_LABEL', _vm.language))),_c('span',{staticClass:"mandatory-mark"},[_vm._v("*")])]),_c('InputText',{class:[
                                        _vm.errors.COMPANY_NAME.missingValue ? 'input-error' : '' ],attrs:{"id":"companyname"},model:{value:(_vm.SIGNUP_FORM.COMPANY_NAME),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "COMPANY_NAME", $$v)},expression:"SIGNUP_FORM.COMPANY_NAME"}}),(_vm.errors.COMPANY_NAME.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('div',{class:[
                                        _vm.utils.hasErrors(_vm.errors.COMPANY_NUMBER)
                                            ? 'label-error'
                                            : '',
                                        'label-text' ],attrs:{"for":"companynumber"}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_COMPANY_NUMBER_LABEL', _vm.language))),_c('span',{staticClass:"mandatory-mark"},[_vm._v("*")])]),_c('InputText',{class:[
                                        _vm.errors.COMPANY_NUMBER.missingValue ? 'input-error' : '' ],attrs:{"id":"companynumber"},model:{value:(_vm.SIGNUP_FORM.COMPANY_NUMBER),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "COMPANY_NUMBER", $$v)},expression:"SIGNUP_FORM.COMPANY_NUMBER"}}),(_vm.errors.COMPANY_NUMBER.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid p-formgrid p-grid"},[_c('div',{staticClass:"p-field p-col"},[_c('div',{class:[
                                        _vm.utils.hasErrors(_vm.errors.STREET_NAME)
                                            ? 'label-error'
                                            : '',
                                        'label-text' ],attrs:{"for":"companynumber"}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_COMPANY_STREET_NAME', _vm.language))),_c('span',{staticClass:"mandatory-mark"},[_vm._v("*")])]),_c('InputText',{class:[_vm.errors.STREET_NAME.missingValue ? 'input-error' : ''],attrs:{"id":"companynumber"},model:{value:(_vm.SIGNUP_FORM.STREET_NAME),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "STREET_NAME", $$v)},expression:"SIGNUP_FORM.STREET_NAME"}}),(_vm.errors.STREET_NAME.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()],1),_c('div',{staticClass:"p-field p-col"},[_c('div',{class:[
                                        _vm.utils.hasErrors(_vm.errors.HOUSE_NUMBER)
                                            ? 'label-error'
                                            : '',
                                        'label-text' ],attrs:{"for":"companynumber"}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_COMPANY_HOUSE_NO', _vm.language))),_c('span',{staticClass:"mandatory-mark"},[_vm._v("*")])]),_c('InputText',{class:[
                                        _vm.errors.HOUSE_NUMBER.missingValue ? 'input-error' : '' ],attrs:{"id":"companynumber"},model:{value:(_vm.SIGNUP_FORM.HOUSE_NUMBER),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "HOUSE_NUMBER", $$v)},expression:"SIGNUP_FORM.HOUSE_NUMBER"}}),(_vm.errors.HOUSE_NUMBER.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid p-formgrid p-grid"},[_c('div',{staticClass:"p-field p-col"},[_c('div',{class:[
                                        _vm.utils.hasErrors(_vm.errors.POSTCODE) ? 'label-error' : '',
                                        'label-text' ],attrs:{"for":"companypostcode"}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_POSTCODE_LABEL', _vm.language))),_c('span',{staticClass:"mandatory-mark"},[_vm._v("*")])]),_c('InputText',{class:[_vm.errors.POSTCODE.missingValue ? 'input-error' : ''],attrs:{"id":"companypostcode"},model:{value:(_vm.SIGNUP_FORM.POSTCODE),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "POSTCODE", $$v)},expression:"SIGNUP_FORM.POSTCODE"}}),(_vm.errors.POSTCODE.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()],1),_c('div',{staticClass:"p-field p-col"},[_c('div',{class:[
                                        _vm.utils.hasErrors(_vm.errors.CITY) ? 'label-error' : '',
                                        'label-text' ],attrs:{"for":"companycity"}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_CITY_LABEL', _vm.language))),_c('span',{staticClass:"mandatory-mark"},[_vm._v("*")])]),_c('InputText',{class:[_vm.errors.CITY.missingValue ? 'input-error' : ''],attrs:{"id":"companycity"},model:{value:(_vm.SIGNUP_FORM.CITY),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "CITY", $$v)},expression:"SIGNUP_FORM.CITY"}}),(_vm.errors.CITY.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('div',{staticClass:"privacy-policy"},[_c('Checkbox',{class:[
                                            _vm.utils.hasErrors(_vm.errors.TERMS_AND_CONDITIONS)
                                                ? 'input-error'
                                                : '' ],attrs:{"id":"sprivacypolicy","binary":true},model:{value:(_vm.SIGNUP_FORM.TERMS_AND_CONDITIONS),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "TERMS_AND_CONDITIONS", $$v)},expression:"SIGNUP_FORM.TERMS_AND_CONDITIONS"}}),_c('span',{staticClass:"p-pl-2"},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_TERMS_AND_CONDITIONS_LABEL', _vm.language))+" "),_c('a',{attrs:{"for":"sprivacypolicy"},on:{"click":_vm.goToTC}},[_vm._v(" "+_vm._s(_vm.t( 'SIGNUP_TERMS_AND_CONDITIONS_LABEL_POST', _vm.language ))+" ")])])],1),(_vm.errors.TERMS_AND_CONDITIONS.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()])]),_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('div',{staticClass:"privacy-policy"},[_c('Checkbox',{class:[
                                            _vm.utils.hasErrors(_vm.errors.PRIVACY_POLICY)
                                                ? 'input-error'
                                                : '' ],attrs:{"id":"sprivacypolicy","binary":true},model:{value:(_vm.SIGNUP_FORM.PRIVACY_POLICY),callback:function ($$v) {_vm.$set(_vm.SIGNUP_FORM, "PRIVACY_POLICY", $$v)},expression:"SIGNUP_FORM.PRIVACY_POLICY"}}),_c('span',{staticClass:"p-pl-2"},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_PRIVACY_POLICY_LABEL_PRE', _vm.language))+" "),_c('a',{attrs:{"for":"sprivacypolicy"},on:{"click":_vm.goToPP}},[_vm._v(" "+_vm._s(_vm.t('SIGNUP_PRIVACY_POLICY_LABEL_LINK', _vm.language))+" ")]),_vm._v(" "+_vm._s(_vm.t('SIGNUP_PRIVACY_POLICY_LABEL_POST', _vm.language))+" ")])],1),(_vm.errors.PRIVACY_POLICY.missingValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('MANDATORY_VALIDATION_MESSAGE', _vm.language)))]):_vm._e()])]),(_vm.errors.serverError)?_c('div',{staticClass:"server-error-message p-mt-3"},[_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t(_vm.backendErrorMessage, _vm.language)))])]):_vm._e()]):_vm._e(),(_vm.successInviteSent)?_c('div',{staticClass:"confirmation-box"},[_c('div',[_vm._v(" "+_vm._s(_vm.t('SIGNUP_EMAIL_SUCCESS_CONFIRMATION_CONTENT', _vm.language))+" ")])]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [(!_vm.successInviteSent)?_c('div',{staticClass:"signup-action-btn"},[_c('Button',{attrs:{"label":_vm.t('PERIUM_SIGNUP_BTN'),"type":"submit"}})],1):_vm._e()]},proxy:true}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
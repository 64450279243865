











































































































































































































































































































































































import { defineComponent, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'

export default defineComponent({
    methods: {
        // #661 is raised to reuse recaptcha function
        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "signup".
            const token = await this.$recaptcha('signup')
            return token

            // Do stuff with the received token.
        },
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { signupData } = useState(['signupData'])
        const toast = useToast()
        const SIGNUP_FORM = ref({
            FIRSTNAME: signupData.value.FIRSTNAME,
            LASTNAME: signupData.value.LASTNAME,
            EMAIL: signupData.value.EMAIL,
            TELEPHONE: signupData.value.TELEPHONE,
            COMPANY_NAME: signupData.value.COMPANY_NAME,
            COMPANY_NUMBER: signupData.value.COMPANY_NUMBER,
            STREET_NAME: signupData.value.STREET_NAME,
            HOUSE_NUMBER: signupData.value.HOUSE_NUMBER,
            POSTCODE: signupData.value.POSTCODE,
            CITY: signupData.value.CITY,
            TERMS_AND_CONDITIONS: signupData.value.TERMS_AND_CONDITIONS,
            PRIVACY_POLICY: signupData.value.PRIVACY_POLICY,
        })
        const errors = ref({
            FIRSTNAME: { missingValue: false },
            LASTNAME: { missingValue: false },
            EMAIL: { missingValue: false, invalidValue: false },
            COMPANY_NAME: { missingValue: false },
            COMPANY_NUMBER: { missingValue: false },
            STREET_NAME: { missingValue: false },
            HOUSE_NUMBER: { missingValue: false },
            POSTCODE: { missingValue: false },
            CITY: { missingValue: false },
            TERMS_AND_CONDITIONS: { missingValue: false },
            PRIVACY_POLICY: { missingValue: false },
            serverError: false,
        })
        const loading = ref(false)
        const successInviteSent = ref(false)
        const { SET_SIGNUP_DATA } = useActions(['SET_SIGNUP_DATA'])
        const backendErrorMessage = ref('')
        const { RESET_STATES } = useActions(['RESET_STATES'])
        RESET_STATES()

        localStorage.clear()

        const validateForm = () => {
            // reset errors
            errors.value = {
                FIRSTNAME: { missingValue: false },
                LASTNAME: { missingValue: false },
                EMAIL: { missingValue: false, invalidValue: false },
                COMPANY_NAME: { missingValue: false },
                COMPANY_NUMBER: { missingValue: false },
                STREET_NAME: { missingValue: false },
                HOUSE_NUMBER: { missingValue: false },
                POSTCODE: { missingValue: false },
                CITY: { missingValue: false },
                TERMS_AND_CONDITIONS: { missingValue: false },
                PRIVACY_POLICY: { missingValue: false },
                serverError: false,
            }
            // mandatory check
            errors.value.FIRSTNAME.missingValue = utils.validateFields(
                SIGNUP_FORM.value.FIRSTNAME,
                'string'
            )
            errors.value.LASTNAME.missingValue = utils.validateFields(
                SIGNUP_FORM.value.LASTNAME,
                'string'
            )
            errors.value.EMAIL.missingValue = utils.validateFields(SIGNUP_FORM.value.EMAIL, 'string')

            // Validate submitted email
            if (SIGNUP_FORM.value.EMAIL)
                errors.value.EMAIL.invalidValue = utils.validateFields(
                    SIGNUP_FORM.value.EMAIL,
                    'email'
                )

            errors.value.COMPANY_NAME.missingValue = utils.validateFields(
                SIGNUP_FORM.value.COMPANY_NAME,
                'string'
            )
            errors.value.COMPANY_NUMBER.missingValue = utils.validateFields(
                SIGNUP_FORM.value.COMPANY_NUMBER,
                'string'
            )
            errors.value.POSTCODE.missingValue = utils.validateFields(
                SIGNUP_FORM.value.POSTCODE,
                'string'
            )
            errors.value.STREET_NAME.missingValue = utils.validateFields(
                SIGNUP_FORM.value.COMPANY_NUMBER,
                'string'
            )
            errors.value.HOUSE_NUMBER.missingValue = utils.validateFields(
                SIGNUP_FORM.value.POSTCODE,
                'string'
            )
            errors.value.TERMS_AND_CONDITIONS.missingValue = utils.validateFields(
                SIGNUP_FORM.value.TERMS_AND_CONDITIONS,
                'boolean'
            )
            errors.value.PRIVACY_POLICY.missingValue = utils.validateFields(
                SIGNUP_FORM.value.PRIVACY_POLICY,
                'boolean'
            )
            errors.value.CITY.missingValue = utils.validateFields(SIGNUP_FORM.value.CITY, 'string')
        }

        const saveUserSignupData = async (token: string) => {
            const mutationQuery = `mutation ($input: SignUpInputPayload!) {
                        signUp(input: $input) {
                            user{
                                organisations{
                                    id
                                }
                            }
                            status
                            errorCode{
                                value
                            }
                        }
                    }`

            const input = {
                firstName: SIGNUP_FORM.value.FIRSTNAME,
                lastName: SIGNUP_FORM.value.LASTNAME,
                email: SIGNUP_FORM.value.EMAIL,
                telephone: SIGNUP_FORM.value.TELEPHONE,
                companyName: SIGNUP_FORM.value.COMPANY_NAME,
                cocNumber: SIGNUP_FORM.value.COMPANY_NUMBER,
                companyPostalcode: SIGNUP_FORM.value.POSTCODE,
                companyStreet: SIGNUP_FORM.value.STREET_NAME,
                companyStreetNumber: SIGNUP_FORM.value.HOUSE_NUMBER,
                city: SIGNUP_FORM.value.CITY,
                reCaptchaToken: token,
            }

            // signup api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
                loading.value = false
            } catch (err) {
                loading.value = false
                toast.error(t('SIGNUP_ERROR_MESSAGE', language.value))
                throw Error('Error while saving sign up data')
            }
            if (result.data.signUp && result.data.signUp.status) {
                successInviteSent.value = true
                // if user is already exists in a organisation directly navigate to Login
                if (result.data.signUp.user && result.data.signUp.user.organisations.length > 1) {
                    // display existing user message when navigating to Login page
                    router.push({ name: 'Login', params: { existingUser: 'true' } })
                }
                toast.success(t('SIGNUP_SUCESS_MESSAGE', language.value))
            } else {
                successInviteSent.value = false
                if (result.data.signUp && result.data.signUp.errorCode.value) {
                    // read backend error
                    errors.value.serverError = true
                    backendErrorMessage.value = utils.getErrorMessage(
                        result.data.signUp.errorCode.value
                    )
                } else {
                    toast.error(t('SIGNUP_ERROR_MESSAGE', language.value))
                }
            }
        }

        async function submitSignUp() {
            // validate login form
            validateForm()
            // check for validation errors
            if (utils.hasErrors(errors.value)) {
                return
            }
            const token = await this.recaptcha()
            saveUserSignupData(token)
        }

        const goToTC = () => {
            router.push({ name: 'TermsAndConditions' })
            // store data to keep them fillted when navigating back
            SET_SIGNUP_DATA(SIGNUP_FORM.value)
        }

        const goToPP = () => {
            router.push({ name: 'PrivacyPolicy' })
            // store data to keep them fillted when navigating back
            SET_SIGNUP_DATA(SIGNUP_FORM.value)
        }
        return {
            t,
            language,
            SIGNUP_FORM,
            errors,
            submitSignUp,
            loading,
            successInviteSent,
            goToTC,
            signupData,
            goToPP,
            backendErrorMessage,
            utils,
        }
    },
})

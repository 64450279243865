



































































































import router from '@/router'
import variables from '@/shared/variables'
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        // get logged in user info
        const loggedInUser: { user: { firstName; lastName; organisations } } = utils.ls.get(
            variables.LOCAL_STORAGE_ITEMS.USER,
            {
                decrypt: true,
            }
        )

        const fullName = ref('')
        const organisationName = ref('')
        const wizardTitle = ref('')
        const wizardSubText = ref('')
        const firstName = ref('')
        // get loggedin user full name
        const getFullName = () => {
            firstName.value = loggedInUser && loggedInUser.user.firstName
            fullName.value =
                (loggedInUser && loggedInUser.user.firstName) +
                ' ' +
                (loggedInUser && loggedInUser.user.lastName)
        }
        // get logged in user organisation
        const getOrganisationName = () => {
            const organisations = ( loggedInUser && loggedInUser.user.organisations )
            const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
            if(organisations)
            // https://stekz.sentry.io/issues/3872847261/?project=6370116&referrer=slack
            // Return empty string when the org that is being looked up does not exist
                organisationName.value = organisations.find(
                    (val) => val.id === organisationId
                )?.name ?? ''
        }

        watch(
            () => router.app.$route,
            () => {
                // change title and subtitle based on page
                if (router.currentRoute.name === 'WizardPage1') {
                    wizardTitle.value = 'WIZARD_LEFT_PAGE1_TITLE'
                    wizardSubText.value = 'WIZARD_LEFT_PAGE1_SUB_TEXT'
                } else {
                    wizardTitle.value = 'WIZARD_LEFT_PAGE2_TITLE'
                    wizardSubText.value = 'WIZARD_LEFT_PAGE2_SUB_TEXT'
                }
            },
            {
                immediate: true,
            }
        )
        onMounted(() => {
            getFullName()
            getOrganisationName()
        })
        return {
            t,
            language,
            fullName,
            organisationName,
            wizardTitle,
            wizardSubText,
            firstName,
            currentRoute: router.currentRoute.name,
        }
    },
})

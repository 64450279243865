






















import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { startAuthFlow } from '@/shared/services/single-sign-on'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import router from '@/router'
export default defineComponent({
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const authFlowFailed = ref(false)
        onMounted(() =>
            startAuthFlow(router.app.$route.params?.flowName).catch(
                () => (authFlowFailed.value = true)
            )
        )

        return {
            t,
            language,
            authFlowFailed,
        }
    },
})








































import { defineComponent } from '@vue/composition-api'
import { useState } from '../mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
export default defineComponent({
    name: 'Confirmation',
    props: {
        displaySaveConfirmation: {
            type: Boolean,
        },
        message: {
            type: String
        },
        icon: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        // No button event
        const closeConfirmation = () => {
            emit('close-confirmation')
        }
        // Yes button event
        const successConfirmation = () => {
            emit('success-confirm')
        }

        return {
            closeConfirmation,
            successConfirmation,
            t,
            language,
        }
    },
})

export interface InputLengthValidatorOptions {
    maxCharacters?: number;
}

export default class InputLengthValidator {
    private editor: any
    private maxCharacters: number

    constructor(editor: any) {
        this.editor = editor
        const options: InputLengthValidatorOptions = editor.config.get('InputLengthValidator') || {}
        this.maxCharacters = options.maxCharacters !== undefined ? options.maxCharacters : Infinity
    }

    public init(): void {
        const editor = this.editor

        editor.model.document.on('change:data', () => {
            const content: string = editor.getData()
            if (content.length > this.maxCharacters) {
                const truncatedContent: string = content.substring(0, this.maxCharacters)

                editor.model.change(() => {
                    editor.setData(truncatedContent)
                })
            }
        })
    }
}

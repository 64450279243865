var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
        'menu-section': true, 
        'active': _vm.section.active
    }, _obj[("level-" + _vm.level)] = true, _obj )},[_c('a',{staticClass:"menu-section-header",on:{"click":_vm.handleHeaderClick}},[(_vm.section.icon.name)?_c('i',{class:( _obj$1 = {
            'fas': true,
            'p-mr-2': true, // Right margin 0.5rem
            'fa-fw': true
        }, _obj$1[_vm.section.icon.name] = true, _obj$1 )}):(_vm.section.icon.image)?_c('img',{staticClass:"p-mr-2 icon-image",attrs:{"src":_vm.section.active ? _vm.section.icon.image.active : _vm.section.icon.image.inactive}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.section.header))])]),_c('transition',{attrs:{"name":"p-toggleable-content"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpened),expression:"isOpened"}],staticClass:"p-toggleable-content"},[_c('div',{staticClass:"menu-section-contents"},[_vm._l((_vm.section.subSections),function(subSection,index){return _c('AccordionMenuSection',{key:("subsection-" + index),attrs:{"section":subSection,"level":_vm.level + 1}})}),(_vm.section.items.length)?_c('Menu',{attrs:{"model":_vm.menuModel}}):_vm._e()],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
const CLIENT_CONFIGURATION_DETAILS = `
    id
    twoFactorRequired
    created
    contactName
    contactEmail
    contactTelephone
    companyName
    cocNumber
    companyStreet
    companyStreetNumber
    companyPostalcode
    companyCity
    companyCountry
    companyTelephone
    financeStreet
    financeStreetNumber
    financePostalcode
    financeCity
    financeCountry
    financeEmail
    interfaceLanguage
    riskToleranceGreen
    riskToleranceOrange
    riskToleranceRed
    dataShare
    companySector{
        sector {
            id
            name
            key
        }
        subSector{
            id
            name
            key
        }
    }
    selectedPriorityCategories{
        id
        name
    }
    priorityCategoryActivated
    companySize
    selectedMsrNorms{
        id
        name
    }
    selectedNorms{
        id
        name
        hasPriorityCategory
        normType
        normTypeOther
        created_by{
            username
        }
    }
    selectedRiskSets {
        nameInfo
        name
        longName
        id
    }
    availableRiskSets {
        nameInfo
        name
        longName
        id
    }
    isEmailReportScheduled`
    

const USERS = `
    id
    username
    firstName
    lastName
    email
    lastLogin
    twoFactorRequired
    twoFactorActivated
    organisations{
        id
        name
        startDate
        twoFactorRequired
        wizardCompleted
        endDate
        roles{
            id
            name
        }
        interfaceLanguage
    }
    `

const ROLES = `
    id
    name
    `
const PRIORITY_CATEGORY = `
    id
    name
    `

const UPDATE_USER_RETURN = `
    user{
        organisations{
            id
            roles{
                id
                name
            }
        }
    }
    `
export default { CLIENT_CONFIGURATION_DETAILS, USERS, ROLES, PRIORITY_CATEGORY, UPDATE_USER_RETURN }



























































import { defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import maturityLevelData from '@/assets/data/control-assessment-data.json'
import dashboardQuery from '@/shared/queries/dashboardQueries'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import router from '@/router'

export default defineComponent({
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const maturityLevels = ref([])
        const maturityAssessmentCounts = ref([])
        const chartData = ref({
            labels: [],
            datasets: [],
        })
        const { selectedNorms } = useState(['selectedNorms'])
        const SELECTED_NORM = ref(null)

        Chart.register(ChartDataLabels)
        Chart.register(...registerables)

        // navigate to controls with maturity level
        const navigateToControls = (barLabel: string) => {
            const selectedMaturity = maturityLevels.value.find(
                (maturity) => maturity.value === barLabel
            )
            if (!selectedMaturity) {
                return
            }
            /* navigate to controls list with maturity score and selected norm to get corresponding control list (filtered controls by 
            maturity score and norm) */
            router.push({
                name: 'Controls',
                query: {
                    maturity: String(selectedMaturity.score),
                    norm: String(SELECTED_NORM.value),
                },
            })
        }
        // chart configurations
        const chartOptions = {
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                },
            },
            scale: {
                ticks: {
                    // y axis steps sizes
                    stepSize: 1,
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    color: '#fff',
                    font: {
                        size: 18,
                    },
                },
            },
            // on click function on bar
            onClick: (e, clickedElements) => {
                if (clickedElements.length === 0) return
                // get the dataIndex
                const { dataIndex } = clickedElements[0].element.$context
                // find the data of the selected bar
                const barLabel = e.chart.data.labels[dataIndex]
                navigateToControls(barLabel)
            },
            onHover: (e: { native: { target } }, barElements: string | any[]) => {
                // if user is mouse hovering on bars
                e.native.target.style.cursor = barElements.length > 0 ? 'pointer' : ''
            },
        }
        const totalAssessments = ref(0)
        const totalControls = ref(0)
        const loading = ref(false)

        // set maturity level dropdown values
        const setMaturityLevels = () => {
            maturityLevels.value = []
            maturityLevelData.MATURITY.map((maturity) => {
                maturityLevels.value.push({
                    score: maturity.SCORE,
                    value: t(maturity.VALUE, language.value),
                })
            })
        }

        // get maturity level explanation per scores
        const getMaturityLevel = (score) => {
            return maturityLevels.value.find((val) => val.score === score).value
        }

        // set doughnut chart data
        const setDoughnutChartData = () => {
            const labels = maturityLevels.value.map((val) => val.value)
            const data = maturityAssessmentCounts.value.map((val) => val.assessmentCount)

            // when there are any assessment generate doughnut chart with response data
            chartData.value = {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        /*previsouly below colors were read by `colorSets`. Kept one color as per mockups. 
                            Could be change with client's comments. */
                        backgroundColor: ['#0080ff'],
                        hoverBackgroundColor: ['#0080ff'],
                    },
                ],
            }

            /* Creating canvas manually to update the canvas in DOM elements directly when chart is getting update. */
            if (document.querySelector('#chartReport'))
                document.querySelector('#chartReport').innerHTML =
                    '<canvas id="myBarChart" style="max-height: 320px"></canvas>'

            // Generate chart using canvas ID
            const ctx = document.getElementById('myBarChart') as HTMLCanvasElement

            new Chart(ctx, {
                type: 'bar',
                data: chartData.value,
                options: chartOptions,
            })
        }

        // get maturity overview info
        const getMaturityOverviewDetails = async () => {
            maturityAssessmentCounts.value = []
            loading.value = true
            const maturityOverviewQuery = `
                query maturityOverview($normId: Int){
                    maturityOverview(normId: $normId){
                        ${dashboardQuery.MATURITY_OVERVIEW}
                    }
                }
            `
            const variables = {
                normId: SELECTED_NORM.value,
            }
            let result
            try {
                result = await apolloClient.getGraphqlData(maturityOverviewQuery, variables)
                loading.value = false
            } catch {
                loading.value = false
                throw Error('Error while retrieving maturity overview data')
            }

            if (result) {
                const maturityCountsCopy = result.data.maturityOverview
                // total count of assessments which maturities are in use
                totalAssessments.value = maturityCountsCopy.totalAssessments
                // total count of controls which are belongs to the selected norm
                totalControls.value = maturityCountsCopy.totalControls
                // add colors for scores
                maturityCountsCopy.items.map((maturity) => {
                    maturityAssessmentCounts.value.push(maturity)
                })
            }
        }

        const normHandler = async () => {
            await getMaturityOverviewDetails()
            setDoughnutChartData()
        }

        // watch for language chaneges and update labels
        watch(language, () => {
            setMaturityLevels()
        })

        // watch for selected norms data change
        watch(
            () => selectedNorms,
            () => {
                setMaturityLevels()
                if (selectedNorms.value.length > 0) {
                    // set first norm as by default value for selected norms and generate chart
                    SELECTED_NORM.value = selectedNorms.value[0].id
                    normHandler()
                }
            },
            {
                immediate: true,
            }
        )

        return {
            t,
            language,
            getMaturityLevel,
            maturityAssessmentCounts,
            chartData,
            chartOptions,
            totalAssessments,
            loading,
            selectedNorms,
            SELECTED_NORM,
            getMaturityOverviewDetails,
            normHandler,
            totalControls,
        }
    },
})

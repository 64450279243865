





















import { defineComponent } from '@vue/composition-api'
import DashboardHeatmap from './org-performance/DashboardHeatmap.vue'
import MaturityOverview from './org-performance/MaturityOverview.vue'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    props: {
        organisationName: String
    },
    components: {
        DashboardHeatmap,
        MaturityOverview,
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])

        return {
            t,
            language,
            utils,
        }
    },
})






































































































import { defineComponent, watch, ref, PropType, inject, Ref, computed } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel';
import { MenuSectionState } from '@/dto/leftMenuDTO';
export default defineComponent({
    name: "AccordionMenuSection",
    props: {
        section: {
            required: true,
            type: Object as PropType<MenuSectionState>
        },
        menuOffsetLeft: {default: '2.5rem'},
        menuOffsetTop: {default: '-2.5rem'},
        // If sectionIdentifier is not provided, section header will be used as identifier.
        sectionIdentifier: {
            type: String
        }

    },
    setup(props) {
        const { t } = useI18n();
        const { language } = useState(["language"]);
        const menuSectionPanel = ref(null)
        const iconRef = ref(null)
        const menuTop = ref(0)
        const menuLeft = ref(0)
        const openSubmenu = ref(null)
        const currentSectionIdentifier = ref(props.sectionIdentifier ?? props.section.header)
        const {LEFT_MENU_OPEN_SECTION, setOpenSection, closeSection}: {
            LEFT_MENU_OPEN_SECTION: Ref<string>;
            setOpenSection: (newSection: string) => void;
            closeSection: (newSection: string) => void;
        } = inject('LEFT_MENU_OPEN_SECTION')

        const isOpenSection = computed(
            () => currentSectionIdentifier.value === LEFT_MENU_OPEN_SECTION.value
        )
        // When a section refers to a specific page (such as Dashboard), we can open it
        // by clicking the icon directly. Otherwise, the user should use the overlay menu.
        const handleIconClick = (event: MouseEvent, section: MenuSectionState) => {
            event.preventDefault(); event.stopPropagation(); 
            if(section.isSingleItemSection) {
                section.items[0].command()
            }
        }

        // Dispatch a dummy event whose handler will toggle the overlay panel
        // in the correct location
        const openMenuAtAnchor = () => iconRef.value?.dispatchEvent(
            new CustomEvent("open-menu-at-anchor", {
                bubbles: false,
            })
        )
        const activateMenu = () => {
            // Sync with shared state to allow other menus to close
            setOpenSection(currentSectionIdentifier.value)
            openMenuAtAnchor()
        }


        const hideMenu = () => {
            menuSectionPanel?.value?.hide?.()
            closeSection(currentSectionIdentifier.value)
        }


        watch(
            LEFT_MENU_OPEN_SECTION,
            (newOpenSection) => {
                if (newOpenSection === currentSectionIdentifier.value && !menuSectionPanel.value?.visible) {
                    openMenuAtAnchor()
                }
                if (newOpenSection !== currentSectionIdentifier.value){
                    if (menuSectionPanel.value?.visible) {
                        menuSectionPanel?.value?.hide?.()
                    }
                    openSubmenu.value = null
                }
            }
        )
        watch(
            () => menuSectionPanel.value?.visible,
            (overlayVisible: boolean) => {
                if (isOpenSection && !overlayVisible) {
                    // The overlay has closed (e.g. due to user click somewhere else on the page)
                    // Sync with the menu state to remove active styling from this section
                    closeSection(currentSectionIdentifier.value)
                    // Reset open subsection
                    openSubmenu.value = null
                }
            }
        )
        return {
            t,
            language,
            menuSectionPanel,
            iconRef,
            menuTop,
            menuLeft,
            activateMenu,
            hideMenu,
            openSubmenu,
            handleIconClick,
            isOpenSection,
        };

    },
    components: { OverlayPanel }
})

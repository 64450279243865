import apolloClient from '@/shared/services/ApolloCLientAPI'
import variables from '../variables'
import query from '@/shared/queries/generalQueries'
import { useActions } from '../mixins/helpers'
import utils from '../mixins/utils'
import { AuthenticationResponseDTO } from '@/dto/backend-response/usersDTO'
import { resetLoadedLanguage } from './I18nServices'
// Load from localStorage where it was set by the auth mechanism upon login
const loadOrgsWithRoles = () => {
    const loggedInUser: { user: { organisations } } = utils.ls.get(
        variables.LOCAL_STORAGE_ITEMS.USER,
        {
            decrypt: true,
        }
    )
    const allOrganisationsForUser = loggedInUser && loggedInUser.user.organisations
    return allOrganisationsForUser
}

// Set user data
const setUserData = async (loginToOrganisation?: number) => {
    const { SET_ROLE } = useActions(['SET_ROLE'])
    const loggedUserQuery = `query{
            authorizationForLoggedInUser{
                ${query.AUTHERNTICATION_DETAILS}
            }
        }`
    let result
    try {
        result = await apolloClient.getGraphqlData(loggedUserQuery)
    } catch {
        throw Error('Error while retrieving autherization data for logged in user')
    }

    if (result.data.authorizationForLoggedInUser) {
        // Convert full json to string and store
        utils.ls.set(variables.LOCAL_STORAGE_ITEMS.USER, result.data.authorizationForLoggedInUser)

        /* store below details as when user has only one organisation or the org
            to log in to was specified as a parameter.
            When they are multiple organisations these data will be save based on 
            selected organisation from dropdown (in OrganisationSelect.vue)*/
        const organisationID =
            result.data.authorizationForLoggedInUser.user.organisations.length === 1
                ? result.data.authorizationForLoggedInUser.user.organisations[0].id
                : loginToOrganisation
        const selectedOrganisation =
            result.data.authorizationForLoggedInUser.user.organisations.find(
                (org) => org.id === organisationID
            )
        if (selectedOrganisation) {
            // store organisation id
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, selectedOrganisation.id)

            // role id
            utils.ls.set(
                variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE,
                selectedOrganisation.roles[0].id
            )
            // role name
            utils.ls.set(
                variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE_NAME,
                selectedOrganisation.roles[0].name
            )
            // set selected role name to store
            SET_ROLE(selectedOrganisation.roles[0].name)
            // set roles of users on localStorage
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.ROLES, selectedOrganisation.roles)
            // This has a side effect to set the wizard completion status
            // in localstorate, which is used in the route navigation guards
            utils.wizardCompletionCheck()
        }
    }
}

// refresh token
const refreshToken = async (): Promise<boolean> => {
    const authToken: string = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.AUTH_TOKEN, {
        decrypt: true,
    })

    const mutationQuery = `mutation{
            refreshToken(token:"${authToken && authToken.replace('Token ', '')}"){
              token
            }
        }`
    let result
    try {
        result = await apolloClient.updateGraphqlData(mutationQuery, null)
    } catch (error) {
        return false
    }
    if (result.data.refreshToken.token) {
        utils.ls.set(
            variables.LOCAL_STORAGE_ITEMS.AUTH_TOKEN,
            'Token ' + result.data.refreshToken.token,
            { encrypt: true }
        )
        return true
    } else return false
}

export const setAccessToken = async (authenticationResponse: AuthenticationResponseDTO) => {
    if (authenticationResponse.token) {
        // save token to local-storage
        utils.ls.set(
            variables.LOCAL_STORAGE_ITEMS.AUTH_TOKEN,
            'Token ' + authenticationResponse.token,
            { encrypt: true }
        )
        // Collect user data and set organisation if specified in the response
        await setUserData(authenticationResponse?.loginOrganisation?.id)

        // if 2FA not required:
        if (!authenticationResponse?.twoFactorRequired) {
            // set 2FA check status as passed
            utils.ls.set(
                variables.LOCAL_STORAGE_ITEMS.TWO_FACTOR_PASS,
                !authenticationResponse.twoFactorRequired ? 'validated' : ''
            )
            // As the first token obtained, has shorter expiration,
            // need to get newer token with longer expiration.
            await refreshToken()
        }
        return {
            status: true,
            twoFactorActivated: authenticationResponse?.twoFactor,
            twoFactorUrl: authenticationResponse?.twoFactorUrl,
            twoFactorRequired: authenticationResponse?.twoFactorRequired,
        }
    }
}

// Set authentication at login
const setAuthentication = async (username: string, password: string): Promise<object> => {
    const mutationQuery = `mutation {
            tokenAuth(username: "${username}", password: "${password}") {
                token
                twoFactor
                twoFactorUrl
                twoFactorRequired
            }
        }`
    let result
    try {
        result = await apolloClient.updateGraphqlData(mutationQuery, null)
    } catch {
        throw Error('Error while retrieving tokenAuth data')
    }

    if (result?.data?.tokenAuth?.token) {
        return setAccessToken(result.data.tokenAuth as AuthenticationResponseDTO)
    }

    return { status: false }
}

// Clear authentication at logout
const clearAuthentication = () => {
    if (utils.ls.get(variables.LOCAL_STORAGE_ITEMS.AUTH_TOKEN, { decrypt: true })) {
        // clear all data on localStorage
        localStorage.clear()
        //clear loaded language
        resetLoadedLanguage()
    }
}

// Verify two factor authentication code
const verifyTwoFactorAuthentication = async (token) => {
    // TODO: Add token to store for validations, as to prevent
    //       localStorage data manipulation to bypass two factor.

    // proceed only if auth token is available
    const authToken = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.AUTH_TOKEN, { decrypt: true })
    if (!authToken) {
        return
    }
    const mutationQuery = `
        mutation {
            twoFactorValidation(token:"${token}"){
                status
                token
                error
            }
        }
        `
    let result
    try {
        result = await apolloClient.updateGraphqlData(mutationQuery, null)
    } catch {
        throw Error('Error while retrieving twofactor validation data')
    }

    if (result.data.twoFactorValidation.status) {
        utils.ls.set(variables.LOCAL_STORAGE_ITEMS.TWO_FACTOR_PASS, 'validated')
        // save token to local-storage
        utils.ls.set(
            variables.LOCAL_STORAGE_ITEMS.AUTH_TOKEN,
            'Token ' + result.data.twoFactorValidation.token,
            { encrypt: true }
        )
        // As the first token obtained has shorter expiration,
        // need to get newer token with longer expiration.
        if (!(await refreshToken())) {
            return
        }
        return {
            status: result.data.twoFactorValidation.status,
            error: result.data.twoFactorValidation.error,
        }
    }

    return { status: false }
}

// token expiration check
const verifyToken = async () => {
    const token: string = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.AUTH_TOKEN, { decrypt: true })

    const mutationQuery = `
        mutation{
            verifyToken(token:"${token.replace('Token ', '')}"){
                valid
            }
        }
        `
    let result
    try {
        result = await apolloClient.updateGraphqlData(mutationQuery, null)
    } catch (error) {
        return false
    }
    if (result) {
        return result.data.verifyToken.valid
    }
}

export default {
    setAuthentication,
    clearAuthentication,
    refreshToken,
    verifyTwoFactorAuthentication,
    verifyToken,
    loadOrgsWithRoles,
}

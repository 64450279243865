// Common api service
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client/core'
import { gql } from '@apollo/client/core'
import fetch from 'cross-fetch'
import { onError } from '@apollo/client/link/error'
import variables from '@/shared/variables'
import utils from '../mixins/utils'

import { useState } from '../mixins/helpers'
import { store } from '@/store/index'
import Authentication from '@/shared/services/Authentication'
import { ActionTypes } from '@/store/action-types'

interface PeriumAuthResponseExtension {
    status: 'INVALID_REQUEST' | 'NOT_AUTHORIZED';
    message: string;
}

const httpLink = new HttpLink({
    uri: process.env.VUE_APP_BACKEND_GRAPHQL_URI,
    fetch,
})

const successLink = new ApolloLink((operation, forward) => {

    return forward(operation).map((data) => {

        store.dispatch(
            ActionTypes.SET_NETWORK_ERROR, false
        )
        return data;
      
      });
})
// Throw query errors
const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if ('result' in networkError && networkError?.statusCode == 403) {
        // If something is wrong with the authorization headers, backend passes info about
        // what went wrong in this obejct
        const periumAuth: PeriumAuthResponseExtension | null = networkError.result?.extensions?.periumAuth
        if (periumAuth.status == "INVALID_REQUEST") {
            // We may have ended up with invalid authorization data in localstorage
            // To recover, clear the authentication data and refresh the page
            // This will redirect the user to the login page, which is better than staying
            // in an invalid state
            Authentication.clearAuthentication()
            window.location.reload()
        }
        else {
            throw Error(`Not authorized for query: ${periumAuth.message}`)
        }
    }
    if (graphQLErrors && graphQLErrors.length > 0) {
        graphQLErrors.map((errorMsg) => console.log('graphQLError', errorMsg.message))
    }

    console.log('networkError', networkError && networkError.message)
    store.dispatch(
        ActionTypes.SET_NETWORK_ERROR, true
    )
})

// add the authorization to the headers
const authMiddleware = new ApolloLink((operation, forward) => {
    const authStatus = utils.getStoredAuthStatus()
    const role = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.SELECTED_ROLE, { decrypt: true })
    const organisation = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, { decrypt: true })
    const authorization = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.AUTH_TOKEN, { decrypt: true })
    operation.setContext(({ headers = {} }) => {
        const updatedHeaders = {
            ...headers,
            'Accept-Language': useState(['language']).language.value
        }
        // Authorization header is set when fully authorized or when in the login process
        if (authStatus == 'AUTHORIZED' || authStatus == 'ONLY_USER_AUTHENTICATED' || authStatus == 'TWO_FACTOR_PENDING') {
            updatedHeaders['Authorization'] = authorization
        }
        // Organisation and role are only set when fully authorized
        if (authStatus == 'AUTHORIZED') {
            updatedHeaders['role'] = role
            updatedHeaders['organisation'] = organisation
        }

        return {headers: updatedHeaders}
    })

    return forward(operation)
})

const apolloClient = new ApolloClient({
    link: ApolloLink.from([successLink, errorLink, authMiddleware, httpLink]),
    cache: new InMemoryCache({
        resultCaching: false,
    }),
})

// get data
const getGraphqlData = async (reqQuery: string, variables = {}) => {
    const query = gql`
        ${reqQuery}
    `
    try {
        return await apolloClient.query({ query, variables, fetchPolicy: 'no-cache' })
    } catch (err) {
        console.log('Error in get API', err)
        throw err
    }
}

// update data
const updateGraphqlData = async (mutationQuery: string, input: {}) => {
    try {
        return await apolloClient.mutate({
            mutation: gql`
                ${mutationQuery}
            `,
            // Parameters
            variables: {
                input,
            },
        })
    } catch (err) {
        console.log('Error in update API', err)
        throw err
    }
}

export default { getGraphqlData, updateGraphqlData, apolloClient}

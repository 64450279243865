// status of assessments/ action plan
export enum Status {
    created = 1,
    inprogress = 2,
    submitted = 3,
    approved = 4,
    declined = 5,
    ready = 6 // only applicable for Action Plans
}

// intervals of assessments/ action plan
export enum Interval {
    day = 1,
    week = 2,
    month = 3,
    quarter = 4,
    year = 5
}

export enum UserRole {
    ADMIN = 'ADMIN',
    EMPLOYEE = 'EMPLOYEE',
    VALIDATOR = 'VALIDATOR',
    CONSULTANT = 'CONSULTANT',
    SUPER_ADMIN = 'SUPER_ADMIN',
    PERIUM_ADMIN = 'PERIUM_ADMIN',
    DEVELOPER_ADMIN = 'DEVELOPER_ADMIN'
}


export enum YesNo {
    YES = 'YES',
    NO = 'NO',
    NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export enum FrequencyUnit{
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    SEMI_ANUALLY = 'SEMI_ANNUALLY',
    ANNUALLY = 'ANNUALLY',
}
<template>
    <div class="login-components-wrapper">
        <div class="login-section">
            <div class="login-router-wrapper">
                <router-view></router-view>
            </div>
            <!-- perium logo -->
            <div class="perium-logo-wrapper">
                <img class="logo" alt="Perium logo" src="@/assets/images/logo.svg" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
.login-components-wrapper {
    .login-section {
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        .login-router-wrapper {
            box-shadow: 0 2px 12px 0 rgba(98, 117, 134, 0.1);
        }
        .perium-logo-wrapper {
            margin-top: 2rem;
            text-align: center;
        }
    }
}
</style>

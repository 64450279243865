










import { defineComponent, PropType } from '@vue/composition-api'
import AccordionMenuSection from './AccordionMenuSection.vue';
import type { MenuSectionState } from '@/dto/leftMenuDTO';

export default defineComponent({
    name: 'ExpandedLeftMenu',
    components: {
        AccordionMenuSection
    },
    props: {
        menuState: {
            required: true,
            type: Array as PropType<Array<MenuSectionState>>
        },
        menuOpen: {type: Boolean}
    },
});

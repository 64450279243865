import apolloClient from '@/shared/services/ApolloCLientAPI'
import { OAuth2Client, generateCodeVerifier, OAuth2Error} from '@badgateway/oauth2-client'
import {setAccessToken } from '@/shared/services/Authentication'
import { AuthenticationResponseDTO } from '@/dto/backend-response/usersDTO'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import { GET_AUTH_FLOW_PARAMETERS, GET_SSO_TOKEN } from '@/shared/queries/ssoQueries'
export const authenticateFlowUser = async (flowName: string, idToken: string) => {
    const result = await apolloClient.apolloClient.mutate({
        mutation: GET_SSO_TOKEN,
        variables: {
            idToken,
            flowName,
        }
    })
    if (!result.data.getSSOFlowToken?.status) {
        return {
            status: false,
            errorMessage: `Error: ${result?.data?.getSSOFlowToken?.error ?? "invalid token"}`,
            errorKey: utils.getErrorMessage(result?.data?.getSSOFlowToken?.errorCode?.value),
        }
    }
    const accessTokenResult = setAccessToken(result.data.getSSOFlowToken as AuthenticationResponseDTO)
    return accessTokenResult
}


/**
 * Get auth flow parameters from backend
 * 
 * @param flowName the unique name for this auth flow in the backend
 * @returns 
 */
export const getAuthFlowParameters = async (flowName: string) => {
    const result = await apolloClient.apolloClient.query({
        query: GET_AUTH_FLOW_PARAMETERS,
            variables: {
                flowName,
            }
        })
    if (result?.data?.getAuthFlowParameters?.parameters) {
        return {
            // Construct redirect URI that uses the same origin in every environment
            redirectUri: new URL('/oauth2/callback', window.location.origin),
            ...result.data.getAuthFlowParameters.parameters
        }
    }
    throw Error("Error while retrieving flow parameters...")
}

const getFlowOAuth2Client = (flowParameters): OAuth2Client => {
    return new OAuth2Client({

        // The base URI of your OAuth2 server
        server: flowParameters.server,
      
        // OAuth2 client id
        clientId: flowParameters.clientId,
      
        // OAuth2 Metadata discovery endpoint
        // This is dependent on the tenant ID
        // Other endpoints are discovered through this
        discoveryEndpoint: flowParameters.discoveryEndpoint,
    });
}

export const startAuthFlow = async (flowName: string) => {
    const flowParameters = await getAuthFlowParameters(flowName)
    const client = getFlowOAuth2Client(flowParameters)

    const verifier = await generateCodeVerifier()

    localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.SSO_VERIFIER, verifier)
    
    const authorizeUrlParams = {
        redirectUri: flowParameters.redirectUri,
        scope: ['openid', 'email'],
        codeVerifier: verifier,
        state: flowName,
    }
    // Optionally override parameters based on settings in backend
    if (flowParameters['overrideUserInteractionPrompt']) {
        authorizeUrlParams['extraParams'] = {
            // Change the type of user interaction prompt
            // https://learn.microsoft.com/en-us/entra/identity-platform/v2-oauth2-auth-code-flow#request-an-authorization-code
            prompt: flowParameters['overrideUserInteractionPrompt']
        }
    }

    if (flowParameters['authCodeScopes']) {
        // Change the scopes requested while getting an authorization code
        // Note: this could set authorizeUrlParams['scope'] to [] which is a valid option
        authorizeUrlParams['scope'] = flowParameters['authCodeScopes']
    }

    const tokenURI = await client.authorizationCode.getAuthorizeUri(authorizeUrlParams)
    window.location.assign(tokenURI)
}

export const completeAuthFlow = async () => {
    // The redirect address will contian the same "state" value we set in startAuthFlow
    const urlParams = new URLSearchParams(window.location.search);
    let client: OAuth2Client
    let flowParameters
    if (urlParams.get('state')) {
        const flowName = urlParams.get('state')
        try {
            flowParameters = await getAuthFlowParameters(flowName)
            client = getFlowOAuth2Client(flowParameters)
        }
        catch {
            return {
                status: false,
                errorMessage: `Could not get OAuth2 configuration for flow ${flowName}.`
            }
        }
    } else {
        return {
            status: false,
            errorMessage: `No flow name in callback url.`
        }
    }
    let authCode: string
    try {
        const validateResult = await client.authorizationCode.validateResponse(
            window.location.href,
            {}
        )
        authCode = validateResult.code
    }
    catch {
        return {
            status: false,
            errorMessage: `Authorization code is invalid.`
        }
    }
    let tokenResponse: {
        id_token?: string;
    }
    try {
        const verifier = localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.SSO_VERIFIER)
        tokenResponse = await client.request("tokenEndpoint", {
            'grant_type': "authorization_code",
            'code': authCode,
            'redirect_uri': flowParameters.redirectUri,
            'code_verifier': verifier,
        }) as {id_token?: string}
    }
    catch (err){
        if (err instanceof OAuth2Error) {
            return {
                status: false,
                errorMessage: `Could not redeem token: ${err.message}.`
            }
        }
        return {
            status: false,
            errorMessage: `Could not redeem token: ${err}.`
        }
    }
    try {
        return authenticateFlowUser(
            urlParams.get('state'),
            tokenResponse['id_token']
        )
    }
    
    catch (err) {
        return {
            status: false,
            errorMessage: `Error while validating identity token: ${err}.`,
        }
    }
}
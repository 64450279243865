import type { MenuSectionSpec } from "@/dto/leftMenuDTO";
import { UserRole } from "@/shared/enum/general-enum";

export const menu: Array<MenuSectionSpec> = [
    {
        headerKey: 'LEFT_MENU_HEADER_DASHBOARD',
        icon: {
            name: 'fa-tachometer-alt',
        },
        isSingleItemSection: true,
        items: [
            {
                headerKey: 'LEFT_MENU_HEADER_DASHBOARD',
                location: { name: 'Dashboard' }
            }
        ],
    },
    {
        headerKey: 'LEFT_MENU_MANAGEMENT_SYSTEM',
        icon: {
            name: 'fa-tasks',
        },
        items: [{
            headerKey: 'LEFT_MENU_MANAGEMENT_SYSTEM_REQUIREMENTS',
            location: { name: 'MSRequirements' },
        }],
        isSingleItemSection: true,
    },
    {
        headerKey: 'LEFT_MENU_RISKS',
        icon: {
            image: {
                active: require('@/assets/images/icon-risicoanalyse-black.svg'),
                inactive: require('@/assets/images/icon-risicoanalyse.svg')
            }
        },
        items: [{
            headerKey: 'LEFT_MENU_RISKS',
            location: { name: 'Risks'}
        },],
        isSingleItemSection: true,
    },
    {
        headerKey: 'LEFT_MENU_CONTROLS',
        icon: {
            name: 'fa-eye',
        },
        items: [
            {
                headerKey: 'LEFT_MENU_CONTROLS',
                location: { name: 'Controls' },
            },
        ],
        isSingleItemSection: true,
    },
    {
        headerKey: 'LEFT_MENU_ASSETS',
        icon: {
            name: 'fa-box'
        },
        items: [
            {
                headerKey: 'LEFT_MENU_ASSETS',
                location: { name: 'Assets' },
            },
        ],
        isSingleItemSection: true,
    },
    {
        headerKey: 'LEFT_MENU_HEADER_IMPROVE',
        icon: {
            name: 'fa-chart-line',
        },
        items: [
            {
                headerKey: 'LEFT_MENU_HEADER_ACTION_PLAN',
                location: { name: 'ImproveControls' },
            },
            {
                headerKey: 'LEFT_MENU_MANAGEMENT_SYSTEM_ACTIONPLAN',
                location: {
                    name: 'ImproveMSRs',
                },
            },
        ],
    },
    {
        headerKey: 'LEFT_MENU_TASK_REGISTER',
        icon: {
            name: 'fa-calendar-check',
        },
        items: [
            {
                headerKey: 'LEFT_MENU_TASK_REGISTER',
                location: {
                    name: 'TaskRegisterList',
                },
            },
        ],
        isSingleItemSection: true,
    },
    {
        headerKey: 'LEFT_MENU_OVERVIEWS',
        icon: {
            name: 'fa-book-open',
        },
        subSections: [
            {
                headerKey: 'LEFT_MENU_MANAGEMENT_SYSTEM',
                icon: {
                    name: 'fa-tasks',
                },
                items: [
                    {
                        headerKey: 'LEFT_MENU_MANAGEMENT_SYSTEM_ASSESSMENTS',
                        location: { name: 'MSRAssessments' },
                    },
                    {
                        headerKey: 'LEFT_MENU_MANAGEMENT_SYSTEM_ACTIONPLAN',
                        location: {
                            name: 'MSRActionPlans',
                        },
                    },
                ]
            },
            {
                headerKey: 'LEFT_MENU_HEADER_RISK_ANALYSIS',
                icon: {
                    image: {
                        active: require('@/assets/images/icon-risicoanalyse-black.svg'),
                        inactive: require('@/assets/images/icon-risicoanalyse.svg')
                    }
                },
                items: [
                    {
                        headerKey: 'LEFT_MENU_THREATS',
                        location: { name: 'Threats'},
                    },
                    {
                        headerKey: 'LEFT_MENU_VULNERABILITIES',
                        location: { name: 'Vulnerabilities'},
                    },

                    {
                        headerKey: 'LEFT_MENU_RISK_ASSESMENTS',
                        location: {
                            name: 'RiskAssessments',
                        }
                    },
                ],
            },
            {
                headerKey: 'LEFT_MENU_HEADER_RISK_MANAGEMENT',
                icon: {
                    name: 'fa-eye',
                },
                items: [
                    {
                        headerKey: 'LEFT_MENU_CONTROL_ASSESSMENTS',
                        location: {
                            name: 'ControlAssessments',
                        },
                    },
                    {
                        headerKey: 'LEFT_MENU_ASSET_CONTROL_ASSESSMENTS',
                        location: {
                            name: 'AssetControlAssessments',
                        },
                    },
                ],
            },
            {
                headerKey: 'LEFT_MENU_HEADER_VALIDATION',
                icon: {
                    name: 'fa-check-double',
                },
                items: [
                    {
                        headerKey: 'LEFT_MENU_VALIDATION_MANAGEMENT_SYSTEM_ASSESSMENTS',
                        location: {
                            name: 'ValidateMSRAssessments',
                        },
                    },
                    {
                        headerKey: 'LEFT_MENU_VALIDATION_MANAGEMENT_SYSTEM_ACTIONPLAN',
                        location: {
                            name: 'ValidateMSRActionPlans',
                        },
                    },
                    {
                        headerKey: 'LEFT_MENU_VALIDATION_RISK_ASSESMENTS',
                        location: {
                            name: 'ValidateRiskAssessments',
                        },
                    },
                    {
                        headerKey: 'LEFT_MENU_VALIDATION_CONTROL_ASSESSMENTS',
                        location: {
                            name: 'ValidateControlAssessments',
                        },
                    },
                    {
                        headerKey: 'LEFT_MENU_ASSET_CONTROL_ASSESSMENTS',
                        location: {
                            name: 'ValidateAssetControlAssessments',
                        },
                    },
                    {
                        headerKey: 'LEFT_MENU_ACTION_PLANS',
                        location: {
                            name: 'ValidateActionPlans',
                        },
                    },
                ],
            },
            {
                headerKey: 'LEFT_MENU_REPORT',
                icon: {
                    name: 'fa-file',
                },
                items: [
                    {
                        headerKey: 'LEFT_MENU_RELATIONS',
                        location: { name: 'Relations' },
                        // Temporarily hidden for normal users because it is very slow:
                        // https://gitlab.com/stekz/perium/backend/-/issues/597
                        visibleToRoles: [UserRole.DEVELOPER_ADMIN, UserRole.PERIUM_ADMIN, UserRole.SUPER_ADMIN],
                    },
                    {
                        headerKey: 'LEFT_MENU_CONTROL_REFERENCES',
                        location: { name: 'ControlReferences' },
                        visibleToRoles: [UserRole.DEVELOPER_ADMIN, UserRole.PERIUM_ADMIN, UserRole.SUPER_ADMIN],
                    },
                    {
                        headerKey: 'LEFT_MENU_AUDITTRAIL',
                        location: { name: 'AuditTrail' },
                    },
                ],
            },
        ]
    },
    {
        headerKey: 'LEFT_MENU_HELP',
        icon: {
            name: 'fa-question',
        },
        items: [
            {
                headerKey: 'LEFT_MENU_HELP',
                location: { name: 'Help' },
            },
        ],
        isSingleItemSection: true,
    },
    {
        headerKey: 'LEFT_MENU_HEADER_ORGANISATION',
        icon: {
            name: 'fa-cog',
        },
        items: [
            {
                headerKey: 'LEFT_MENU_CLIENT_CONFIGURATION',
                location: { name: 'ClientConfiguration' },
            },
            {
                headerKey: 'LEFT_MENU_SELECTED_NORMS',
                location: { name: 'Norms' },
            },
            {
                headerKey: 'LEFT_MENU_MANAGEMENT_SYSTEM_NORMS',
                location: { name: 'MSRNorms' },
            },
        ],
    }
]













































































































































































































































import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import query from '@/shared/queries/clientConfigQuery'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { UserRole, YesNo } from '@/shared/enum/general-enum'
import utils from '../mixins/utils'
import generalData from '@/assets/data/general-data.json'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '../mixins/helpers'
import variables from '../variables'
import moment from 'moment'

export default defineComponent({
    props: {
        userDetails: {
            type: Object,
        },
        editable: {
            type: Boolean,
        },
        type: {
            type: String,
        },
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const USERFORM = ref({
            EMAIL: '',
            FIRST_NAME: '',
            LAST_NAME: '',
            ROLES: [],
            START_DATE: new Date(),
            END_DATE: null,
            TWOFACTOR_REQUIRED: '',
        })
        const roles = ref([])
        const { role } = useState(['role'])
        const errors = ref({
            EMAIL: { missingValue: false, invalidValue: false },
            FIRST_NAME: { missingValue: false },
            LAST_NAME: { missingValue: false },
            ROLES: { missingValue: false },
        })
        const twoFactorDisabled = ref(false)

        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        // get current org id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const twoFactorRequiredDropdown = ref([])

        // set minimum date to yesterday for endDate
        const minEndDate = computed((): Date => {
            const today = new Date()
            const yesterday = today.setDate(today.getDate() - 1)
            return new Date(yesterday)
        })

        const validateForm = () => {
            errors.value = {
                EMAIL: { missingValue: false, invalidValue: false },
                FIRST_NAME: { missingValue: false },
                LAST_NAME: { missingValue: false },
                ROLES: { missingValue: false },
            }
            // missingValue check
            errors.value.FIRST_NAME.missingValue = utils.validateFields(
                USERFORM.value.FIRST_NAME,
                'string'
            )
            // missingValue check
            errors.value.LAST_NAME.missingValue = utils.validateFields(
                USERFORM.value.LAST_NAME,
                'string'
            )

            // Validate email
            if (USERFORM.value.EMAIL)
                errors.value.EMAIL.invalidValue = utils.validateFields(
                    USERFORM.value.EMAIL,
                    'email'
                )
            // missingValue check
            errors.value.EMAIL.missingValue = utils.validateFields(USERFORM.value.EMAIL, 'string')
            // missingValue check
            errors.value.ROLES.missingValue = utils.validateFields(USERFORM.value.ROLES, 'array')
        }

        // hide roles when user logged in with following roles
        const getHiddenRolesPerRole = () => {
            switch (role.value) {
                case UserRole.ADMIN:
                    // hide PERIUM_ADMIN, DEVELOPER_ADMIN and SUPER_ADMIN for ADMIN
                    return [UserRole.PERIUM_ADMIN, UserRole.DEVELOPER_ADMIN, UserRole.SUPER_ADMIN]
                case UserRole.DEVELOPER_ADMIN:
                    // hide SUPER_ADMIN for DEVELOPER_ADMIN
                    return [UserRole.SUPER_ADMIN]
                case UserRole.PERIUM_ADMIN:
                    // hide SUPER_ADMIN for PERIUM_ADMIN
                    return [UserRole.SUPER_ADMIN]
                default:
                    return []
            }
        }
        // get list of role
        const getRoles = async () => {
            const getRolesQuery = `
                query{
                    roles{
                        ${query.ROLES}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getRolesQuery)
            } catch {
                throw Error('Error while retrieving roles')
            }

            if (result) {
                const rolesOriginalResult = result.data.roles
                let filteredRoles = []
                // for invite user hide following roles in display
                if (props.type === 'invite-user') {
                    filteredRoles = rolesOriginalResult.filter(
                        (val) =>
                            ![
                                UserRole.SUPER_ADMIN,
                                UserRole.PERIUM_ADMIN,
                                UserRole.DEVELOPER_ADMIN,
                            ].includes(val.name)
                    )
                } // for update user hide roles based on logged in role
                else if (props.type === 'update-user') {
                    filteredRoles = rolesOriginalResult.filter(
                        (val) => !getHiddenRolesPerRole().includes(val.name)
                    )
                } else {
                    filteredRoles = rolesOriginalResult
                }
                roles.value = filteredRoles
            }
        }

        const setFormData = () => {
            const userData = props.userDetails

            // get organisations from returned updated user info and filter with current org
            const organisation = userData.organisations.filter((val) => val.id == organisationId)

            USERFORM.value = {
                FIRST_NAME: userData.firstName,
                LAST_NAME: userData.lastName,
                ROLES: organisation[0].roles,
                EMAIL: userData.email,
                START_DATE: moment(userData.startDate).toDate(),
                END_DATE: userData.endDate && moment(userData.endDate).toDate(),
                TWOFACTOR_REQUIRED: userData.twoFactorRequired ? YesNo.YES : YesNo.NO,
            }
            // if organisation has set 2fa to be missingValue, user cannot change it to be non-missingValue by own choice
            if (twoFactorDisabled.value) USERFORM.value.TWOFACTOR_REQUIRED = YesNo.YES
        }

        /*disable perium admin, developer admin and super admin check from the roles checkboxes 
           as only backend team can give asseign them */
        const disableAssignPermission = (roleName) => {
            if (
                roleName === UserRole.PERIUM_ADMIN ||
                roleName === UserRole.DEVELOPER_ADMIN ||
                roleName === UserRole.SUPER_ADMIN ||
                // when the user is admin, avoid removing their own admin role
                (roleName === role.value &&
                    props.type === 'update-user' &&
                    userId === props.userDetails.id)
            ) {
                return true
            }
        }

        // populate two-factor dropdown values
        const setTwoFactorRequiredDropdown = () => {
            // create a dropdown to contain 'YES', 'NO' values
            twoFactorRequiredDropdown.value = [
                {
                    key: YesNo.YES,
                    value: t(YesNo.YES, language.value),
                },
                {
                    key: YesNo.NO,
                    value: t(YesNo.NO, language.value),
                },
            ]

            // check if any organisation has 2FA required.
            const organisation = props.userDetails.organisations.find(
                (org) => org.twoFactorRequired === true
            )
            twoFactorDisabled.value = !(organisation == null)
        }

        const userExpired = computed(() => {
            return (
                props.userDetails &&
                utils.checkUserExpire(props.userDetails.startDate, props.userDetails.endDate)
            )
        })

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return !props.userDetails || props.editable
        })

        onMounted(async () => {
            await getRoles()
            if (props.userDetails) {
                setTwoFactorRequiredDropdown()
                setFormData()
            }
        })

        //  watch for userDetails changes and set form data
        watch(
            () => props.userDetails,
            () => {
                if (props.userDetails) {
                    setTwoFactorRequiredDropdown()
                    setFormData()
                }
            }
        )
        return {
            t,
            language,
            USERFORM,
            roles,
            UserRole,
            utils,
            generalData,
            disableAssignPermission,
            role,
            errors,
            validateForm,
            moment,
            minEndDate,
            twoFactorRequiredDropdown,
            twoFactorDisabled,
            YesNo,
            userExpired,
            isEditableMode,
        }
    },
})












import { defineComponent, onMounted, ref } from '@vue/composition-api'
import idleTimeout from 'idle-timeout'
import authService from '@/shared/services/Authentication'
import variables from './shared/variables'
import utils from './shared/mixins/utils'
import router from './router'

// toast
import { provideToast } from 'vue-toastification/composition'
import 'vue-toastification/dist/index.css'

export default defineComponent({
    name: 'App',
    setup() {
        const loading = ref(false)
        // toast configurations
        provideToast({
            transition: 'Vue-Toastification__bounce',
            maxToasts: 20,
            newestOnTop: true,
            // position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.46,
            showCloseButtonOnHover: true,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
        })

        // timer to refresh token
        // this will periodically call refresh-token query to get a new token.
        const refreshTokenTimer = setInterval(() => {
            // refresh the token
            authService.refreshToken()
        }, variables.SESSION.REFRESH_TOKEN_TIMEOUT)

        // initialize idle-timeout
        // this is to check if user has been idle (out of page) for the given amount of time.
        const idleRunner = idleTimeout(
            () => {
                clearInterval(refreshTokenTimer)
                authService.clearAuthentication()
                // naivgate to Login page when the session is timed out.
                router.push({ name: 'Login' , params: {autoLogout: 'true'}})
            },
            {
                element: document.body,
                timeout: variables.SESSION.IDLE_TIMEOUT,
                loop: false,
            }
        )

        // if the token is expired when user access the application clear localStorage and navigate to Login page
        const checkTokenExpiration = async () => {
            loading.value = true
            const validToken = await authService.verifyToken()
            loading.value = false
            if (!validToken) {
                localStorage.clear()
                router.push({ name: 'Login' })
            }
            
        }

        onMounted(() => {
            // These are meant to be run if when user is logged in and make a refresh.
            if (!utils.isAuthenticated()) {
                clearInterval(refreshTokenTimer)
                idleRunner.destroy()
            } else {
                // when the token is available on localStorage
                checkTokenExpiration()
            }
        })

        return {
            loading
        }
    },
})
















import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
        },
        value: {
            type: Boolean,
        },
    },
    setup(props, { emit }) {
        const toggleValue = ref(false)
        // on toggle change
        const onChangeToggle = () => {
            emit('onchange-toggle', toggleValue.value)
        }
        // set default value
        const setValue = () => {
            toggleValue.value = props.value
        }

        watch(
            () => props.value,
            () => {
                 setValue()
            },
            {
                immediate: true,
            }
        )
        return {
            toggleValue,
            onChangeToggle,
        }
    },
})

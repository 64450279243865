























































import { defineComponent, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import UserForm from '@/shared/components/UserForm.vue'
export default defineComponent({
    components: {
        UserForm,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const userFormCmp = ref(null)

        // send invitation to the user
        const sendUserInvite = async () => {
            userFormCmp.value.validateForm()
            if (utils.hasErrors(userFormCmp.value.errors)) {
                return
            }
            emit('invite-user', userFormCmp.value.USERFORM)
        }

        const cancel = () => {
            emit('cancel')
        }
        return {
            t,
            language,
            sendUserInvite,
            userFormCmp,
            cancel,
        }
    },
})

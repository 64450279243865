var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'menu-icon': true,
    active: _vm.section.active || _vm.isOpenSection,
},style:({cursor: _vm.section.isSingleItemSection ? 'pointer' : 'default'}),on:{"mouseenter":_vm.activateMenu,"mouseleave":function () {if(_vm.section.isSingleItemSection) {_vm.hideMenu()}},"click":function (e) { return _vm.handleIconClick(e, _vm.section); }}},[_c('span',{ref:"iconRef",style:({
        position: 'relative',
        left: _vm.menuOffsetLeft,
        top: _vm.menuOffsetTop,
    }),on:{"open-menu-at-anchor":function (event) { return _vm.menuSectionPanel.show(event); }}}),_vm._t("default",[(_vm.section.icon.name)?_c('i',{class:( _obj = {
            'p-mr-2': true,
            'fas': true,
            'fa-fw': true
        }, _obj[_vm.section.icon.name] = true, _obj )}):(_vm.section.icon.image)?_c('img',{staticClass:"p-mr-2 icon-image",attrs:{"src":(_vm.section.active || _vm.isOpenSection) ? _vm.section.icon.image.active : _vm.section.icon.image.inactive}}):_vm._e()]),_c('OverlayPanel',{ref:"menuSectionPanel",staticClass:"menu-section-overlay",style:({'pointer-events': _vm.section.isSingleItemSection ? 'none' : 'auto'}),attrs:{"appendTo":"body","animation":"none"}},[(_vm.section.header)?_c('div',{staticClass:"menu-header-name"},[_vm._v(_vm._s(_vm.section.header))]):_vm._e(),(!_vm.section.isSingleItemSection)?_c('div',[_c('ul',{staticClass:"floating-menu-section"},[_vm._l((_vm.section.subSections),function(subsection,index){return _c('li',{key:("submenu-" + index),class:{
                        'submenu-item': true,
                        'active-item': subsection.active
                    },on:{"mousemove":function () { return _vm.openSubmenu = index; }}},[_c('div',{class:{
                        submenu: true,
                        'active-item': subsection.active
                    }},[_c('span',[_vm._v(_vm._s(subsection.header))]),_c('i',{staticClass:"fas fa-angle-right"})]),(subsection.items && _vm.openSubmenu === index)?_c('ul',{staticClass:"floating-menu-subsection"},_vm._l((subsection.items),function(item,index){return _c('li',{key:("menuitem-" + index),class:item.active ? 'active-item' : '',on:{"click":function () {
                                _vm.hideMenu();
                                _vm.openSubmenu = null;
                                item.command();
                            }}},[_vm._v(" "+_vm._s(item.header)+" ")])}),0):_vm._e()])}),_vm._l((_vm.section.items),function(item,index){return _c('li',{key:("menuitem-" + index),class:item.active ? 'active-item' : '',on:{"click":function () {_vm.hideMenu(); item.command()}}},[_vm._v(" "+_vm._s(item.header)+" ")])})],2)]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }


























import { defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import utils from '@/shared/mixins/utils'
import 'vue-toastification/dist/index.css'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import query from '@/shared/queries/generalQueries'
import LeftPanel from '@/shared/components/left-panel/LeftPanel.vue'
import { useActions, useState } from '@/shared/mixins/helpers'
import variables from '@/shared/variables'
import { UserDTO } from '@/dto/backend-response/usersDTO'
import WizardWrapper from '@/views/dashboard/wizard/WizardWrapper.vue'
import { NormType } from '@/shared/enum/norms-enum'
import { useI18n } from 'vue-i18n-composable'
import authService from '@/shared/services/Authentication'
import { useToast } from 'vue-toastification/composition'

export default defineComponent({
    components: {
        LeftPanel,
        WizardWrapper,
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { SET_USERS } = useActions(['SET_USERS'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { menuOpen } = useState(['menuOpen'])
        const { networkError } = useState(['networkError'])
        const { visibleLeftMenuBar } = useState(['visibleLeftMenuBar'])
        const userDropdownOptions = ref([])
        const isMobileDevice = ref(false)
        const visibleLeft = ref(false)
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const usersList: Ref<Array<UserDTO>> = ref([])
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        const toast = useToast()
        watch(networkError, (error) => {
            // This state is managed by Apollo middleware
            // It is set to true once a network request fails, and back to false
            // the next time a request succeeds.
            if (error) {
                toast.error(t('ERROR__NETWORK_ERROR', language.value), {
                    // Keep error toast visible as long as the network is out
                    timeout: false,
                })
            } else {
                // Keep an error toast visible as long as the network is out
                toast.clear()
            }
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        const { SET_NORM_TYPES } = useActions(['SET_NORM_TYPES'])
        const { SET_SELECTED_NORMS } = useActions(['SET_SELECTED_NORMS'])
        const enableRoute = ref(false)
        const validToken = ref(false)

        // set user dropdown option values
        const setUserDropdownValues = () => {
            userDropdownOptions.value = []
            usersList.value.map((user) => {
                // get current organisation
                const currentOrganisation = user.organisations.filter(
                    (organisation) => organisation.id === organisationId
                )
                // get user expireation state
                const userExpired = utils.checkUserExpire(
                    currentOrganisation[0].startDate,
                    currentOrganisation[0].endDate
                )

                userDropdownOptions.value.push({
                    name: user.firstName + ' ' + user.lastName,
                    id: user.id,
                    username: user.username,
                    organisations: user.organisations,
                    userExpired,
                    // get formatted name with adding (inactive) text next to name for expired users
                    fname: userExpired
                        ? user.firstName +
                          ' ' +
                          user.lastName +
                          ' (' +
                          t('INACTIVE', language.value) +
                          ')'
                        : user.firstName + ' ' + user.lastName,
                })
            })
            userDropdownOptions.value = utils.sortbyAlphabeticOrder(
                userDropdownOptions.value,
                'fname'
            )
            SET_USERS(userDropdownOptions.value)
        }

        // update logged in user info
        const updateLoggedInUserRole = (usersDetails) => {
            // update user info in localstorage with updated data
            const loggedInUserDetails = {
                user: usersDetails.filter((val) => val.id == userId)[0],
            }
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.USER, loggedInUserDetails)
        }
        const getUsers = async () => {
            const usersQuery = `
                query {
                    users{
                      ${query.USERS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(usersQuery)
            } catch {
                throw Error('Error while retrieving users')
            }
            if (result) {
                // const usersDataResult: ApolloQueryResult<UsersDTO> = result
                usersList.value = result.data.users
                setUserDropdownValues()
                updateLoggedInUserRole(usersList.value)
            }
        }

        // get client config data
        const getClientConfig = async () => {
            const clientConfig = `
                query{
                    organisationconfig(orgId:${organisationId}){
                        ${query.NORMS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(clientConfig)
            } catch {
                throw Error('Error while retrieving client config data')
            }

            if (result) {
                const clientConfigResult = result.data.organisationconfig
                if (clientConfigResult) {
                    const selectedNorms = clientConfigResult.selectedNorms

                    const normTypes = []
                    // store norm types and other norm types (`OTHER - otherNormType`) into `normTypes`
                    selectedNorms.map((norm) => {
                        norm.normType !== NormType.OTHER
                            ? normTypes.push(norm.normType)
                            : normTypes.push(NormType.OTHER + ' - ' + norm.normTypeOther)
                    })

                    // store norm types
                    SET_NORM_TYPES(normTypes)
                    // store norms
                    SET_SELECTED_NORMS(selectedNorms)
                }
            }
        }

        const leftMenuHidden = () => {
            SET_LEFT_MENU_SIDEBAR(false)
        }

        // check the token expiration, if incase user trys to navigate directly to child routes of `Home`
        const checkTokenExpiration = async () => {
            validToken.value = await authService.verifyToken()
            // avoid calling below api queries when the token is expired
            if (validToken.value) {
                await getClientConfig()
                await getUsers()
            }
        }

        onMounted(async () => {
            isMobileDevice.value = utils.mobileDeviceCheck()
            await checkTokenExpiration()

            /* Using `enableRoute` to start routing after above functions are executed. 
            Since we use data from above functions in dashboard, data should be available when we navigate to dashboard */
            enableRoute.value = true
            if (!isMobileDevice.value) {
                SET_OPEN_MENU(true)
                utils.openNav(true)
            }
        })

        watch(visibleLeftMenuBar, () => {
            visibleLeft.value = visibleLeftMenuBar.value
        })

        return {
            isMobileDevice,
            visibleLeft,
            leftMenuHidden,
            enableRoute,
            validToken,
        }
    },
})




















import { defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import InviteUser from '@/components/client-configuration/InviteUser.vue'
import ToggleSwitch from '@/shared/components/ToggleSwitch.vue'
import variables from '@/shared/variables'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    components: { InviteUser, ToggleSwitch },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { SET_WIZARD_DATA } = useActions(['SET_WIZARD_DATA'])
        const dataShare = ref(false)
        const { wizardData } = useState(['wizardData'])

        const next = () => {
            SET_WIZARD_DATA({ dataShare: dataShare.value })
            utils.ls.set(
                    variables.LOCAL_STORAGE_ITEMS.WIZARD_DATA,
                    wizardData.value
                )
        }

        const dataShareToggle = (toggleValue) => {
            dataShare.value = toggleValue
            next()
        }

        watch(
            () => Object.assign({}, wizardData),
            () => {
                if (wizardData) {
                    dataShare.value = wizardData.value.dataShare
                }
            },
            { immediate: true }
        )
        const finishWizard = () => {
            next()
            emit('finish-wizard', { dataShare: dataShare.value })
        }
        return {
            t,
            language,
            dataShare,
            dataShareToggle,
            finishWizard,
        }
    },
})

import { render, staticRenderFns } from "./OpenItems.vue?vue&type=template&id=857a66c8&scoped=true&"
import script from "./OpenItems.vue?vue&type=script&lang=ts&"
export * from "./OpenItems.vue?vue&type=script&lang=ts&"
import style0 from "./OpenItems.vue?vue&type=style&index=0&id=857a66c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "857a66c8",
  null
  
)

export default component.exports































































import { defineComponent, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import router from '@/router'
import utils from '@/shared/mixins/utils'
import { useToast } from 'vue-toastification/composition'

export default defineComponent({
    methods: {
        // #661 is raised to reuse recaptcha function
        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "send email".
            const token = await this.$recaptcha('submit')
            return token

            // Do stuff with the received token.
        },
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const loading = ref(false)
        const FORGOT_PASSWORD_FORM = ref({
            EMAIL: '',
        })
        const toast = useToast()
        const errors = ref({
            EMAIL: { missingValue: false, invalidValue: false },
            serverError: false,
        })

        const validateForm = () => {
            // reset errors
            errors.value = {
                EMAIL: { missingValue: false, invalidValue: false },
                serverError: false,
            }
            // missingValue check
            errors.value.EMAIL.missingValue = utils.validateFields(
                FORGOT_PASSWORD_FORM.value.EMAIL,
                'string'
            )
            // Validate submitted email
            errors.value.EMAIL.invalidValue = utils.validateFields(
                FORGOT_PASSWORD_FORM.value.EMAIL,
                'email'
            )
        }

        const sendResetPasswordEmail = async (token: string) => {
            loading.value = true
            const mutationQuery = `
                        mutation($input: PasswordResetInput!) {
                            sendPasswordResetEmail(input: $input){
                                status
                                error
                            }
                        }
                        `
            const input = {
                email: FORGOT_PASSWORD_FORM.value.EMAIL,
                reCaptchaToken: token,
            }
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch {
                throw Error('Error while sending email')
            }

            loading.value = false
            if (result.data.sendPasswordResetEmail.status) {
                toast.success(t('FORGOT_PASSWORD_RESET_SEND_SUCCESS_MESSAGE', language.value))
                router.push({ name: 'Login' })
            } else {
                errors.value.serverError = result.data.sendPasswordResetEmail.error
            }
        }

        async function submitResetPasswordEmail() {
            // validate login form
            validateForm()
            // check for validation errors
            if (utils.hasErrors(errors.value)) {
                return
            }
            const token = await this.recaptcha()
            sendResetPasswordEmail(token)
        }

        const goBackToLogin = () => {
            router.push({ name: 'Login' })
        }

        return {
            t,
            language,
            FORGOT_PASSWORD_FORM,
            submitResetPasswordEmail,
            goBackToLogin,
            errors,
            loading,
        }
    },
})
















import { defineComponent, ref, onMounted } from '@vue/composition-api'
import {completeAuthFlow} from '@/shared/services/single-sign-on'
import router from '@/router'
import utils from '@/shared/mixins/utils';
import { useI18n } from 'vue-i18n-composable';
import { useState } from '@/shared/mixins/helpers';

export default defineComponent({
       setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const authFlowFailed = ref(false);
        const errorMessage = ref("Login failed.")
        onMounted(
            () => completeAuthFlow()
                .then(
                    (flowResult) => {
                        // If the flow has been completed successfully, user is now logged
                        // in and the organisation is selected.
                        if (flowResult?.status) {
                            // Redirect user to the right page
                            if (utils.wizardCompletionCheck()) {
                                router.push({name: 'Dashboard'})
                            } else {
                                router.push({name: 'WizardPage1'})
                            }
                        }
                        else {
                            // Show a failure message
                            authFlowFailed.value = true
                            if ( 'errorMessage' in flowResult) {
                                // Directly show backend error message.
                                errorMessage.value = flowResult.errorMessage
                            }
                        }
                    }
                )
                .catch(
                    
                    () => {
                        authFlowFailed.value = true
                        errorMessage.value = "Did not receive a token."
                    }
                )
        )
        return {
            authFlowFailed,
            errorTranslationKey: errorMessage,
            t,
            language,
        }
    },
})


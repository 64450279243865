































































import { useActions, useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import { computed, defineComponent, ref } from '@vue/composition-api'
import utils from '@/shared/mixins/utils'
import OpenItems from '@/components/dashboard/OpenItems.vue'
import ActiveItems from '@/components/dashboard/ActiveItems.vue'
import OrgPerformance from '@/components/dashboard/OrgPerformance.vue'
import ControlMaturityLevel from '@/components/dashboard/ControlMaturityLevel.vue'
import variables from '@/shared/variables'
import { UserRole } from '@/shared/enum/general-enum'

export default defineComponent({
    components: { OpenItems, OrgPerformance, ActiveItems, ControlMaturityLevel },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const visibleLeftMenu = ref(false)
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { menuOpen } = useState(['menuOpen'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { role } = useState(['role'])
                // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        // get logged in user info
        const loggedInUser: { user: { organisations } } = utils.ls.get(
            variables.LOCAL_STORAGE_ITEMS.USER,
            {
                decrypt: true,
            }
        )
        // current organisation name
        const organisationName = computed(() => {
            const currentOrganisation = loggedInUser.user.organisations.find(
                (organisation) => organisation.id === organisationId
            )
            if (currentOrganisation) return currentOrganisation.name
        })

        return {
            language,
            t,
            utils,
            visibleLeftMenu,
            SET_LEFT_MENU_SIDEBAR,
            SET_OPEN_MENU,
            menuOpen,
            role,
            variables,
            UserRole,
            organisationName
        }
    },
})

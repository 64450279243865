




















































import { defineComponent, ref } from '@vue/composition-api'
import Step1 from '@/components/dashboard/wizard/page1/Step1.vue'
import Step2 from '@/components/dashboard/wizard/page1/Step2.vue'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import Step3 from '@/components/dashboard/wizard/page1/Step3.vue'
import Step4 from '@/components/dashboard/wizard/page1/Step4.vue'
import Recap from '@/components/dashboard/wizard/page2/Recap.vue'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import variables from '@/shared/variables'
import router from '@/router'
import query from '@/shared/queries/generalQueries'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    beforeRouteEnter(to, from, next) {
        const { wizardData } = useState(['wizardData'])
        const completedSteps = wizardData.value.completedSteps
        if (completedSteps.includes('4')) {
            next()
        } else {
            next({ name: 'WizardPage1' })
        }
    },
    components: {
        Step1,
        Step2,
        Step3,
        Step4,
        Recap,
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const wizardItems = ref([
            { title: 'WIZARD_STEP1_TITLE', step: 1, status: 'start' },
            { title: 'WIZARD_STEP2_TITLE', step: 2, status: 'start' },
            { title: 'WIZARD_STEP3_TITLE', step: 3, status: 'start' },
            { title: 'WIZARD_STEP4_TITLE', step: 4, status: 'start' },
        ])
        const activeStep = ref(1)
        const { wizardData } = useState(['wizardData'])
        const loading = ref(false)
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id

        const next = (step) => {
            // activate next step
            activeStep.value = step + 1
        }

        const getUsers = async () => {
            const usersQuery = `
                query {
                    users{
                      ${query.USERS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(usersQuery)
            } catch {
                throw Error('Error while retrieving users')
            }

            if (result) {
                const usersList = result.data.users
                // update user info in localstorage with updated data to get updated data with wizard completion status
                const loggedInUserDetails = {
                    user: usersList.filter((val) => val.id == userId)[0],
                }
                utils.ls.set(variables.LOCAL_STORAGE_ITEMS.USER, loggedInUserDetails)
            }
        }
        // update wizardCompleted status for organisation
        const updateOrgStatus = async () => {
            loading.value = true
            const mutationQuery = `mutation {
                updateOrganisationStatus(id: ${organisationId}, wizardCompleted: true) {
                    status
                    error
                }
            }`
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, null)
                loading.value = false
            } catch (err) {
                loading.value = false
                return 'error'
            }
            if (result) {
                if (result.data.updateOrganisationStatus.status) {
                    utils.ls.set(variables.LOCAL_STORAGE_ITEMS.WIZARD_COMPLETION_STATUS, 'true')
                    getUsers()
                    router.push({ name: 'Dashboard' })
                }
            }
        }

        const finishWizard = () => {
            updateOrgStatus()
        }

        return {
            wizardItems,
            t,
            language,
            next,
            activeStep,
            wizardData,
            finishWizard,
            loading,
        }
    },
})

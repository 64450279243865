import apolloClient from '@/shared/services/ApolloCLientAPI'
import { createI18n } from 'vue-i18n-composable'
import en from '@/assets/translations/en.json'
import nl from '@/assets/translations/nl.json'
import variables from '@/shared/variables'
import { useActions } from '@/shared/mixins/helpers'
import i18nQueries from "@/shared/queries/i18nQueries"


const { SET_LANGUAGE } = useActions(['SET_LANGUAGE'])


let loadedOrganisationLanguage = false

// Retrieve message dict for bundled language
const getBundledMessageDict = (locale) => {
    if (locale === 'en') {
        return en
    }
    if (locale === "nl" || locale === "dutch") {
        return nl;
    }
    console.warn(`No bundled translations for language ${locale}`)
    return {}
}

const i18n = createI18n({
    fallbackLocale: variables.DEFAULT_LANGUAGE,
    locale: variables.DEFAULT_LANGUAGE,
    messages: {[variables.DEFAULT_LANGUAGE]: getBundledMessageDict(variables.DEFAULT_LANGUAGE)}
});


const setLocale = async (locale: string) => {
    i18n.locale = locale
    SET_LANGUAGE(locale)
}

const resetLoadedLanguage: () => void = () => (loadedOrganisationLanguage = false)


// Set translation data for the current organisation
// Should be called after any organisation switch
const configureOrganisationTranslations = async () => {
    let result;

    // Query either loads the complete translation data, or only queries the 
    // language that should be used for selecting the right bundled translations.
    const query = variables.USE_BACKEND_TRANSLATIONS 
        ? i18nQueries.LOCALIZATION_WITH_MESSAGES 
        : i18nQueries.LOCALIZATION_WITHOUT_MESSAGES
    try {
        result = await apolloClient.getGraphqlData(query)
    } catch {
        throw Error('Error while loading translation from backend')
    }
    if (result?.data?.localizationForLoggedInUser?.locale) {
        // If the query returns the locale, we get it from the response
        const { locale } = result.data.localizationForLoggedInUser;
        // Then if we are using backend translatioins, get the translation data from 
        // the response. Otherwise, load it from the frontend files.
        const messageDict = variables.USE_BACKEND_TRANSLATIONS 
            ? JSON.parse(result.data.localizationForLoggedInUser.messageDictJson) 
            : getBundledMessageDict(locale)
        
        i18n.setLocaleMessage(locale, messageDict)
        // Set locale in the store. This will be used to set the accept-language headers
        // on subsequent apollo graphql queries. 
        setLocale(locale)
        // Set a flag to prevent this code from running on every vue-router navigation
        loadedOrganisationLanguage = true
    }
}


// Switch language after the UI has already been loaded. Just calling setLocale is not sufficient
// to achieve this.

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const switchLanguage = (locale: string) => {
    // TODO: once all components support live language switching, replace this page refresh with
    // a call to setLocale
    window.location.reload()
}

export {
    configureOrganisationTranslations,
    i18n,
    loadedOrganisationLanguage,
    switchLanguage,
    resetLoadedLanguage,
}
const USERS = `
    id
    username
    firstName
    lastName
    email
    twoFactorRequired
    twoFactorActivated
    organisations{
        id
        name
        startDate
        endDate
        twoFactorRequired
        wizardCompleted
        roles{
            id
            name
        }
    }`
// get authenticated user data
const AUTHERNTICATION_DETAILS = `
    user{
        id
        username
        firstName
        lastName
        email
        twoFactorRequired
        twoFactorActivated
        organisations{
            id
            name
            twoFactorRequired
            wizardCompleted
            startDate
            endDate
            roles{
                id
                name
            }
        }
    }
    `

const NORMS = `
    selectedNorms{
        id
        normType
        normTypeOther
        isControlTypeEn
        name
        created_by{
            id
            username
        }
    }
    `
export default { AUTHERNTICATION_DETAILS, USERS, NORMS }

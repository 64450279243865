




/**
 * Usage of the Editor:
 *
 * <MarkdownEditor v-model="markdownText" />
 * ...
 * import MarkdownEditor from '@/shared/components/MarkdownEditor.vue'
 * ...
 * components: {
 *     MarkdownEditor,
 * },
 *
 * ===
 *
 * To view the markdown:
 *
 * <div v-html="marked(markdownText)"></div>
 * ...
 * import utils from '@/shared/mixins/utils'
 * ...
 * methods: {
 *     marked(value) {
 *         return utils.marked(value)
 *     }
 * }
 */

import { defineComponent } from '@vue/composition-api'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import Link from '@ckeditor/ckeditor5-link/src/link'
import List from '@ckeditor/ckeditor5-list/src/list'
import TodoList from '@ckeditor/ckeditor5-list/src/todolist'
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat'
import Markdown from '@ckeditor/ckeditor5-markdown-gfm/src/markdown'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
// import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock'
import Table from '@ckeditor/ckeditor5-table/src/table'
// import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';

// import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/'
import InputLengthValidator from '@/shared/utils/InputLengthValidator'

export default defineComponent({
    name: 'MarkdownEditor',
    emits: ['input'],
    components: {
        'CKEditor': CKEditor.component,
    },
    props: {
        value: {
            type: String,
        },
        maxCharacters: {
            type: Number,
            default: Infinity // Default to unlimited
        },
        disabled: {
            type: Boolean,
        }
    },
    data() {
        return {
            editor: ClassicEditor,
        }
    },
    computed: {
        editorConfig() {
            return {
                allowedContent: true,
                toolbar: {
                    items: [
                        'heading', 'bold', 'italic', 'strikethrough', 'link', '|',
                        'bulletedList', 'numberedList', 'todolist', 'outdent', 'indent', '|',
                        'code', 'horizontalLine', 'removeformat', '|',
                        'undo', 'redo'
                    ],
                },
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                    ]
                },
                plugins: [
                    Markdown, Autoformat, EssentialsPlugin,
                    Heading, Bold, Italic, Strikethrough, Link,
                    Indent, List, TodoList, HorizontalLine, Code,
                    Table, RemoveFormat, InputLengthValidator
                ],
                InputLengthValidator: {
                    maxCharacters: this.maxCharacters // Pass the maxCharacters prop to the plugin
                }
            }
        }
    },
    methods: {
        input(value) {
            this.$emit('input', value)
        }
    }
})

/**
 * Ensure return value is always an array, by wrapping single values or returning `[]` for nullish values. 
 * 
 * Query parameters can occur zero, one or multiple times. Vue router handles 
 * this by setting the value to `undefined`, a `string` or an array of strings. 
 * This way we can pass the query parameter as a prop, and the component can 
 * be sure it will always be of the same type. 
 */
export const ensureArray = (queryParam: string | string[] | undefined): string[] => {
    if (!queryParam) {
        return []
    }
    if (Array.isArray(queryParam)) {
        return queryParam
    }
    return [queryParam]
}
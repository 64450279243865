// status of tasks
export enum TaskStatus {
    //#809
    TEMPLATE = 'TEMPLATE',
    TOSTART = 'TO_START',
    INPROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
}

export enum TaskStrategies {
    //#809
    ONCE = 'ONCE',
    PERIODICAL = 'PERIODICAL',
}























































































import { Ref, computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'
import router from '@/router'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import { gql } from '@apollo/client/core'

const GET_HEATMAP_QUERY = gql`
query GetDashboardHeatmap($filters: RiskFilterInput) {
    risksTable(filters: $filters) {
        heatmap {
            chance
            impact
            frequencyChanceImpact
        }
    }
    organisationconfig {
        riskToleranceGreen
        riskToleranceOrange
        riskToleranceRed
    }
    allowedFieldValues {
        values {
            riskSet (longName: true) {
                label 
                value
            }
        }
    }
}
`
interface RiskToleranceConfig {
    riskToleranceGreen: number;
    riskToleranceOrange: number;
    riskToleranceRed: number;
}

interface HeatmapData {
    chance: number[];
    impact: number[];
    frequencyChanceImpact: number[][];
    riskTolerance: RiskToleranceConfig;
    riskSetOptions: {label: string; value: string}[];
}

const getHeatmap = async (selectedRiskSet: string | null): Promise<HeatmapData> => {
    const result = await apolloClient.apolloClient.query({
        query: GET_HEATMAP_QUERY,
        fetchPolicy: 'network-only',
        variables: {
            filters: {
                riskSet: selectedRiskSet ? [selectedRiskSet] : null
            }
        }
    })
    return {
        riskTolerance: result.data.organisationconfig,
        riskSetOptions: result.data.allowedFieldValues.values.riskSet,
        ...result.data.risksTable.heatmap,
    }
}

const emptyHeatmapData = (): HeatmapData => {
    const chance = [1,2,3,4,5];
    const impact = [1,2,3,4,5];
    return {
            chance: chance,
            impact: impact,
            frequencyChanceImpact: chance.map(() => impact.map(() => 0)),
            riskTolerance: {
                riskToleranceGreen: 4,
                riskToleranceOrange: 18,
                riskToleranceRed: 25,
            },
            riskSetOptions: [],
        }
}

export default defineComponent({
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const SELECTED_RISK_SET = ref(null)
        const heatmap: Ref<HeatmapData> = ref(emptyHeatmapData())
        const loading = ref(false)

        const riskSetOptions = computed(
            () => [
                {label: t('SHOW_ALL', language.value), value: null},
                ...heatmap.value?.riskSetOptions ?? []
            ]
        )

        // calculate risk score
        const calculateRiskScore = (impact, chance) => {
            return impact * chance
        }

        // get heatmap data
        const getHeatMapData = async () => {
            loading.value = true
            heatmap.value = {
                ...emptyHeatmapData(),
                riskTolerance: heatmap.value.riskTolerance,
                riskSetOptions: heatmap.value.riskSetOptions,
            }
            try {
                heatmap.value = await getHeatmap(SELECTED_RISK_SET.value)
            } catch {
                throw Error('Error while retrieving heatmap data')
            } finally {
                loading.value = false
            }
        }

        // navigate to risk assessments list by filtering netChance and netImpact
        const goToAssessments = (impact: number, chance: number, riskSetID: string | null) => {
            const query = {
                    statefilter: ['2', '3', '4', '5'],
                    netChance: chance.toString(),
                    netImpact: impact.toString(),
                    onlyLatest: "true",
            }
            if (SELECTED_RISK_SET.value) {
                // WFilter expects the risk set to be passed by name
                query['riskSet'] = riskSetOptions.value.find(
                    option => option.value == riskSetID
                ).label
            }
            router.push({
                name: 'RiskAssessments',
                query,
            })
        }

        onMounted(async () => {
            await getHeatMapData()
        })
        watch(
            SELECTED_RISK_SET,
            () => getHeatMapData()
        )

        return {
            t,
            language,
            heatmap,
            riskSetOptions,
            SELECTED_RISK_SET,
            calculateRiskScore,
            utils,
            goToAssessments,
            loading,

        }
    },
})
